import ConnectedOrganisationDropdownMenu from 'components/OrganisationDropdownMenu/OrganisationDropdownMenu.connector';
import SidebarItem, {
    SidebarItemsDropdown,
} from 'components/SidebarItem/SidebarItem';
import { SidebarDivider } from 'components/SidebarItem/SidebarItem.components';
import getLanguageObject, { LangObject } from 'helpers/language';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import ConnectedCurrentUserSidebarItem, {
    SidebarContainer,
    SidebarLogoutItem,
    SidebarNav,
} from './Sidebar.components';
import SidebarProps from './Sidebar.types';
import moment from 'moment';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import useLocalStorageState from 'use-local-storage-state';
import DashboardIcon from 'components/Icons/dashboard';
import AllocatorIcon from 'components/Icons/allocator';
import SearchIcon from 'components/Icons/search';
import JobProfilerIcon from 'components/Icons/jobProfiler';
import MatrixIcon from 'components/Icons/matrix';
import TalentIcon from 'components/Icons/talent';
import ELearningIcon from 'components/Icons/elearning';
import BellIcon from 'components/Icons/bell';
import UsersIcon from 'components/Icons/users';
import OrganogramIcon from 'components/Icons/organogram';
import LinkIcon from 'components/Icons/link';
import CommunicationIcon from 'components/Icons/communication';
import LogoutIcon from 'components/Icons/logout';
import { Permissions } from 'store/user/user.types';
import DownloadIcon from 'components/Icons/download';
import DocumentIcon from 'components/Icons/document';
import ictIps from '../../assets/pdfs/Styr Dock IT & Security doc.pdf';
import { updatesGetUpdates } from 'store/updates/updates.actions';
import { useDispatch } from 'react-redux';

const MaybeRenderManagement: React.FC<{
    permissions: Permissions;
    collapseManage: boolean;
    setCollapseManage: (value: boolean) => void;
    sidebarOpen: boolean;
    lang: LangObject;
    isShareLink: boolean | undefined;
}> = ({
    permissions,
    collapseManage,
    setCollapseManage,
    sidebarOpen,
    lang,
    isShareLink,
}) => {
    if (permissions.canCreateOrganisation || permissions.canManageOrganisation)
        return (
            <>
                <SidebarDivider />
                <SidebarItemsDropdown
                    collapse={collapseManage}
                    setCollapse={() => {
                        setCollapseManage(!collapseManage);
                    }}
                    sidebarOpen={sidebarOpen}
                    text={lang.management}
                >
                    {permissions.canManageOrganisation && (
                        <>
                            {!isShareLink && (
                                <SidebarItem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={UsersIcon}
                                    text={lang.users}
                                    navigateToPage={
                                        Routes.ManageOrganisationUsers
                                    }
                                    isSubitem
                                />
                            )}
                            <SidebarItem
                                sidebarOpen={sidebarOpen}
                                IconSvg={OrganogramIcon}
                                text={lang.manageOrganisation}
                                navigateToPage={
                                    Routes.ManageOrganisationDetails
                                }
                                isSubitem
                            />
                            <SidebarItem
                                sidebarOpen={sidebarOpen}
                                IconSvg={MatrixIcon}
                                text={lang.jobMatrixArchitecture}
                                navigateToPage={Routes.ManageJobMatrix}
                                isSubitem
                            />
                            <SidebarItem
                                sidebarOpen={sidebarOpen}
                                IconSvg={LinkIcon}
                                text={'Share Links'}
                                navigateToPage={Routes.ManageShareLinks}
                                isSubitem
                            />
                            {permissions.canUpdateAlternativeValuationMethod && (
                                <SidebarItem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={CommunicationIcon}
                                    text={lang.manageValuation}
                                    navigateToPage={Routes.ValuationRoute}
                                    isSubitem
                                />
                            )}
                        </>
                    )}
                </SidebarItemsDropdown>
            </>
        );
    return null;
};

const Sidebar: React.FC<SidebarProps> = ({
    sidebarOpen,
    setActiveNavbarItem,
    options,
    permissions,
    currentLanguage,
    currentOrganisation,
    updates,
    isShareLink,
    shareLinkSettings,
    userLogout,
}) => {
    const isUsingMSAL = useIsAuthenticated();
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const lang = getLanguageObject(currentLanguage);
    const location = useLocation();
    const history = useHistory();
    const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);

    const [collapseManage, setCollapseManage] = useLocalStorageState(
        `styr-sidebar-manage`,
        false
    );
    const [collapseMatrix, setCollapseMatrix] = useLocalStorageState(
        `styr-sidebar-matrix`,
        false
    );
    const [collapseInformation, setCollapseInformation] = useLocalStorageState(
        `styr-sidebar-information`,
        false
    );

    const firstDateOfNotifications = moment().format('01/01/2023');

    useEffect(() => {
        const getSavedUpdates = localStorage.getItem('update-notification');
        const storedUpdates =
            getSavedUpdates === null ? [] : JSON.parse(getSavedUpdates);

        const hasUpdateOpened = updates.map(update => {
            const updateDate = moment(update.date).format('DD/MM/YYYY');
            return Boolean(
                storedUpdates.find(
                    (x: string) =>
                        x === update.id &&
                        updateDate >= firstDateOfNotifications
                )
            );
        });
        const updateAvailable = hasUpdateOpened.includes(false);
        setUpdateAvailable(updateAvailable);
    }, [updates, firstDateOfNotifications, currentOrganisation?.id]);

    useEffect(
        () => {
            setActiveNavbarItem(location.pathname);
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [location]
    );

    useEffect(() => {
        dispatch(updatesGetUpdates());
    }, [updatesGetUpdates]);

    return (
        <SidebarContainer>
            <SidebarNav full={sidebarOpen}>
                <div>
                    <ConnectedOrganisationDropdownMenu />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={DashboardIcon}
                        text={lang.dashboard}
                        navigateToPage={Routes.DashboardRoute}
                    />
                    {!isShareLink && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={AllocatorIcon}
                            text={lang.startAllocator}
                            navigateToPage={Routes.AllocatorRoute}
                        />
                    )}
                    {(!isShareLink ||
                        (isShareLink &&
                            shareLinkSettings?.show_styr_profile)) && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={SearchIcon}
                            text={lang.searchJobsnRoles}
                            navigateToPage={Routes.ResultsRoute}
                        />
                    )}
                    {options?.jobFamilies && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={JobProfilerIcon}
                            text={lang.manageJobFamily}
                            navigateToPage={Routes.ManageJobFamily}
                        />
                    )}
                    {(currentOrganisation?.jobMatrixEnabled ||
                        currentOrganisation?.talentMatrixEnabled) && (
                        <SidebarItemsDropdown
                            sidebarOpen={sidebarOpen}
                            text={lang.jobTalentMatrix}
                            collapse={collapseMatrix}
                            setCollapse={(newVal: boolean) => {
                                setCollapseMatrix(newVal);
                                if (newVal) {
                                    setCollapseManage(false);
                                }
                            }}
                        >
                            {currentOrganisation.jobMatrixEnabled && (
                                <SidebarItem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={MatrixIcon}
                                    text={lang.jobMatrix}
                                    navigateToPage={Routes.JobMatrixRoute}
                                    isSubitem
                                />
                            )}
                            {currentOrganisation.talentMatrixEnabled && (
                                <SidebarItem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={MatrixIcon}
                                    text={lang.talentMatrix}
                                    navigateToPage={Routes.TalentMatrix}
                                    isSubitem
                                />
                            )}
                        </SidebarItemsDropdown>
                    )}
                    {options?.tmaLink && !isShareLink && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={TalentIcon}
                            text={lang.talentManagementTool}
                            navigateToSite={options?.tmaLink}
                        />
                    )}
                    <SidebarDivider />
                    {!isShareLink && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={ELearningIcon}
                            text={lang.elearningStyrModel}
                            navigateToSite="https://styr.nl/e-learning/"
                        />
                    )}
                    {(permissions.canViewDocuments && !isShareLink) && (
                        <SidebarItem
                            sidebarOpen={sidebarOpen}
                            IconSvg={() => (
                                <DocumentIcon color="currentColor" />
                            )}
                            text={lang.documentOverview}
                            navigateToPage={Routes.DocumentsRoute}
                        />
                    )}
                    <MaybeRenderManagement
                        permissions={permissions}
                        collapseManage={collapseManage}
                        setCollapseManage={setCollapseManage}
                        sidebarOpen={sidebarOpen}
                        lang={lang}
                        isShareLink={isShareLink}
                    />
                    {!isShareLink && (
                        <>
                            <SidebarDivider />
                            <SidebarItemsDropdown
                                sidebarOpen={sidebarOpen}
                                text={lang.dockInformation}
                                collapse={collapseInformation}
                                setCollapse={(newVal: boolean) => {
                                    setCollapseInformation(newVal);
                                }}
                                updateAvailable={updateAvailable}
                            >
                                <SidebarItem
                                    isSubitem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={BellIcon}
                                    text={lang.styrUpdates}
                                    navigateToPage={Routes.LatestUpdates}
                                    updateAvailable={updateAvailable}
                                />
                                <SidebarItem
                                    isSubitem
                                    sidebarOpen={sidebarOpen}
                                    IconSvg={() => (
                                        <DownloadIcon color="white" />
                                    )}
                                    text={lang.ictIPS}
                                    navigateToPage={ictIps}
                                />
                            </SidebarItemsDropdown>
                        </>
                    )}
                </div>
                <SidebarLogoutItem>
                    <ConnectedCurrentUserSidebarItem />
                    <SidebarDivider />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={LogoutIcon}
                        text={lang.logout}
                        onClick={() => {
                            if (isUsingMSAL) {
                                instance.logoutPopup();
                            }
                            userLogout(history);
                        }}
                    />
                </SidebarLogoutItem>
            </SidebarNav>
        </SidebarContainer>
    );
};

export default Sidebar;
