import React, { FC } from 'react';
import ReduxStore from 'store/store.type';
import { connect } from 'react-redux';
import { Permissions } from 'store/user/user.types';
import { Language } from 'types/MultiLingualString';
import getLanguageObject, { LangObject } from 'helpers/language';
import { useLocation } from 'react-router-dom';
import { getAuthRules } from 'routes/Permissions';
import RequireAuthAD from './RequireAuthAD';

interface RequireAuthProps {
    children: React.ReactNode;
    permissions: Permissions;
    isShareLink?: boolean;
    requiredPermissions?: string[];
    allowShareLink?: boolean;
    currentLanguage: Language;
}

const RequireAuth: FC<RequireAuthProps> = ({
    children,
    permissions,
    isShareLink,
    allowShareLink,
    currentLanguage,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const path = useLocation().pathname;
    const isPublic = getAuthRules(path).includes('public');
    if (isPublic) return children;

    if (isShareLink) {
        if (allowShareLink || allowShareLink === undefined) return children;

        return getNoExcessElements(lang);
    }

    const hasPermission = getAuthRules(path);
    if (hasPermission.length > 0) {
        const hasRequiredPermissions = hasPermission.every(permission => {
            // Check if the permission is in the permissions object by using the permission as a key
            return permissions[permission as keyof Permissions];
        });

        if (!hasRequiredPermissions) return getNoExcessElements(lang);
    }

    return (
        <RequireAuthAD>
            {children}
        </RequireAuthAD>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    isShareLink: state.user.isShareLink,
    permissions: state.user.permissions,
    currentLanguage: state.language.currentLanguage,
});

const mapDispatchToProps = {  };
const ConnectedRequireAuth = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequireAuth);

export default ConnectedRequireAuth;

export const getNoExcessElements = (lang: LangObject) => {
    return (
        <>
            <div>
                <h1>{lang.noPermissionHeader}</h1>
                <p>{lang.noPermissionText}</p>
            </div>
        </>
    );
};
