import { matchPath } from "react-router-dom";
import Routes from "./Routes.types";

/**
 * A mapping of application routes with permissions required for access.
 * - Keys: Routes from the `Routes` enum.
 * - Values: An array of permission strings (e.g., 'canViewDocuments') required to access the route.
 *   Routes with an empty array (`[]`) are accessible without any specific permissions.
 */
export const PermissionsRecord: Record<string, string[]> = {
    [Routes.ManageOrganisationUsers]: ['canViewAllUsers'],
    [Routes.LoginRoute]: ['public'],
    [Routes.ShardRoute]: [],
    [`${Routes.ShardRoute}:id`]: ['public'],
    [Routes.DashboardRoute]: [],
    [Routes.AllocatorRoute]: [],
    [Routes.PdfRoute]: [],
    [Routes.ResultRoute]: [],
    [`${Routes.ResultPreviewRoute}:id`]: ['public'],
    [Routes.ResultsRoute]: [],
    [Routes.JobMatrixRoute]: [],
    [Routes.ManageJobMatrix]: [],
    [Routes.JobMatrixPrint]: [],
    [Routes.TalentMatrixPrint]: [],
    [Routes.TalentMatrix]: [],
    [Routes.LatestUpdates]: [],
    [Routes.StyrManual]: [],
    [Routes.ManageOrganisationDetails]: [],
    [Routes.UpdateRoute]: [],
    [Routes.PartnerRoute]: [],
    [Routes.ValuationRoute]: [],
    [Routes.ManageShareLinks]: [],
    [Routes.ManageJobFamily]: [],
    [Routes.JobFamilyEdit]: [],
    [Routes.JobFamilyView]: [],
    [Routes.AdminRoute]: [],
    [Routes.AdminAllocatorQuestionsRoute]: [],
    [Routes.AdminAllocatorQuestionRoute]: [],
    [Routes.AdminAllocatorValidatedProfilesRoute]: [],
    [Routes.AdminAllocatorValidatedProfileRoute]: [],
    [Routes.AdminUserManagementRoute]: [],
    [Routes.AdminUserManagementDetailRoute]: [],
    [Routes.DocumentsRoute]: ['canViewDocuments'],
};

/**
 * Get the permissions for a given path using AuthRecord.
 * @param path - The current pathname.
 * @returns - The permission for the matched route or an empty array if no match.
 */
export const getAuthRules = (path: string): string[] =>
    Object.entries(PermissionsRecord).find(([pattern]) =>
        matchPath(path, { path: pattern, exact: true })
    )?.[1] || [];