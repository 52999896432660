import styled from 'styled-components';
import theme from 'utils/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
`;

export const UpdateExistingUserContainer = styled.div`
    position: fixed;
    z-index: 10;
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    margin: 1em 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width: 500px;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
    top: 35%;
`;

export const BackdropBlocker = styled.div`
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
`;

export const Label = styled.label`
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    position: relative;
    display: flex;
    flex-direction: column;
`;
