import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import CopyLink from 'components/CopyLink/CopyLink';
import getLanguageObject from 'helpers/language';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { deleteShareLink } from 'store/user/user.actions';
import { capitalize } from 'utils/talentPaths';
import ConnectedPopUpCreateShareLink from './ManageShareLinks.components';
import { Main, Row } from './ManageShareLinks.styling';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import { Container, Header } from 'layouts/MainLayout/MainLayout.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import {
    TableContainer,
    TableItemContainer,
} from 'components/ResultsTable/ResultsTable.components';
import { HeaderItem } from 'components/TableElements/TableElements';
import useLocalStorageState from 'use-local-storage-state/dist';
import { compareShareLinks } from 'helpers/CompareFunctions';
import TrashIcon from 'components/Icons/trash';
import EditIcon from 'components/Icons/edit';
import ManageOrganisationProps from 'screens/ManageShareLinks/ManageShareLinks.types';

const ManageShareLinks: React.FC<ManageOrganisationProps> = ({
    currentLanguage,
    currentOrganisation,
    shareLinks,
    deleteShareLink,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [manageShareLink, setManageShareLink] = useState({
        popup: false,
        uuid: '',
    });
    const [deletePopup, setDeletePopup] = useState(false);

    const [state, setState] = useLocalStorageState<{
        sorting: 'name' | 'id';
        sortingOrder: boolean;
    }>('manageLinkSortOrder' + currentOrganisation?.id, {
        sorting: 'name',
        sortingOrder: false,
    });

    const changeSorting = (item: 'name' | 'id') => {
        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const handleStateManageShareLink = () =>
        setManageShareLink({
            ...manageShareLink,
            popup: !manageShareLink.popup,
        });

    const toggleOnOffClear = () => {
        setManageShareLink({ popup: !manageShareLink.popup, uuid: '' });
        return;
    };

    const toggleOnOffUUID = (uuid: string) => {
        setManageShareLink({ popup: !manageShareLink.popup, uuid: uuid });
        return;
    };

    const sortedShareLinks = shareLinks?.sort((a, b) => {
        return compareShareLinks(a, b, state.sorting);
    });

    if (state.sortingOrder) {
        sortedShareLinks?.reverse();
    }

    return (
        <>
            {manageShareLink.popup && (
                <ConnectedPopUpCreateShareLink
                    uuid={manageShareLink.uuid}
                    handleState={toggleOnOffClear}
                />
            )}
            <Container>
                <Header>
                    <BreadCrumbs
                        title={lang.manageShareLinkTitle}
                        paths={[
                            {
                                title: lang.dashboard,
                                path: Routes.DashboardRoute,
                            },
                            {
                                title: lang.manageShareLinkTitle,
                                path: Routes.ManageShareLinks,
                            },
                        ]}
                    />
                </Header>
                <Main>
                    <div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: lang.manageShareLinkDescription.replace(
                                    /\n/g,
                                    '<br />'
                                ),
                            }}
                        />
                    </div>
                    <TableContainer>
                        <TableItemContainer sticky>
                            <Row>
                                <HeaderItem
                                    onClick={() => {
                                        changeSorting('name');
                                    }}
                                    text="Name"
                                    activeItem={state.sorting === 'name'}
                                    sortingOrder={state.sortingOrder}
                                />
                                <HeaderItem
                                    onClick={() => {
                                        changeSorting('id');
                                    }}
                                    text="Link"
                                    activeItem={state.sorting === 'id'}
                                    sortingOrder={state.sortingOrder}
                                />
                                <div />
                                <div />
                            </Row>
                        </TableItemContainer>
                        {sortedShareLinks.map(sl => {
                            return (
                                <TableItemContainer key={sl.id}>
                                    <Row>
                                        <div>{capitalize(sl.name)}</div>
                                        <div>
                                            <CopyLink
                                                link={
                                                    window.location.host +
                                                    '/shared/' +
                                                    sl.id
                                                }
                                            />
                                        </div>
                                        <div>
                                            <IconButton
                                                IconSVG={EditIcon}
                                                color="primary"
                                                onClick={() =>
                                                    toggleOnOffUUID(sl.id)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <AreYouSurePopup
                                                onClick={() =>
                                                    deleteShareLink(sl.id)
                                                }
                                                action={lang.delete}
                                                item={lang.link}
                                                InnerContent={() => (
                                                    <IconButton
                                                        IconSVG={TrashIcon}
                                                        color="warning"
                                                        onClick={() =>
                                                            setDeletePopup(
                                                                !deletePopup
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Row>
                                </TableItemContainer>
                            );
                        })}
                    </TableContainer>
                </Main>
                <ConnectedBottomNavigation>
                    <div />
                    <Button
                        text={lang.createNewLink}
                        priority={'primary'}
                        onClick={handleStateManageShareLink}
                    />
                </ConnectedBottomNavigation>
            </Container>
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    shareLinks: state.user.currentOrganisation?.shareLinks || [],
});

const mapDispatchToProps = {
    deleteShareLink: deleteShareLink,
};

const ManageOrganisationMatrix = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageShareLinks);

export default ManageOrganisationMatrix;
