import apiRequest from 'helpers/api';
import { NIL as NIL_UUID } from 'uuid';
import { getOrganisationUnitAndUnitWithOrderByIndex } from 'helpers/arrays';
import { History } from 'history';
import { AnyAction, Dispatch } from 'redux';
import Routes from 'routes/Routes.types';
import store from 'store/store';
import { getRemoteLanguages } from 'store/language/language.actions';
import { Role } from 'types/Role';
import { getLevelIndex } from 'utils/levels';
import UserActions from './user.constants';
import { Organisation, OrganisationUser, MatrixLevelNotesObject, ShareLink, SsoConfiguration } from 'types/Organisation';
import { LoginShareLinkResponse } from './user.types';
import { getCurrentLanguageId } from 'store/language/language.actions';
import { createGuid } from './createGuid';
import { getToken } from 'store/tokenHelper';
import { handleApiError } from "../../helpers/errorHandler";
import { Domain } from "../../types/Domain";
import { UserErrorTypes } from "./user.errors";
import { toast } from "react-toastify";
import { ThunkAction } from 'redux-thunk';

export type UserAddError = (error: string) => void;
export const userAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: UserActions.AddError,
        payload: {
            error,
        },
    });
};

export const userAddManageOrganisationsError = (error: string) => (
    dispatch: Dispatch
) => {
    return dispatch({
        type: UserActions.AddManageOrgError,
        payload: {
            error,
        },
    });
};

export type UserClearErrors = () => void;
export const userClearErrors = () => (dispatch: Dispatch) => {
    return dispatch({
        type: UserActions.ClearErrors,
    });
};


export type UserSharedLinkLogin = (
    password: string,
    token: string,
    history: History,
    callback?: () => void,
) => void;

export const userSharedLinkLogin = (
    password: string,
    token: string,
    history: History,
    callback?: () => void
) => async (dispatch: Dispatch) => {
    const postData = {
        password: password
    }
    const state = store.getState();
    let id = token;

    if (token.length === 13) {
        id = await createGuid(token)
    }

    apiRequest(`Organisations/share/${id}/authorize`, 'POST', undefined, postData, {
        currentOrganisation: "3d7ab66f-236a-4a55-bac1-be3ce3a21070" //TODO: remove this in backend.
    })
        .then((data: LoginShareLinkResponse) => {
            if (!data.token) {
                return dispatch({
                    type: UserActions.AddError,
                    payload: {
                        error:
                            'The password you entered was incorrect',
                    },
                });
            }
            dispatch({
                type: UserActions.UpdateRole,
                payload: {
                    role: "Guest",
                },
            })
            dispatch({
                type: UserActions.UpdatePermissions,
                payload: {
                    permissions: {
                        canEditJobValidatedProfile: false,
                        canProvideStyrCompetencesAccess: false,
                        canDeleteResult: false,
                        canUpdateStatusResult: false,
                        canEditJobMatrix: false,
                        canManageOrganisation: false,
                        canInviteUser: false,
                        canInviteExpert: false,
                        canInvitePartnerUser: false,
                        canInvitePartnerExpert: false,
                        canEditOrganisationUnits: false,
                        canEditDifferentiatingFactors: false,
                        canUpdateAlternativeValuationMethod: false,
                        canCreateOrganisation: false,
                        canDuplicateOrganisation: false,
                        //@ts-ignore
                        canNotAccessLevels: data.shareLinkStyrLevels.map(x => x.levelCode.toLowerCase()),
                        canOnlyAccessOrganisationUnit: data.organisationUnitId,
                        canViewExtraFields: {
                            extraField1: data.showExtraColumn1,
                            extraField2: data.showExtraColumn2,
                            extraField3: data.showExtraColumn3,
                        },
                        canViewTags: data.showTags,
                    },
                },
            })

            dispatch({
                type: UserActions.Login,
                payload: {
                    jwt: data.token,
                    isShareLink: true,
                    share_link_settings: {
                        show_styr_profile: data.showStyrProfile,
                    },
                    role: "Guest",
                },
            });

            // Get all organisations
            //@ts-ignore
            dispatch(updateOrganisationsRequest({
                sharedLink: true
            }))

            setTimeout(() => {
                history.push(Routes.DashboardRoute);
            }, 1000)
        })
        .catch((e) => {
            handleApiError(Domain.User, UserErrorTypes.userSharedLinkLogin, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddError,
                payload: {
                    error: 'The username or password you entered was incorrect',
                },
            });
        }).finally(() => {
            if (callback) {
                callback();
            }
        })
};

export const userLoginMicrosoft = (
    token: string,
): ThunkAction<void, any, unknown, AnyAction> => async (dispatch, getState) => {
    dispatch({
        type: UserActions.Login,
        payload: {
            jwt: token,
        },
    })

    try {
        await dispatch(updateOrganisationsRequest())
        await Promise.all([
            dispatch(updateOrganisationUsers()),
            dispatch(getRemoteLanguages()),
            dispatch(updatePermissions()),
            dispatch(getRole()),
        ]);

        await dispatch(updateOrganisationRequest())
    } catch (error) {
        console.error('Error completing initial setup:', error);
    }
};

export const userLogout = (history?: History) => (dispatch: Dispatch) => {
    if (history) {
        history.push(Routes.LoginRoute);
    } else {
        window.location.href = Routes.LoginRoute;
    }
    return dispatch({
        type: UserActions.Logout,
    });
};

export type SetCurrentOrganisation = (id: string) => void;
export const setCurrentOrganisation: SetCurrentOrganisation = id => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const foundOrganisation = state.user.organisations.find(x => x.id === id);
    if (!foundOrganisation) {
        return dispatch({
            type: UserActions.AddError,
            payload: {
                error: 'Can not switch to non-existing organisation',
            },
        });
    }

    dispatch({
        type: UserActions.SetCurrentOrganisation,
        payload: {
            id,
        },
    });

    //@ts-ignore
    return dispatch(updateOrganisationRequest())
};


export type UpdateOrganisationRequest = ThunkAction<Promise<void | Organisation>, any, unknown, AnyAction>;
export const updateOrganisationRequest: () => UpdateOrganisationRequest = () => {
    return async (dispatch, getState): Promise<Organisation | void> => {
        const state = getState()

        if (!state.user.currentOrganisation?.id) {
          return Promise.resolve()
        }

        const endpoint = `organisations/${state.user.currentOrganisation.id}`

        try {
          const data: Organisation = await apiRequest(
            endpoint,
            'GET',
            state.user.jwt,
            false,
            { currentOrganisation: state.user.currentOrganisation.id }
          )

          dispatch({
            type: UserActions.UpdateOrganisation,
            payload: {
              organisation: {
                ...data,
                matrixLevelNotes: data.matrixLevelNotes || {},
                matrixLevelNotes2: data.matrixLevelNotes2 || {},
                matrixLevelNotes3: data.matrixLevelNotes3 || {},
              },
            },
          })

          await dispatch<any>(updateOrganisationUsers())
          return data
        } catch (error) {
          handleApiError(
            Domain.User,
            UserErrorTypes.updateOrganisationRequest,
            state.language.currentLanguage
          )
          // Rethrow so the caller can catch
          throw error
        }
      }
    }


export type GetCurrentSsoConfigurations = () => void;
export const getCurrentSsoConfigurations = () => (
    dispatch: Dispatch
) =>{
    const state = store.getState();

    if (state.user.isShareLink) {
        return;
    }

    if(!state.user.permissions.canConfigureSso)
        return;

    if (!state.user.currentOrganisation?.id) {
        return;
    }
    apiRequest(`login/${state.user.currentOrganisation?.id}`, 'GET', state.user.jwt, undefined, {
        isAccountApi: true,
        currentOrganisation: state.user.currentOrganisation?.id
    }).then((currentSsoConfigurations?: SsoConfiguration[]) => {
        dispatch({
            type: UserActions.SetCurrentSsoConfigurations,
            payload: {
                ssoConfigurations: currentSsoConfigurations || []
            },
        });
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationUsers, state.language.currentLanguage);
    })
}

export type UpdateOrganisationUsers = () => ThunkAction<Promise<void>, any, unknown, AnyAction>;
export const updateOrganisationUsers: UpdateOrganisationUsers = () => async (dispatch, getState) => {
    const state = getState();

    if (state.user.isShareLink || !state.user.currentOrganisation?.id) {
        return Promise.resolve();
    }

    const endpoint = `User/GetUsersForOrganisation/${state.user.currentOrganisation.id}`;

    try {
        const users: OrganisationUser[] = await apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
            isAccountApi: true,
            currentOrganisation: state.user.currentOrganisation.id,
        });

        dispatch({
            type: UserActions.SetUsersForCurrentOrganisation,
            payload: {
                users,
            },
        });

        return Promise.resolve();
    } catch (error) {
        // Handle API errors
        handleApiError(
            Domain.User,
            UserErrorTypes.updateOrganisationUsers,
            state.language.currentLanguage
        );

        return Promise.reject(error);
    }
};

interface UpdateOrganisationsRequestOptions {
    sharedLink?: boolean;
}

export type UpdateOrganisationsRequest = (
    updateOrganisationsRequestOptions?: UpdateOrganisationsRequestOptions
) => ThunkAction<Promise<void>, any, unknown, AnyAction>;

export const updateOrganisationsRequest = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => async (dispatch, getState) => {
        const state = getState();
        const endpoint = 'organisations/list';

        if (!state.user.jwt) {
            return Promise.reject(new Error('No JWT token found'));
        }

        try {
            const organisations: Organisation = await apiRequest(
                endpoint,
                'GET',
                state.user.jwt,
                undefined,
                { currentOrganisation: state.user.currentOrganisation?.id }
            );

            dispatch({
                type: UserActions.UpdateOrganisations,
                payload: {
                    organisations,
                    isSharedLink: false,
                },
            });


        } catch (error) {
            handleApiError(
                Domain.User,
                UserErrorTypes.updateOrganisationsRequest,
                state.language.currentLanguage
            );
            return Promise.reject(error)
        }
    };

export type ChangeRole = (id: string, role: Role | string) => void;
export const changeRole: ChangeRole = (id, role) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `User/UpdateUserOrganisationRole/${id}`;

    let data = role; //TODO: change to object in backend?
    if (role === "PartnerAdministrator") {
        data = "PartnerAdmin"
    }

    apiRequest(endpoint, 'PUT', state.user.jwt, data, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    })
        .then(() => {
            //@ts-ignore
            dispatch(updateOrganisationUsers())
        })
        .catch(() => {
            handleApiError(Domain.User, UserErrorTypes.changeRole, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddManageOrgError,
                payload: {
                    error: 'User role could not be changed.',
                },
            });
        });
};

export type InviteUser = (email: string, name: string, role: Role, organisationId?: string, userId?: string, sendEmail?: boolean) => void;
export const inviteUser: InviteUser = (email, name, role, organisationId, userId, sendEmail) => (dispatch: Dispatch) => {
    if (email.length < 5 || !email.includes('@')) {
        toast.error('Invalid email for invitation.')
        return dispatch({
            type: UserActions.AddManageOrgError,
            payload: {
                error: 'Invalid email for invitation.',
            },
        });
    }

    const state = store.getState();
    const organisation = organisationId ?? state.user.currentOrganisation?.id;
    if (!organisation) {
        return dispatch({
            type: UserActions.AddError,
            payload: {
                error: 'Can not invite users for non-existing organisation',
            },
        });
    }
    const sendEmailQuery = sendEmail ? '?sendEmail=true' : '';
    const endpoint = `User/InviteUser/${organisation}${sendEmailQuery}`;

    const newUser = {
        emailAdress: email,
        roleName: role,
        firstName: name.split(' ')[0],
        lastName: name.split(' ').slice(1).join(' '),
    };

    apiRequest(endpoint, 'POST', state.user.jwt, newUser, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    })
        .then(() => {
            if (userId) {
                //@ts-ignore
                dispatch(loadSelectedUser(userId))
            } else {
                //@ts-ignore
                dispatch(updateOrganisationUsers())
            }
        })
        .catch(() => {
            handleApiError(Domain.User, UserErrorTypes.inviteUser, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddManageOrgError,
                payload: {
                    error: 'That user is already invited to Styr.',
                },
            });
        });
};

export type InvitePartner = (email: string) => void;
export const invitePartner: InvitePartner = email => (dispatch: Dispatch) => {
    if (email.length < 5 || !email.includes('@')) {
        toast.error('Invalid email for invitation.')
        return dispatch({
            type: UserActions.AddManageOrgError,
            payload: {
                error: 'Invalid email for invitation.',
            },
        });
    }

    const state = store.getState();
    const organisation = state.user.currentOrganisation?.id;
    if (!organisation) {
        toast.error('Can not invite users for non-existing organisation.')
        return dispatch({
            type: UserActions.AddError,
            payload: {
                error: 'Can not invite users for non-existing organisation',
            },
        });
    }
    const endpoint = `organisations/partner`;

    const body = {
        organisation,
        email,
    };

    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(() => { })
        .catch((error) => {
            handleApiError(Domain.User, UserErrorTypes.invitePartner, state.language.currentLanguage)
        })
};

export type DeleteUserFromApplication = (userid: string) => void;
export const deleteUserFromApplication: DeleteUserFromApplication = (userid) => (dispatch: Dispatch) => {
    const state = store.getState();

    const endpoint = `User/${userid}`;

    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    })
        .then(() => {
            //@ts-ignore
            dispatch(updateOrganisationUsers())
            //@ts-ignore
            dispatch({
                type: UserActions.UpdateSelectedUserOrganisation,
                payload: {
                    selectedOrganisationUser: null
                },
            });
        })
        .catch(() => {
            handleApiError(Domain.User, UserErrorTypes.deleteUser, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddManageOrgError,
                payload: {
                    error: 'Could not delete user.',
                },
            });
        });
};

export type UpdateUserName = (id: string, firstName: string, lastName: string) => void;
export const updateUserName: UpdateUserName = (id, firstName, lastName) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `User/${id}/username`;

    const body = {
        id,
        firstName,
        lastName,
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    })
        .then(() => {
            //@ts-ignore
            dispatch(updateOrganisationUsers())
        })
        .catch(() => {
            handleApiError(Domain.User, UserErrorTypes.updateUserName, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddManageOrgError,
                payload: {
                    error: 'Could not update user name.',
                },
            });
        });
};

export type DeleteUser = (userid: string, organisationId?: string) => void;
export const deleteUser: DeleteUser = (userid, organisationId) => (dispatch: Dispatch) => {
    const state = store.getState();
    const organisation = organisationId ?? state.user.currentOrganisation?.id;
    if (!organisation) {
        toast.error('Can not delete users for non-existing organisation.')
        return dispatch({
            type: UserActions.AddError,
            payload: {
                error: 'Can not delete users for non-existing organisation',
            },
        });
    }
    const endpoint = `User/RemoveUserfromOrganisation/${userid}/${organisation}`;

    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    })
        .then(() => {
            if (organisationId) {
                //@ts-ignore
                dispatch(loadSelectedUser(userid))
            } else {
                //@ts-ignore
                dispatch(updateOrganisationUsers())
            }
        })
        .catch(() => {
            handleApiError(Domain.User, UserErrorTypes.deleteUser, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddManageOrgError,
                payload: {
                    error: 'Could not delete user.',
                },
            });
        });
};

export const deleteOrganisation = (id: string, history: History) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/${id}`;
    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(() => {
        dispatch({
            type: UserActions.DeleteOrganisation,
            payload: {
                organisationId: id,
            },
        });

        const orgsWithoutcurrent = state.user.organisations.filter(
            x => x.id !== id
        );

        if (orgsWithoutcurrent.length > 0) {
            const org = orgsWithoutcurrent[0];
            if (org) {
                //@ts-ignore
                setCurrentOrganisation(org.id)(dispatch);
                history.push(Routes.DashboardRoute);
            }
        }

    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.deleteOrganisation, state.language.currentLanguage)
    });
}

export const duplicateOrganisation = (name: string, id: string, history: History) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/duplicate/` + id;
    const body = {
        id,
        name,
    };
    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation) => {
            dispatch({
                type: UserActions.AddOrganisation,
                payload: {
                    organisation,
                },
            });
            history.push(Routes.ManageOrganisationUsers);

            //eslint-disable-next-line
            //@ts-ignore
            updateOrganisationsRequest()(dispatch);
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.duplicateOrganisation, state.language.currentLanguage)
    });
};


export const createNewOrganisation = (name: string, history: History) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        name,
    };

    if (!name) {
        return
    }

    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.AddOrganisation,
                payload: {
                    organisation,
                },
            });
            history.push(Routes.ManageOrganisationUsers);
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.createNewOrganisation, state.language.currentLanguage)
    });
};

export type UpdateOrganisationValuationRequest = (id: string, subs: { [key: string]: string }, enable: boolean) => void;

export const updateOrganisationValuation: UpdateOrganisationValuationRequest = (id, subs, enable) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/styrlevelmap`;
    const body = {
        "organisationId": id,
        "enabled": enable,
        "data": subs,
        "alternativeTitle": subs.title || ""
    };
    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationValuation, state.language.currentLanguage)
    });
};

export const updateCurrentSsoConfiguration = (ssoConfiguration: SsoConfiguration): (() => Promise<SsoConfiguration | null | undefined>) => () => {
    const state = store.getState();
    const endpoint = ssoConfiguration.id ? `login/update` : `login/create`;
    const body = {
        ...ssoConfiguration,
        organisationId: state.user.currentOrganisation?.id,
    };
    return apiRequest(endpoint, ssoConfiguration.id ? 'PUT': 'POST', state.user.jwt, body, {
        isAccountApi: true,
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateCurrentSsoConfiguration, state.language.currentLanguage)
    });

}

export const updateOrganisationContractContactPerson = (id: string, contractContactPerson: string) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        id,
        contractContactPerson
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationContractContactPerson, state.language.currentLanguage)
    });
}

export const updateOrganisationTMALink = (id: string, link: string, jobFamilies?: boolean) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        id,
        options: {
            tmaLink: link,
            jobFamilies: jobFamilies,
        },
    };
    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationTMALink, state.language.currentLanguage)
    });
}

export const updateOrganisationJobFamilies = (id: string, jobFamilies?: boolean) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        id,
        options: {
            ...state.user.currentOrganisation?.options,
            jobFamilies: jobFamilies,
        },
    };

    if (!state.user.currentOrganisation) {
        return
    }

    dispatch({
        type: UserActions.UpdateOrganisation,
        payload: {
            organisation: {
                ...state.user.currentOrganisation,
                options: {
                    ...state.user.currentOrganisation?.options,
                    jobFamilies: jobFamilies,
                }
            },
        },
    });

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationJobFamilies, state.language.currentLanguage)
    });
}

export const updateOrganisationName = (id: string, name: string) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        id,
        name,
    };
    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationName, state.language.currentLanguage)
    });
};

export const updateOrganisationMatrixes = (id: string, jobMatrixEnabled: boolean, talentMatrixEnabled: boolean) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const body = {
        id,
        jobMatrixEnabled,
        talentMatrixEnabled,
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (organisation: Organisation) => {
            dispatch({
                type: UserActions.UpdateOrganisation,
                payload: {
                    organisation,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationMatrixes, state.language.currentLanguage)
    });
};

export type UpdateFamType = "add" | "remove" | "up" | "down" // all types of updates you can do to an item of a job family

export type UpdateResultInFamily = (
    resultID: string,
    familyID: string,
    action: UpdateFamType
) => void;

export const updateResultInFamily = (resultID: string, familyID: string, action: UpdateFamType) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/family/${familyID}/jobs`;
    if (!state.user.currentOrganisation) {
        toast.error('Tried to add result to family without a selected current organisation')
        console.error("Tried to add result to family without a selected current organisation")
        return;
    }

    const currentFamily = state.user.currentOrganisation.jobFamilies.find(x => x.id === familyID);
    if (!currentFamily) {
        toast.error('Can not find the familyID in the current organisation')
        console.error("Can not find the familyID in the current organisation")
        return;
    }

    const resultIds = currentFamily.jobs || []
    const resultJobs = state.results.results.filter(r => resultIds.find(y => y?.id === r.id))

    const currentResults = resultIds.map(id =>
        resultJobs.find(j => j.id === id.id)!)
        .sort((a, b) => getLevelIndex(b!.level) - getLevelIndex(a!.level))
        .filter(r => Boolean(r))
        .map(r => r.id)

    // [add] or [remove] a result from a job family
    const updateResultsMutationOp = (currentResults: string[]) => { return currentResults.includes(resultID) ? currentResults.filter(x => x !== resultID) : [...currentResults, resultID] }

    // move an item from a job family [up] or [down]
    const updateResultsArrowOp = (results: string[], action: UpdateFamType) => {
        const fromIndex = results.indexOf(resultID)
        const toIndex = fromIndex + (action === "up" ? -1 : 1)
        const element = results.splice(fromIndex, 1)[0];
        results.splice(toIndex, 0, element);
        return results
    }

    const newResults = (action === "add" || action === "remove") ? updateResultsMutationOp(currentResults) : updateResultsArrowOp(currentResults, action)

    const body = newResults;

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateResultInFamily, state.language.currentLanguage)
    });
};

export const addOrganisationUnit = (name: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const endpoint = `organisations/organisationUnit`;

    const body = {
        name: [{
            languageId: currentLanguageCode,
            translation: name
        }],
        organisationId: state.user.currentOrganisation?.id,
    };
    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.addOrganisationUnit, state.language.currentLanguage)
    })
        .finally(
            () => {
                //@ts-ignore
                return dispatch(updateOrganisationRequest())
            }
        );
};

export const updateOrganisationUnit = (name: string, organisationUnit: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const currentLanguageCode = getCurrentLanguageId(state.language)
    const endpoint = `organisations/organisationUnit`;

    const body = {
        id: organisationUnit,
        name: [{
            languageId: currentLanguageCode,
            translation: name
        }],
        organisationId: state.user.currentOrganisation?.id,
    };
    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateOrganisationUnit, state.language.currentLanguage)
    })
        .finally(
            () => {
                //@ts-ignore
                return dispatch(updateOrganisationRequest())
            });
};

export const deleteOrganisationUnit = (organisationUnit: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/organisationUnit/` + organisationUnit;
    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch(() => {
        handleApiError(Domain.User, UserErrorTypes.deleteOrganisationUnit, state.language.currentLanguage)
    }).finally(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    );
};

export const addUnit = (name: string, organisationUnit: string) => (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/unit`;
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const body = {
        name: [{
            languageId: currentLanguageCode,
            translation: name
        }],
        organisationUnitId: organisationUnit,
        organisationId: state.user.currentOrganisation?.id,
    };
    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.addUnit, state.language.currentLanguage)
    })
        .finally(
            () => {
                //@ts-ignore
                return dispatch(updateOrganisationRequest())
            }
        );
};

export const deleteUnit = (unit: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/unit/` + unit;

    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch(() => {
        handleApiError(Domain.User, UserErrorTypes.deleteUnit, state.language.currentLanguage)
    }).finally(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    );
};

export const updateUnit = (name: string, unit: string, organisationUnit?: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/unit`;
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const body = {
        id: unit,
        name: [{
            languageId: currentLanguageCode,
            translation: name
        }],
        organisationId: state.user.currentOrganisation?.id,
        organisationUnitId: organisationUnit
    };

    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateUnit, state.language.currentLanguage)
    })
        .finally(
            () => {
                //@ts-ignore
                return dispatch(updateOrganisationRequest())
            }
        );
};

export const updateUnitsAndOrganisationUnitsOrder = () => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/updateSortOrder`;
    const organisationUnits = state.user.currentOrganisation?.organisationUnits;
    if (organisationUnits) {
        const body = {
            organisationUnits: getOrganisationUnitAndUnitWithOrderByIndex(organisationUnits),
            id: state.user.currentOrganisation?.id,
        };
        apiRequest(endpoint, 'POST', state.user.jwt, body, {
            currentOrganisation: state.user.currentOrganisation?.id
        }).catch((error) => {
            handleApiError(Domain.User, UserErrorTypes.updateUnitsAndOrganisationUnitsOrder, state.language.currentLanguage)
        });
    }
};

export const updateLevelNotes = (notes: MatrixLevelNotesObject, suffix?: String) => async (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/notes`;
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const token = await getToken(state, dispatch);

    const mappedNotes = Object.keys(notes).reduce((acc, key: string): { [key: string]: string } => {
        const note = notes[key as keyof MatrixLevelNotesObject];
        if (key === "title") {
            return {
                ...acc,
                title: note
            };
        }
        if (key.length > 2) {
            return acc
        }

        if (key.toLowerCase() === "id") {
            return acc
        }

        return {
            ...acc,
            ["level" + key.toUpperCase()]: note
        };
    }, {});

    const suffixToLevelNoteType = () => {
        if (suffix === "1") {
            return "One"
        } else if (suffix === "2") {
            return "Two"
        } else if (suffix === "3") {
            return "Three"
        } else {
            return "One"
        }
    }

    const body = {
        id: state.user.currentOrganisation?.id,
        organisationId: state.user.currentOrganisation?.id,
        translations: [{
            ...mappedNotes,
            languageId: currentLanguageCode,
        }],
        suffix,
        levelNoteType: suffixToLevelNoteType(),
    };

    const getNotesIndexInCurrentOrganisation = () => {
        if (suffix === "1" || !suffix) {
            return "matrixLevelNotes";
        }
        return "matrixLevelNotes" + suffix;
    }

    dispatch({
        type: UserActions.UpdateOrganisation,
        payload: {
            organisation: {
                ...state.user.currentOrganisation,
                [getNotesIndexInCurrentOrganisation()]: {
                    // eslint-disable-next-line
                    // @ts-ignore
                    ...state.user.currentOrganisation?.[getNotesIndexInCurrentOrganisation()],
                    ...notes
                },
            },
        },
    });

    apiRequest(endpoint, 'PUT', token, [body], {
        currentOrganisation: state.user.currentOrganisation?.id
    }).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateLevelNotes, state.language.currentLanguage)
    });
};

export const updateUnitOrder = (organisationUnit: string, oldIndex: number, newIndex: number) => (dispatch: Dispatch) => {
    const state = store.getState();
    if (state.user.currentOrganisation) {
        dispatch({
            type: UserActions.UpdateUnitOrder,
            payload: {
                organisation: state.user.currentOrganisation,
                oldIndex,
                newIndex,
                organisationUnit
            }
        })
        updateUnitsAndOrganisationUnitsOrder()(dispatch);
    }
}

export const updateOrganisationUnitOrder = (oldIndex: number, newIndex: number) => (dispatch: Dispatch) => {
    const state = store.getState();
    if (state.user.currentOrganisation) {
        dispatch({
            type: UserActions.UpdateOrganisationUnitOrder,
            payload: {
                organisation: state.user.currentOrganisation,
                oldIndex,
                newIndex
            }
        })
        updateUnitsAndOrganisationUnitsOrder()(dispatch);
    }
}

export const uploadOrganisationImage = (image: string, fileExtension: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/${state.user.currentOrganisation?.id}/picture`;
    const body = {
        id: state.user.currentOrganisation?.id,
        fileName: state.user.currentOrganisation?.id,
        image,
        fileExtension: fileExtension,
    };
    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        })
        .catch((error) => {
            handleApiError(Domain.User, UserErrorTypes.uploadOrganisationImage, state.language.currentLanguage)
        });
}

export type CreateShareLink = (
    shareLink: ShareLink
) => void;

export const createShareLink: CreateShareLink = (shareLink) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/share`;
    const body = {
        organisationId: state.user.currentOrganisation?.id,
        ...shareLink,
        id: undefined
    };
    apiRequest(endpoint, 'POST', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    )
}

export type UpdateShareLink = (
    shareLink: ShareLink
) => void;

export const updateShareLink: UpdateShareLink = (shareLink) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/share`;
    const body = {
        organisationId: state.user.currentOrganisation?.id,
        ...shareLink,
        organisationUnitId: shareLink.organisationUnitId || NIL_UUID

    };
    apiRequest(endpoint, 'PUT', state.user.jwt, body, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.updateShareLink, state.language.currentLanguage)
    })
}

export type DeleteShareLink = (
    id: string
) => void;

export const deleteShareLink: DeleteShareLink = (id) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/share/` + id;
    apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            return dispatch(updateOrganisationRequest())
        }
    ).catch((error) => {
        handleApiError(Domain.User, UserErrorTypes.deleteShareLink, state.language.currentLanguage)
    })
}

export type UpdatePermissions = () => ThunkAction<Promise<void>, any, unknown, AnyAction>;

export const updatePermissions: UpdatePermissions = () => async (dispatch, getState) => {
    const state = getState();

    if (state.user.isShareLink || !state.user.currentOrganisation?.id) {
        return Promise.resolve();
    }

    try {
        const token = state.user.jwt || '';
        const decodedToken = JSON.parse(atob(token.split('.')[1]));

        const endpoint = `User/GetUserPermissions/${decodedToken?.extension_Uuid}/${state.user.currentOrganisation.id}`;

        const result: string[] = await apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
            currentOrganisation: state.user.currentOrganisation.id,
            isAccountApi: true,
        });

        await dispatch({
            type: UserActions.UpdatePermissions,
            payload: {
                permissions: {
                    canEditJobValidatedProfile: result.includes("SetJobActiveStatus"),
                    canProvideStyrCompetencesAccess: result.includes("ToggleStyrTmaNormedCompetencies"),
                    canDeleteResult: result.includes("SetJobActiveStatus"),
                    canUpdateStatusResult: result.includes("SetJobActiveStatus"),
                    canEditJobMatrix: result.includes("EditJobMatrixView"),
                    canManageOrganisation: result.includes("EditJobMatrixArchitecture"),
                    canInviteUser: result.includes("AddUserToOrganisation"),
                    canInviteExpert: result.includes("AddExpertUserToOrganisation"),
                    canInvitePartnerUser: result.includes("AddOrganisation"),
                    canInvitePartnerExpert: result.includes("AddOrganisation"),
                    canInviteAdministrator: result.includes("CanAddUsersToAllOrganisations"),
                    canRemoveUserFromOrganisation: result.includes("RemoveUserFromOrganisation"),
                    canViewAllUsers: result.includes("ViewAllUsers"),
                    canEditOrganisationUnits: result.includes("EditJobMatrixArchitecture"),
                    canEditDifferentiatingFactors: result.includes("EditJobMatrixArchitecture"),
                    canUpdateAlternativeValuationMethod: result.includes("AddOrganisation"),
                    canCreateOrganisation: result.includes("AddOrganisation"),
                    canDeleteOrganisation: result.includes("DeleteOrganisation"),
                    canDuplicateOrganisation: result.includes("DuplicateOrganisation"),
                    canNotAccessLevels: [],
                    canOnlyAccessOrganisationUnit: "",
                    canToggleStyrTmaNormedCompetencies: result.includes("ToggleStyrTmaNormedCompetencies"),
                    canViewDocuments: result.includes("CanViewDocuments"),
                    canConfigureSso: result.includes("CanConfigureSSO"),
                    canManageContractContactPersons: result.includes("CanManageContractContactPersons"),
                    canUpdateUserName: result.includes("UpdateUserName"),
                    canViewExtraFields: {
                        extraField1: true,
                        extraField2: true,
                        extraField3: true,
                    },
                    canViewTags: true,
                },
            },
        });

        return Promise.resolve();
    } catch (error) {
        handleApiError(Domain.User, UserErrorTypes.updatePermissions, state.language.currentLanguage);
        console.warn('Error updating permissions:', error);

        return Promise.reject(error);
    }
};

// Get role
export type GetRole = () => ThunkAction<Promise<void>, any, unknown, AnyAction>;
export const getRole: GetRole = () => async (dispatch, getState) => {
    const state = getState();
    const token = state.user.jwt;

    if (!token || state.user.isShareLink || !state.user.currentOrganisation?.id) {
        return Promise.resolve(); // Return a resolved promise if no action is needed
    }

    const endpoint = `User/GetCurrentUserRole`;

    try {
        const result: { displayName: string } = await apiRequest(endpoint, 'GET', token, undefined, {
            currentOrganisation: state.user.currentOrganisation.id,
            isAccountApi: true,
        });

        // Dispatch the updatePermissions action and wait for it to complete
        await dispatch(updatePermissions());

        // Update the role in Redux store
        dispatch({
            type: UserActions.UpdateRole,
            payload: {
                role: result.displayName,
            },
        });

        return Promise.resolve(); // Indicate successful completion
    } catch (error) {
        // Handle API errors
        handleApiError(Domain.User, UserErrorTypes.getRole, state.language.currentLanguage);
        console.warn('Error fetching role:', error);

        return Promise.reject(error); // Indicate failure
    }
};


export type LoadPagedUsers = (skip: number, take: number, sortby: string, sortOrder: string, organizationId?: string) => Promise<OrganisationUser>;
export const loadPagedUsers = (skip: number, take: number, sortby: string, sortOrder: string, organizationId?: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<OrganisationUser>((reject) => {
        let endpoint = `admin/users?skip=${skip}&take=${take}&orderByField=${sortby}&sortOrder=${sortOrder}`;
        if (organizationId)
            endpoint = `${endpoint}&organisationId=${organizationId}`;
        apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
            isAccountApi: true,
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((result) => {
                dispatch({
                    type: UserActions.UpdateAllUsers,
                    payload: {
                        allUsers: result.data,
                        allUsersTotalCount: result.totalCount
                    },
                });
            })
            .catch((error) => {
                //TODO: add Admin domain to error handler
                handleApiError(Domain.User, UserErrorTypes.loadPagedUserRequest, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type UpdateUser = (user: OrganisationUser) => void;
export const updateUser: UpdateUser = (user) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `User/${user.id}`;
    apiRequest(endpoint, 'PUT', state.user.jwt, user, {
        currentOrganisation: state.user.currentOrganisation?.id,
        isAccountApi: true
    }).then(
        () => {
            //@ts-ignore
            dispatch(loadSelectedUser(user.id))
        }
    ).catch((error) => {
        //TODO: add Admin domain to error handler
        handleApiError(Domain.User, UserErrorTypes.updateUserRequest, state.language.currentLanguage)
    });
};

export type LoadSelectedUser = (id: string) => Promise<OrganisationUser>;
export const loadSelectedUser = (id: string) => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<OrganisationUser>((reject) => {
        apiRequest(`user/${id}`, 'GET', state.user.jwt, undefined, {
            isAccountApi: true,
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((result) => {
                dispatch({
                    type: UserActions.UpdateSelectedUserOrganisation,
                    payload: {
                        selectedOrganisationUser: result
                    },
                });
            })
            .catch((error) => {
                //TODO: add Admin domain to error handler
                handleApiError(Domain.User, UserErrorTypes.loadSelectedUserRequest, store.getState().language.currentLanguage)
                reject(error)
            });
    })
};

export type LoadAllOrganisations = () => Promise<Organisation[]>;
export const loadAllOrganisations = () => (dispatch: Dispatch) => {
    const state = store.getState();
    return new Promise<Organisation[]>((reject) => {
        apiRequest(`admin/organisations`, 'GET', state.user.jwt, undefined, {
            isAccountApi: true,
            currentOrganisation: state.user.currentOrganisation?.id
        })
            .then((result) => {
                dispatch({
                    type: UserActions.UpdateAllOrganisations,
                    payload: {
                        organisations: result,
                    },
                });
            })
            .catch((error) => {
                //TODO: add Admin domain to error handler
                handleApiError(Domain.User, UserErrorTypes.loadAllOrganisationsRequest, store.getState().language.currentLanguage)
                reject(error)
            }
            );
    })
};
