import React, { useEffect } from 'react';
import getLanguageObject from 'helpers/language';
import {
    ManageOrganisationUsersContainer,
    RenderUsers,
} from './ManageOrganisationUsers.components';
import ManageOrganisationUsersProps from './ManageOrganisationUsers.types';
import { useOrganisation } from 'providers/Organisation.provider';

const ManageOrganisationUsers: React.FC<ManageOrganisationUsersProps> = ({
    users,
    deleteUser,
    currentLanguage,
    permissions,
}) => {
    const { updateOrganisation } = useOrganisation();

    useEffect(() => {
        updateOrganisation();
    }, [updateOrganisation]);

    const lang = getLanguageObject(currentLanguage);

    return (
        <ManageOrganisationUsersContainer>
            <RenderUsers
                deleteUser={deleteUser}
                lang={lang}
                users={users}
                permissions={permissions}
            />
        </ManageOrganisationUsersContainer>
    );
};

export default ManageOrganisationUsers;
