
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConnectedResultSection from 'sections/ValidatedProfiles/ResultSection/ResultSection.connector';
import ResultProps from './Result.types';

const Result: React.FC<ResultProps> = () => {
    const { id } = useParams<{ id: string }>();
    const [popupState, setPopup] = useState(false);
    const popupHandler = () => setPopup(!popupState);

    return (
        <ConnectedResultSection
            popupState={popupState}
            popupHandler={popupHandler}
            id={id}
        />
    );
};

export default Result;
