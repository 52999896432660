import React from 'react';
import { Container } from './Dashboard.components';
import DashboardProps from './Dashboard.types';
import ConnectedDashboardBlocksSection from 'sections/DashboardBlocksSection/DashboardBlocksSection.connector';

const Dashboard: React.FC<DashboardProps> = () => {
    return (
        <Container>
            <ConnectedDashboardBlocksSection />
        </Container>
    );
};

export default Dashboard;
