import { AnyAction, Dispatch } from 'redux';
import { Language, MultiLingualObject, MultiLingualString } from 'types/MultiLingualString';
import LanguageActions from './language.constants';
import { languages } from 'helpers/language';
import LanguageState, { RemoteLanguage } from './language.types';
import apiRequest from 'helpers/api';
import {handleApiError} from "../../helpers/errorHandler";
import {Domain} from "../../types/Domain";
import {LanguageErrorTypes} from "./language.errors";
import { ThunkAction } from 'redux-thunk';

export const languageAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: LanguageActions.AddError,
        payload: {
            error,
        },
    });
};

export const getRemoteLanguages = (): ThunkAction<Promise<void>, any, unknown, AnyAction> => async (
    dispatch,
    getState
) => {
    const state = getState();

    if (state.language.remoteLanguages.length > 0) {
        return Promise.resolve();
    }

    const endpoint = 'languages';

    try {
        const data: RemoteLanguage[] = await apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
            currentOrganisation: state.user.currentOrganisation?.id,
        });

        dispatch({
            type: LanguageActions.UpdateRemoteLanguages,
            payload: {
                data: data.filter((l) => l.active),
            },
        });

        return Promise.resolve();
    } catch (error) {
        handleApiError(
            Domain.Language,
            LanguageErrorTypes.getRemoteLanguages,
            state.language.currentLanguage
        );

        return Promise.reject(error);
    }
};

export const setCurrentLanguage = (language: Language) => (
    dispatch: Dispatch
) => {
    return dispatch({
        type: LanguageActions.SetCurrentLanguage,
        payload: {
            currentLanguage: language,
        },
    });
};


// Retrieves a string in the current language, or falls back to the next available language.
export const getStringFromCurrentLanguage = (
    string: MultiLingualString | undefined,
    language: Language
): string => {
    if(!string) {
        return ""
    }
    // Attempt to return the string in the requested language.
    if (string[language]) {
      return string[language];
    }

    // If the requested language is not available, iterate through the languages array.
    for (const lang of languages) {
      if (string[lang]) {
        return string[lang]; // Return the first available translation.
      }
    }

    // If no translations are available, return an empty string.
    return '';
};

// Retrieves an object in the current language, or falls back to the next available language.
export function getObjectFromCurrentLanguage<T>(
    object: MultiLingualObject<T> | undefined,
    language: Language
): T | undefined {
    // Check if the object is defined.
    if (!object) {
      return undefined;
    }

    // Attempt to return the object in the requested language.
    if (object[language]) {
      return object[language] || undefined;
    }

    // If the requested language is not available, iterate through the languages array.
    for (const lang of languages) {
      if (object[lang]) {
        return object[lang] || undefined; // Return the first available translation.
      }
    }

    // If no translations are found, return undefined.
    return undefined;
};

// Retrieves an object in the current language, or falls back to English only.
export function getObjectFromCurrentLanguageStrict<T>(
    object: MultiLingualObject<T> | undefined,
    languageId: string
): T | null {
    //@ts-ignore
    const foundTranslation = object?.translations?.find(x => x.languageId === languageId) || null;

    //@ts-ignore
    if(!foundTranslation && object?.translations?.length > 0) {
        //@ts-ignore
        return object?.translations[0] || null;
    }

    return foundTranslation;
}

export const getCurrentLanguageId = (state: LanguageState) => {
    const currentLang = state.currentLanguage;
    return state.remoteLanguages.find(l => l.code === currentLang)?.id;
}

export const convertMultilingualStringToLanguageObject = (state: LanguageState, string?: MultiLingualString) => {
    if(!string) {
        return []
    }
    const result: { languageId: string; translation: any; }[] = state.remoteLanguages.map((lang) => {
        const translation = string.hasOwnProperty(lang.code) ? string[lang.code as keyof MultiLingualString] : ""
        return {
            "languageId": lang.id,
            "translation": translation
        }
    })

    return result;
}
