import { connect } from 'react-redux';
import { UpdateExistingUser } from 'sections/ManageOrganisation/UpdateExistingUser/UpdateExistingUser';
import ReduxStore from 'store/store.type';
import { updateUserName } from 'store/user/user.actions';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = { updateUserName };
const ConnectedUpdateExsistingUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateExistingUser);

export default ConnectedUpdateExsistingUser;
