import getLanguageObject from 'helpers/language';
import React, { useEffect } from 'react';
import Routes from 'routes/Routes.types';
import {
    BlocksGrid,
    DashboardBlocks,
} from './DashboardBlocksSection.components';
import DashboardBlockSectionProps, {
    DashboardBlockProps,
} from './DashboardBlocksSection.types';

interface Block extends DashboardBlockProps {
    show: boolean;
}

const DashboardBlocksSection: React.FC<DashboardBlockSectionProps> = ({
    isSharedLink,
    currentLanguage,
    currentOrganisation,
    permissions,
    share_link_settings
}) => {
    const lang = getLanguageObject(currentLanguage);

    const blocks: Block[] = [
        {
            title: lang.jobTalentMatrix,
            image: require('../../assets/images/JobMatrix.png'),
            navigateToPage: Routes.JobMatrixRoute,
            show: Boolean(currentOrganisation?.jobMatrixEnabled),
        },
        {
            title: lang.startAllocator,
            image: require('../../assets/images/icons/StartAllocator.png'),
            navigateToPage: Routes.AllocatorRoute,
            show: !isSharedLink,
        },
        {
            title: lang.searchJobsnRoles,
            image: require('../../assets/images/SearchJobsRoles.png'),
            navigateToPage: Routes.ResultsRoute,
            show: Boolean(!isSharedLink || (isSharedLink && share_link_settings?.show_styr_profile)),
        },
        {
            title: lang.manageJobFamily,
            image: require('../../assets/images/icons/JobProfiler.png'),
            navigateToPage: Routes.ManageJobFamily,
            show:
                Boolean(currentOrganisation?.options?.jobFamilies) &&
                !isSharedLink,
        },
        {
            title: lang.elearningStyrModel,
            image: require('../../assets/images/ElearningStyrModel.png'),
            navigateToSite: 'https://styr.nl/e-learning/',
            show: !isSharedLink,
        },
        {
            title: lang.talentManagementTool,
            image: require('../../assets/images/TalentManagementTool.png'),
            navigateToSite: currentOrganisation?.options?.tmaLink || undefined,
            show:
                Boolean(currentOrganisation?.options?.tmaLink) && !isSharedLink,
        },
        {
            title: lang.documentOverview,
            image: require('../../assets/images/Styr_kennisbank v2.png'),
            navigateToPage: Routes.DocumentsRoute,
            show: !isSharedLink && Boolean(permissions?.canViewDocuments),
        },
    ];


    return (
        <BlocksGrid>
            {blocks.map(item => {
                if (!item.show) return null;
                return (
                    <DashboardBlocks
                        key={item.title}
                        title={item.title}
                        image={item.image}
                        navigateToSite={item.navigateToSite}
                        navigateToPage={item.navigateToPage}
                    />
                );
            })}
        </BlocksGrid>
    );
};

export default DashboardBlocksSection;
