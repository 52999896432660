import React, { createContext, useContext, useState, ReactNode } from 'react';
import Loading from 'containers/Loading/Loading';

import { OrganisationContextProps } from './Organisation.types';
import {
    setCurrentOrganisation,
    updateOrganisationRequest,
    updateOrganisationsRequest,
} from 'store/user/user.actions';
import { useDispatch } from 'react-redux';

export const OrganisationContext = createContext<
    OrganisationContextProps | undefined
>(undefined);

export const OrganisationProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleSwitchOrganisation = async (id: string) => {
        try {
            setLoading(true);
            await dispatch(setCurrentOrganisation(id));
            await dispatch(updateOrganisationsRequest());
        } catch (error) {
            console.error('Failed to switch organisation:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateOrganisation = async () => {
        await dispatch(updateOrganisationRequest());
    };

    const contextValue: OrganisationContextProps = {
        handleSwitchOrganisation,
        updateOrganisation,
    };

    return (
        <OrganisationContext.Provider value={contextValue}>
            {loading ? <Loading /> : null}
            {children}
        </OrganisationContext.Provider>
    );
};

export const useOrganisation = (): OrganisationContextProps => {
    const context = useContext(OrganisationContext);
    if (!context) {
        throw new Error(
            'useOrganisation must be used within an OrganisationProvider'
        );
    }
    return context;
};
