import { matchPath } from 'react-router-dom';
import MainLayoutProps, { LayoutType } from './MainLayout.types';
import React, { FC, ReactNode } from 'react';
import Header from 'containers/Header/Header.connector';
import Sidebar from 'containers/Sidebar/Sidebar.connector';
import { Wrapper, Main, Pages } from './MainLayout.components';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Routes from 'routes/Routes.types';

/**
 * Get the layout type for a given path using layoutRecord.
 * @param path - The current pathname.
 * @param layoutRecord - The record mapping routes to layout types.
 * @returns The layout type for the matched route.
 */
export const getLayoutForPath = (path: string, layoutRecord: Partial<Record<Routes, LayoutType>>): LayoutType => {
    for (const [route, layout] of Object.entries(layoutRecord)) {
        if (matchPath(path, { path: route, exact: false })) {
            return layout!;
        }
    }
    return 'default';
};

export const getLayoutWrapper: Record<
    LayoutType,
    FC<{ children: ReactNode } & MainLayoutProps>
> = {
    default: ({ children }) => (
        <Wrapper>
            <Sidebar />
            <Main>
                <Header />
                <Pages>{children}</Pages>
            </Main>
        </Wrapper>
    ),
    'no-sidebar': MainLayoutNoSidebar,
    admin: AdminLayout,
};
