import { connect } from 'react-redux';
import {
    resultsChangeResult, resultsChangeResultValidatedProfile, resultsDeleteResult, resultsGetResult
} from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import ResultSection from './ResultSection';
import { updateOrganisationRequest } from 'store/user/user.actions';

const mapStateToProps = (state: ReduxStore) => ({
    results: state.results.results,
    currentLanguage: state.language.currentLanguage,
    canDeleteResult: state.user.permissions.canDeleteResult,
    permissions: state.user.permissions,
    isShareLink: state.user.isShareLink,
    share_link_settings: state.user.share_link_settings,
    users: state.user.currentOrganisation?.users,
    currentOrganisation: state.user.currentOrganisation
});
const mapDispatchToProps = { updateOrganisationRequest, resultsChangeResultValidatedProfile, resultsGetResult, resultsDeleteResult, resultsChangeResult };
const ConnectedResultSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultSection);

export default ConnectedResultSection;
