import styled from 'styled-components';

export const H1 = styled.h1<{color?: string;}>`
    margin: 0.5rem 0;
    color: ${props => props.color ? props.color : '#333333'};
    font-family: Avenir;
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
`;

export const H2 = styled.h2<{color?: string;}>`
    margin: 0.5rem 0;
    color: ${props => props.color ? props.color : '#333333'};
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
`;

export const H3 = styled.h3<{color?: string;}>`
    margin: 0.5rem 0;
    color: ${props => props.color ? props.color : '#333333'};
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    line-height: 32px;
`;

export const UnderlinedTitle = styled.h2<{color?: string;}>`
    margin: 0.5rem 0;
    color: ${props => props.color ? props.color : '#333333'};
    display: inline-block;
    font-size: 30px;
    font-weight: 900;
    line-height: 32px;
    padding-bottom: 10px;
    position: relative;
    ::before {
        border-bottom: 3px solid #f06e64;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0%;
        position: absolute;
        width: 180px;
    }
`;

export const UnderlinedSubTitle = styled.h2`
    font-size: 22px;
    margin: 0.5rem 0;
    color: #333333;
    display: inline-block;
    font-weight: 900;
    line-height: 32px;
    padding-bottom: 10px;
    position: relative;
    ::before {
        border-bottom: 3px solid #f06e64;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0%;
        position: absolute;
        width: 180px;
    }
`;

export const TextField = styled.input<{fullWidth?: boolean}>`
    width: 100%;
    max-width: ${props => props.fullWidth ? "unset" : "300px"};
    padding: 4px 8px;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.4;
    border: 2px solid #3b4b63;
    border-radius: 5px;
    color: #333333;
    display: block;
    font-weight: 500;
    min-height: 40px;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`;

export const TextBoldContainer = styled.div`
    font-family: 'Avenir';
    font-size: 30px;
    font-weight: 900;
    line-height: 32px;
`;

export const TextWarning = styled.p`
    font-family: 'Avenir';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
`;

export const TextSmall = styled(TextBoldContainer)`
    font-size: 13px;
    font-weight: 400;
    line-height: initial;
`;
