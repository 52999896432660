import { IconButton } from 'components/Button/IconButton/IconButton';
import ArrowDownIcon from 'components/Icons/arrowDown';
import { ArrowUpIcon } from 'components/Icons/arrowUp';
import { LangObject } from 'helpers/language';
import { useNavigate } from 'helpers/useNavigation';
import React from 'react';
import { DocumentsBlockProps } from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.types';
import styled from 'styled-components';

export const DocumentsBlocksContainer = styled.article`
    width: 250px;
    min-width: 200px;
    max-width: 250px;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(167 178 195 / 32%);
    overflow: hidden;
    flex: 0 0 20%;
    margin: 20px;

    cursor: pointer;

    &:nth-child(even) {background: #afc8e1}
    &:nth-child(odd) {background: #afc8e1}

    &:hover{
        &:nth-child(even) {background: #bfd3e7}
        &:nth-child(odd) {background: #bfd3e7}
    }
`

const DocumentsBlocksHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 120px;
`

const Figure = styled.figure`
    margin: 0;
    padding: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
`;

const DocumentsBlocksBody = styled.div`
    padding: 1.25em 1em;
    background-color: #fff;
    font-weight: bold;
    font-size: 0.9em;
    min-height: 35px;
`

const DocumentsBlocksImg = styled.img<{ enlarge?: boolean; }>`
    width: auto;
    height: 100px;
`;

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`

export const DocumentsBlocks: React.FC<DocumentsBlockProps> = ({ title, image, navigateToSite, navigateToPage }) => {
    const { navigate } = useNavigate();

    const goto = () => {
        if (navigateToSite) {
            window.open(navigateToSite, '_blank');
            return;
        } else if (navigateToPage) {
            navigate(navigateToPage);
        }
    }

    return (
        <DocumentsBlocksContainer onClick={goto}>
            <DocumentsBlocksHeader>
                <Figure>
                    <DocumentsBlocksImg
                        src={image}
                    />
                </Figure>
            </DocumentsBlocksHeader>
            <DocumentsBlocksBody>
                {title}
            </DocumentsBlocksBody>
        </DocumentsBlocksContainer >
    )
}

const Container = styled.div`
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
    background-color: white;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
`;


export const BlocksGrid: React.FC<{ lang: LangObject, children: React.ReactNode, header?: string, closeable?: boolean }> = ({ lang, children, header, closeable }) => {
    if ((children as React.ReactNode[])?.length == 0) return null;

    const [openState, setOpenState] = React.useState<boolean>(true);
    return (<BlockContainer>
        <Row>
            {header && header.length > 0 &&<h3>{header}</h3>}
            {closeable === true && (children as React.ReactNode[])?.length > 0 &&(
                <ToggleButton>
                    {openState === true ? (
                        <IconButton IconSVG={ArrowUpIcon} onClick={() => setOpenState(!openState)} color={'primary'} />
                    ) : (
                        <IconButton IconSVG={ArrowDownIcon} onClick={() => setOpenState(!openState)} color={'primary'} />
                    )}
                </ToggleButton>
            )}
        </Row>
        <Container>
            {openState && (children as React.ReactNode[])?.length > 0 && children}
            {openState && (children as React.ReactNode[])?.length === 0 && (
                <div>
                    {lang.noDocumentAvailable}
                </div>
            )}
        </Container>
    </BlockContainer>)
}

export const BlockContainer = styled.div`
    max-height: 100%;
    max-width: 100%;
    background-color: white;
    padding-left: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
`;
export const IconContainer = styled.div`
    overflow: visible;
    vertical-align: -0.225em;
    font-size: 1.25em;
    line-height: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;

    width: 24px;
    position: relative;
`;

export const Row = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
`;

export const ToggleButton = styled.div`
    padding-left: 10px;
`;
