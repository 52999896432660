import { SetStateAction } from 'react'
import { UpdateJobFamilyByField } from "store/families/jobfamily.actions"
import { ResultsGetResults, ResultsUpdateResultByField, ResultsUpdateResultExtraCompetence } from "store/results/results.actions"
import { LevelsOptionsArray, Permissions } from "store/user/user.types"
import { Dispatch } from 'react'
import { Language } from "types/MultiLingualString"
import { Organisation } from "types/Organisation"
import { Family, ValidatedProfile, Result } from "types/Result"

export interface JobFamilyMatrixStylingProps {
    isHorizontalSticky?: boolean;
    isVerticalSticky?: boolean;
    stickyVerticalOffset?: number;
    stickyHorizontalOffset?: number;
    isPrinting?: boolean;
    isEditMode?: boolean;
    isSticky?: boolean;
}

export interface JobFamilyMatrixProps {
    isShareLink?: boolean
    id: string
    currentLanguage: Language
    currentOrganisation?: Organisation
    results: Result[]
    resultsGetResults: ResultsGetResults
    updateOrganisationRequest: () => void;
    getRemoteLanguages: () => void;
    permissions: Permissions;
}

export interface JobColumnProps {
    uuid: number | string;
    results: Result[]
    currentOrganisation?: Organisation
    currentLanguage: Language;
    editMode: boolean
}

export interface RowProps {
    alternativeTitle?: string;
    family?: Family
    filter?: FilterFields
    results: Result[]
    validatedProfiles?: ValidatedProfile[]
    currentLanguage: Language
    currentOrganisation?: Organisation
    levelOptions?: LevelsOptionsArray
    isPrinting?: boolean
    styrLevelSub?: { [key: string]: string }
    organisationLevelsAvailable?: boolean;
    styling: JobFamilyMatrixStylingProps;
    verticalStickySettings?: VerticalStickySetting[];
    updateVerticalSetting?: (updatedVerticalStickySetting: VerticalStickySetting) => void;
}

export interface FilterFields {
    jobCompetences: boolean
    jobFamily_Field_1: boolean
    jobFamily_Field_2: boolean
    jobFamily_Field_3: boolean
    jobFamily_Field_4: boolean
    jobFamily_Field_5: boolean
    jobFamily_Field_6: boolean
}

export interface BoxEditableProps {
    key?: string;
    multilang?: boolean;
    wysiwyg?: boolean;
    value: string;
    placeholder?: string;
    whenEmpty?: string;
    bold?: boolean;
    saved?: boolean;
    changeHandler: (e: string, key?: string) => void;
    language?: Language;
    styling: JobFamilyMatrixStylingProps;
}

export interface MatrixBoxEditableProps {
    language?: Language
    wysiwyg?: boolean;
    id: number | string;
    text: string
    field: string
    placeholder?: string
    bold?: boolean
    title?: boolean
    whenEmpty?: string
    type?: EditableBoxType
    put?: PutType
    currentLanguage: Language,
    saved?: boolean,
    styling: JobFamilyMatrixStylingProps,
    resultsUpdateResultByField: ResultsUpdateResultByField
    updateJobFamilyByField: UpdateJobFamilyByField
    resultsUpdateResultExtraCompetence: ResultsUpdateResultExtraCompetence
}

export interface MatrixBoxDoubleEditableProps {
    id: number
    text: string
    title: string
    editMode: boolean
    resultsUpdateResultExtraCompetence: ResultsUpdateResultExtraCompetence
}

export interface VerticalStickySetting {
    enabled: boolean;
    row: number;
    offset: number;
    height: number;
}

export enum DownloadTypes {
    PDF = "pdf",
    Image = "png"
}

export type EditableBoxType = "description" | "cell"
export type PutType = "family" | "result" | "jobCompetences"
