
import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateSection from 'sections/UpdateSection/UpdateSection.connector';
import { UpdateContainer } from './Update.components';
import UpdateProps from './Update.types';

const Update: React.FC<UpdateProps> = () => {
    const { id } = useParams<{ id: string }>();
    return (
        <UpdateContainer>
            <UpdateSection id={id} />
        </UpdateContainer>
    );
};

export default Update;
