import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { invitePartner } from 'store/user/user.actions';
import DashboardBlocksSection from './DashboardBlocksSection';

const mapStateToProps = (state: ReduxStore) => ({
    currentOrganisation: state.user.currentOrganisation,
    currentLanguage: state.language.currentLanguage,
    isSharedLink: state.user.isShareLink,
    permissions: state.user.permissions,
    share_link_settings: state.user.share_link_settings,
});

const mapDispatchToProps = { invitePartner };
const ConnectedDashboardBlocksSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardBlocksSection);

export default ConnectedDashboardBlocksSection;
