
import React from 'react';
import { useParams } from 'react-router-dom';
import { ConnectedJobFamilyMatrix } from 'sections/JobFamilyMatrix/JobFamilyMatrix';

const JobFamilyMatrixScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return <ConnectedJobFamilyMatrix id={id} />;
};

export default JobFamilyMatrixScreen;
