import LoginFormSL from 'containers/LoginForm/LoginFormSL.connector';
import React from 'react';
import LoginSection from 'sections/LoginSection/LoginSection.connector';
import LoginSharedLinkProps from './Login.types';

import { useParams } from 'react-router-dom';
import { LoginContainer } from './Login.components';

const Login: React.FC<LoginSharedLinkProps> = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <LoginContainer>
            <LoginSection>
                <LoginFormSL token={id} />
            </LoginSection>
        </LoginContainer>
    );
};

export default Login;
