import React, { FC, useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { useNavigate } from 'helpers/useNavigation';
import getLanguageObject from 'helpers/language';
import Routes from 'routes/Routes.types';
import { Family } from 'types/Result';
import { updateJobFamilySortOrder } from 'store/families/jobfamily.actions';
import ReduxStore from 'store/store.type';

import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { FullArrowDownIcon } from 'components/Icons/arrowDown';
import { FullArrowUpIcon } from 'components/Icons/arrowUp';
import { HeaderCheckBox, State } from 'components/TableElements/TableElements';

import {
    TableBody,
    TableItemContainer,
    SortButtonContainer,
    Row,
} from '../ListOfJobFamilies.components';
import { JobFamilyTableBodyProps } from '../types';

export const JobFamilyTableBody: FC<JobFamilyTableBodyProps> = ({
    jobFamilies,
    filters,
    isShareLink,
    permissions,
    updateJobFamilySortOrder,
    currentLanguage,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const { navigate } = useNavigate();
    const {
        changeSorting,
        state,
        statusFilter,
        selectedItems,
        handleSelectedItems,
    } = filters;

    const filteredFamilies = useMemo(() => {
        return jobFamilies
            .filter(x => {
                if (!statusFilter) return true;
                return x.status?.toLowerCase() === statusFilter;
            })
            .filter(x => {
                if (!isShareLink) return true;
                return x.status?.toLowerCase() === 'active';
            });
    }, [jobFamilies, statusFilter, isShareLink]);

    const [sortedFamilies, setSortedFamilies] = useState<Family[]>(() =>
        [...filteredFamilies].sort((a, b) => {
            const aValue = a[state.sorting as keyof Family];
            const bValue = b[state.sorting as keyof Family];
            if (state.sortingOrder) {
                return (aValue ?? '') < (bValue ?? '') ? 1 : -1;
            }
            return (aValue ?? '') > (bValue ?? '') ? 1 : -1;
        })
    );

    useEffect(() => {
        setSortedFamilies(
            [...filteredFamilies].sort((a, b) => {
                const aValue = a[state.sorting as keyof Family];
                const bValue = b[state.sorting as keyof Family];
                if (state.sortingOrder) {
                    return (aValue ?? '') < (bValue ?? '') ? 1 : -1;
                }
                return (aValue ?? '') > (bValue ?? '') ? 1 : -1;
            })
        );
    }, [filteredFamilies, state.sorting, state.sortingOrder]);

    const debouncedUpdate = React.useCallback(
        debounce(updateJobFamilySortOrder, 1000),
        [updateJobFamilySortOrder]
    );

    const countItems = (value: any[] | null): number => {
        if (!value) return 0;
        return value.length;
    };

    const handleMove = (index: number, direction: 'up' | 'down') => {
        let localSortedFamilies = [...sortedFamilies];

        if (index === 0 && direction === 'up') return;
        if (index === localSortedFamilies.length - 1 && direction === 'down')
            return;

        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if (!localSortedFamilies[index] || !localSortedFamilies[newIndex])
            return;

        const tempSortOrder = localSortedFamilies[index].sortOrder;
        localSortedFamilies[index].sortOrder =
            localSortedFamilies[newIndex].sortOrder;
        localSortedFamilies[newIndex].sortOrder = tempSortOrder;

        localSortedFamilies.sort((a, b) => a.sortOrder - b.sortOrder);

        debouncedUpdate(localSortedFamilies);
        setSortedFamilies(localSortedFamilies);

        if (state.sorting !== 'sortOrder') changeSorting('sortOrder');
    };

    return (
        <TableBody>
            {sortedFamilies.map((family: Family, index: number) => {
                const allowToView = countItems(family.jobs) === 0;
                const checkStatus =
                    family.status === null ? 'Concept' : family.status;

                const renderSortButtons = () => {
                    if (index === 0) {
                        return (
                            <IconButton
                                color="success"
                                IconSVG={FullArrowDownIcon}
                                onClick={() => handleMove(index, 'down')}
                            />
                        );
                    }

                    if (index === sortedFamilies.length - 1) {
                        return (
                            <IconButton
                                color="success"
                                IconSVG={FullArrowUpIcon}
                                onClick={() => handleMove(index, 'up')}
                            />
                        );
                    }

                    return (
                        <>
                            <IconButton
                                color="success"
                                IconSVG={FullArrowUpIcon}
                                onClick={() => handleMove(index, 'up')}
                            />
                            <IconButton
                                color="success"
                                IconSVG={FullArrowDownIcon}
                                onClick={() => handleMove(index, 'down')}
                            />
                        </>
                    );
                };

                return (
                    <TableItemContainer key={family.id}>
                        {permissions.canManageOrganisation ? (
                            <HeaderCheckBox
                                activeItem={selectedItems.includes(family.id)}
                                onClick={() => handleSelectedItems(family.id)}
                            />
                        ) : (
                            <div />
                        )}
                        <SortButtonContainer>
                            <div>{renderSortButtons()}</div>
                        </SortButtonContainer>

                        <div>
                            {family.name
                                .replace(/<[^>]*>?/gm, '')
                                .replace('&amp;', '&')}
                        </div>
                        <div>{countItems(family.jobs)}</div>
                        <div>
                            {moment
                                .utc(family.lastModified)
                                .local()
                                .format('DD MMM YYYY HH:mm')}
                        </div>
                        <State status={checkStatus} />
                        <Row>
                            {(permissions.canManageOrganisation ||
                                family.status?.toLowerCase() === 'concept') && (
                                <Button
                                    text={lang.manage}
                                    priority="primary"
                                    onClick={() =>
                                        navigate(Routes.JobFamilyEdit + family.id)
                                    }
                                    size="small"
                                />
                            )}
                            <Button
                                text={lang.view}
                                priority="tertiary"
                                onClick={() =>
                                    navigate(Routes.JobFamilyView + family.id)
                                }
                                disabled={allowToView}
                                size="small"
                            />
                        </Row>
                    </TableItemContainer>
                );
            })}
        </TableBody>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
    isShareLink: state.user.isShareLink,
});

const mapDispatchToProps = {
    updateJobFamilySortOrder,
};

export const ConnectedJobFamilyTableBody = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobFamilyTableBody);
