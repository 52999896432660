import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Button from 'components/Button/Button';
import Loading from 'containers/Loading/Loading';
import InputField, { MultiSelectField, SelectField } from 'containers/InputField/InputField';
import getLanguageObject from 'helpers/language';
import React, { useState } from 'react';
import { useEffect } from 'react';
import type { Option } from 'react-multi-select-component';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import { StyledHeader } from 'sections/Admin/AdminAllocatorQuestionsList/AdminAllocatorQuestionsList.components';
import { AdminUserManagementSingleContainer, Column, SingleAdminUserManagementContainer, SingleAdminUserManagementInnerContainer, SingleAdminUserManagementInnerContainerItem, SingleAdminUserOrganisationContainer, SingleAdminUserOrganisationRow } from 'sections/Admin/AdminUserManagementSingle/AdminUserManagementSingle.components';
import { LoadSelectedUser, LoadAllOrganisations, ChangeRole, DeleteUser, InviteUser, UpdateUser, DeleteUserFromApplication } from 'store/user/user.actions';
import { Permissions } from 'store/user/user.types';
import { Language } from 'types/MultiLingualString';
import { DifferentiatingFactors, Organisation, OrganisationUser } from 'types/Organisation';
import { Role } from 'types/Role';

interface AdminUserManagementSingleProps {
    id: string;
    allOrganisations: Organisation[];
    user?: OrganisationUser;
    currentLanguage: Language;
    permissions: Permissions;
    loadSelectedUser: LoadSelectedUser;
    loadAllOrganisations: LoadAllOrganisations;
    deleteUser: DeleteUser;
    deleteUserFromApplication: DeleteUserFromApplication;
    inviteUser: InviteUser;
    updateUser: UpdateUser;
}

export const AdminUserManagementSingle: React.FC<AdminUserManagementSingleProps> = ({
    id,
    allOrganisations,
    user,
    currentLanguage,
    permissions,
    loadSelectedUser,
    deleteUser,
    deleteUserFromApplication,
    inviteUser,
    updateUser
}) => {
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();

    const [currentUser, setUser] = useState<OrganisationUser>();
    const [isloading, setIsLoading] = useState(true);
    const [deletePopup, setDeletePopup] = useState(false)

    const [addedOrganisations, setAddedOrganisations] = useState<Organisation[]>([]);
    const [removedOrganisations, setRemovedOrganisations] = useState<Organisation[]>([]);

    useEffect(() => {
        loadSelectedUser(id);
    }, [id]);

    useEffect(() => {
        if (!user) {
            setIsLoading(false);
            navigate();
            return;
        }

        setUser(user);
        setIsLoading(false);
    }, [user, user?.organisations.length]);

    const navigate = () => {
        history.push(Routes.AdminUserManagementDetailRoute);
    }

    const RoleSelect: React.FC<{ user: OrganisationUser, changeRole: ChangeRole, permissions: Permissions }> = ({ user, changeRole, permissions }) => {
        if (!permissions.canDuplicateOrganisation) {
            return <div>{user.roleName}</div>
        }

        if (user.roleName === 'Administrator') {
            if (
                !permissions.canInvitePartnerExpert
            ) {
                return null;
            }
            return <div>{user.roleName}</div>
        }

        return (
            <SelectField
                onChange={e => {
                    const newRole = e.target.value
                    changeRole(user.id, newRole)
                }}
                options={
                    <>
                        {permissions.canInviteUser && <option value="ClientUser">Client User</option>}
                        {permissions.canInviteExpert || permissions.canInvitePartnerUser && <option value="ClientExpert">Client Expert</option>}
                        {permissions.canInviteExpert || permissions.canInvitePartnerUser && <option value="ClientAdministrator">Client Administrator</option>}
                        {permissions.canInvitePartnerUser && <option value="PartnerUser">Partner User</option>}
                        {permissions.canInvitePartnerExpert && <option value="PartnerExpert">Partner Expert</option>}
                        {permissions.canInvitePartnerExpert && <option value="PartnerAdministrator">Partner Administrator</option>}
                        {permissions.canInviteAdministrator && <option value="Administrator">Administrator</option>}
                    </>
                }
                value={user.roleName.replace(' ', '')}
            />
        )
    }


    if (!user) {
        return null;
    }

    if (!currentUser) {
        return null
    }

    if (isloading) { return (<Loading />) }

    const saveUser = () => {
        setIsLoading(true);
        const roleName = currentUser.roleName !== "" ? currentUser.roleName as Role : "ClientUser";
        addedOrganisations.forEach((org, index) => {
            let sendMail = false;
            //the last organisation in the list should send the mail
            if (index === addedOrganisations.length - 1) {
                sendMail = true;
            }
            inviteUser(currentUser.emailAdress, `${currentUser.firstName} ${currentUser.lastName}`, roleName, org.id, currentUser.id, sendMail);
        });

        removedOrganisations.forEach(org => {
            deleteUser(currentUser.id, org.id);
        });

        updateUser(currentUser);

        //reset the lists
        setAddedOrganisations([]);
        setRemovedOrganisations([]);
    }

    const handleDeleteUserFromApplication = (userId: string) => {
        setIsLoading(true);
        deleteUserFromApplication(userId);
    }

    return (
        <AdminUserManagementSingleContainer>
            <StyledHeader style={{ backgroundColor: "#eff4f9" }}>
                <BreadCrumbs title={`${lang.manageUsers}`} paths={[
                    {
                        title: lang.manageUsers,
                        path: Routes.AdminUserManagementRoute
                    },
                    {
                        title: currentUser?.firstName + ' ' + currentUser?.lastName,
                        path: Routes.AdminUserManagementDetailRoute
                    }
                ]} />

                <AreYouSurePopup
                    onClick={() => handleDeleteUserFromApplication(currentUser.id)}
                    action={lang.delete}
                    item=''
                    title={lang.deleteWarningUser}
                    InnerContent={() => <Button priority='tertiary' text={lang.delete} onClick={() => {
                        setDeletePopup(!deletePopup)
                    }} />}
                />

            </StyledHeader>
            <SingleAdminUserManagementContainer>
                <SingleAdminUserManagementInnerContainer>
                    <SingleAdminUserManagementInnerContainerItem>
                        <h3>{lang.firstName}</h3>
                        <InputField errorMessage={null} type="text" value={currentUser.firstName} onChange={(e) => {
                            setUser({
                                ...currentUser,
                                firstName: e.target.value
                            })
                        }} />
                        <h3>{lang.lastName}</h3>
                        <InputField errorMessage={null} type="text" value={currentUser.lastName} onChange={(e) => {
                            setUser({
                                ...currentUser,
                                lastName: e.target.value
                            })
                        }} />
                        <h3>{lang.usernameOrEmail}</h3>
                        <InputField errorMessage={null} type="text" value={currentUser.emailAdress} onChange={(e) => {
                            setUser({
                                ...currentUser,
                                emailAdress: e.target.value
                            })
                        }} />
                        <h3>{lang.userRole}</h3>
                        <RoleSelect user={currentUser} changeRole={
                            (id, role) => {
                                if (role === 'PartnerAdministrator') {
                                    role = 'PartnerAdmin';
                                }
                                setUser({
                                    ...currentUser,
                                    roleName: role,
                                    roleId: id
                                })
                            }
                        } permissions={permissions} />
                    </SingleAdminUserManagementInnerContainerItem>
                    {currentUser.roleName !== 'Administrator' && <SingleAdminUserManagementInnerContainerItem>
                        <h3>{lang.organisations}</h3>
                        <MultiSelectField width={300} searchable={true} currentLanguage={currentLanguage} value={currentUser.organisations.map(x => new Option(x.name, x.id))} options={allOrganisations.map(x => new Option(x.name, x.id))} onChange={
                            (e: Option[]) => {
                                const updatedOrganisationList = e.map((x) => ({
                                    name: x.label,
                                    id: x.value,
                                    imageUrl: '',
                                    shareLinks: [],
                                    organisationUnits: [],
                                    jobFamilies: [],
                                    users: [],
                                    differentiatingFactors: {} as DifferentiatingFactors,
                                    alternativeLevels: {} as any,
                                    alternativeTitle: '',
                                    enabledStyrLevelSubs: false,
                                    options: {} as any
                                }));

                                //find the removed organisations and set them to a separate list
                                const removed = currentUser.organisations.filter(
                                    org => !updatedOrganisationList.some(updatedOrg => updatedOrg.id === org.id)
                                );

                                setRemovedOrganisations(removed);

                                //find the added organisations and set them to a separate list
                                const added = updatedOrganisationList.filter(
                                    updatedOrg => !currentUser.organisations.some(org => org.id === updatedOrg.id)
                                );

                                setAddedOrganisations(added);

                                currentUser.organisations = updatedOrganisationList;
                            }
                        }></MultiSelectField>
                        <SingleAdminUserOrganisationContainer>
                            {currentUser.organisations
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((org, index) => {
                                    return (
                                        <SingleAdminUserOrganisationRow key={org.id}>
                                            <Column>{org.name}</Column>
                                        </SingleAdminUserOrganisationRow>
                                    )
                                })
                            }
                        </SingleAdminUserOrganisationContainer>

                    </SingleAdminUserManagementInnerContainerItem>}
                </SingleAdminUserManagementInnerContainer>
                <Button text={lang.save} onClick={() => {
                    saveUser();
                }} />
            </SingleAdminUserManagementContainer>
        </AdminUserManagementSingleContainer>
    );
}
