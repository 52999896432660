
import { Language } from 'types/MultiLingualString';
import { be_fr } from './language/be_fr';
import { be_nl } from './language/be_nl';
import { de } from './language/de';
import { en } from './language/en';
import { fr } from './language/fr';
import { nl } from './language/nl';
import { pl } from './language/pl';
import { es } from './language/es';

//TODO: migrate this to backend.
export const languages: Language[] = ['en-EN', 'nl-NL', 'de-DE', 'nl-BE', 'fr-BE', 'fr-FR', 'pl-PL', 'es-ES'];

const getLanguageObject = (language: Language) => {
    if (language) {
        if (language === 'nl-NL') return nl;
        if (language === 'nl-BE') return be_nl;
        if (language === 'fr-BE') return be_fr;
        if (language === 'en-EN') return en;
        if (language === 'de-DE') return de;
        if (language === 'fr-FR') return fr;
        if (language === 'pl-PL') return pl;
        if (language === 'es-ES') return es;
    }

    return en;
};

export interface LangObject {
    validationToLong: string;
    validationValidValue: string;
    id: string;
    styrAllocator: string;
    forgotPassword: string;
    add: string;
    edit: string;
    unitEmpty: string;
    unitAlreadyExists: string;
    noUnits: string;
    editJob: string;
    editFilterOptions: string;
    styrProfile: string;
    viewValidatedProfile: string;
    viewJobFamily: string;
    viewJobMatrix: string;
    talentMatrix: string;
    theTalentMatrix: string;
    jobMatrix: string;
    theJobMatrix: string;
    jobTalentMatrix: string;
    jobMatrixArchitectureName: string;
    nameAlreadyExists: string;
    pleaseEnterUnit: string;
    educationLevelValidatedProfile: string;
    styrGroups: string;
    uploadImage: string;
    chooseImage: string;
    styrLevel: string;
    filterTags: string;
    freeInputfieldColumn: string,
    freeInputfield: string;
    freeInputfield2: string;
    freeInputfield3: string;
    salaryScale: string;
    jobMatrixArchitecture: string;
    naturalCareerPath: string;
    styrGroup: string;
    problemSolvingCapability: string;
    expertiseLevel: string;
    chooseUserRole: string;
    userRole: string;
    planningHorizon: string;
    continuousImprovement: string;
    communicationSkills: string;
    managementSkill: string;
    management: string;
    educationLevel: string;
    education_level: string;
    levelOfExpertise: string
    planning_horizon: string
    continuous_improvement: string
    autonomy: string
    communication_skills: string
    coordinationSkills: string
    projectManagementSkills: string
    styr_competencies: string
    extra_competencies: string
    question: string;
    unit: string;
    organisationUnitHasNoUnits: (organisationUnitName: string) => string;
    jobAlreadyExist: string;
    noNameEntered: string;
    unitShort: string;
    addNewUnit: string;
    deleteWarningOrganisationUnit: string;
    deleteWarningUnit: string;
    deleteWarningUser: string;
    organisationUnit: string;
    addNewOrganisationUnit: string;
    newName: string;
    name: string;
    firstName: string;
    lastName: string;
    editPlural: string;
    manage: string;
    unitMultiple: string;
    inviteNewUser: string;
    inviteExistingUser: string;
    userInvited: string;
    partnerInvited: string;
    inviteNewPartner: string;
    next: string;
    back: string;
    user: string;
    users: string;
    manageUsers: string;
    close: string;
    title: string;
    description: string;
    noResults: string;
    comment: string;
    placeholder: (item: string) => string;
    placeholderError: (item: string) => string;
    questionsServiceDeskHome: string;
    attachment: string;
    post: string;
    submit: string;
    updates: string;
    recentAllocated: string;
    date: string;
    role: string;
    functionOrRole: string;
    jobRole: string;
    jobNRole: string;
    retry: string;
    level: string;
    organisationName: string;
    createDetails: string;
    createdOn: string;
    createOrganisation: string;
    createUser: string;
    createNewOrganisation: string;
    note: string;
    save: string;
    saving: string;
    saveResults: string;
    delete: string;
    deletePlural: string;
    confirmation: (action: string, item: string) => string;
    result: string;
    cancel: string;
    freeInputLevel: string;
    columns: string;
    compare: string;
    choose: string;
    change: string;
    manageOrganisation: string;
    manageValuation: string;
    manageValuationDescription: string;
    pleaseSelectAtleastTwo: string;
    styrDifferencingFactors: string;
    showTalentpath: string;
    changeStyrLevel: string;
    selectLevelAfterTalentpathChange: string;
    backToValidatedProfile: string;
    talentpath: string;
    backToPreview: string;
    view: string;
    changeLevel: string;
    chooseStyrLevels: (currentCount: number) => string;
    selectStyrLevelsToCompare: (currentCount: number) => string;
    currentValidatedProfileLevelIs: string;
    youreAboutToChange: string;
    changing: string;
    theStyrLevel: string;
    viewInformation: string;
    allocatorWarningOrganisation: string;
    maxLogoWidthDescription: string;
    maxLogoWidthWarning: string;
    supportedFileTypes: string;

    goToManagement: string;

    dashboard: string;
    organisation: string;
    startAllocator: string;
    searchJobsnRoles: string;
    talentManagementTool: string;
    elearningStyrModel: string;
    styrUpdates: string;
    logout: string;

    talentpathAdministrative: string;
    talentpathAdvisory: string;
    talentpathCommercial: string;
    talentpathExpert: string;
    talentpathManagement: string;
    talentpathProjectManagement: string;
    talentpathSupportCoordination: string;
    talentpathProcessManagement: string;
    talentpathOperationalTechnical: string;
    manageJobFamily: string;
    manageJobFamilyNotEnabled: string;
    search: string;
    jobFamilyAlreadyExist: string;
    pleaseEnterFamilyName: string;
    addJobFamily: string;
    goToSettings: string;
    addNewJob: string;
    newJobFamily: string;
    nameJobFamily: string;
    jobFamily: string;
    status: string
    changeStatusBulk: string
    lastModified: string;
    differentiatingFactorsTitle: string;
    changeDifferentiatingFactorsTitle: string;
    differentiatingFactorsDescription: string;

    competencies: string;
    defaultStyrCompetencies: string;
    extraCompetencies: string;

    mainResponsibilities: string;
    row: string;
    enterTitle: string;
    enterDescription: string;
    addExtraCompetence: string;
    manageRows: string;
    createNewJob: string;
    selectOrganisationUnit: string;
    selectUnit: string;
    selectTalentPath: string;
    selectLevel: string;
    pleaseFillInAllField: string;

    accountability: string;
    attention_to_detail: string;
    business_orientation: string;
    commercial_power: string;
    conduct: string;
    controlling_progress: string;
    cooperation: string;
    creativity: string;
    customer_orientation: string;
    decisiveness: string;
    delegating: string;
    developing_employees: string;
    discipline_competence: string;
    flexible_behavior: string;
    focus_on_quality: string;
    forming_judgment: string;
    identification_with_management: string;
    independence: string;
    innovative_power: string;
    insight: string;
    leadership_of_groups: string;
    managing: string;
    need_to_achieve: string;
    networking: string;
    perseverance: string;
    persuasiveness: string;
    planning_and_organizing: string;
    result_orientedness: string;
    sociability: string;
    social_awareness: string;
    verbal_expression: string;
    vision: string;
    workmanship: string;
    written_expression: string;
    courage: string;
    integrity: string;

    backToJobFamilies: string;
    showAll: string;

    jobfamilySearchPlaceholder: string
    filterResults: string

    password: string
    accessCode: string
    accessCodeDescription: string
    accessCodeError: string
    usernameOrEmail: string
    usernameEmpty: string
    passwordEmpty: string
    microsoftNotWorking: string
    switchOrganisation: string
    viewOtherOrganisations: string

    login: string
    forgotPasswordTitle: string
    backToLogin: string
    requestNewPassword: string
    tryAgain: string
    newPasswordSend: string
    emailFieldEmpty: string
    resetPasswordTitle: string
    resetPasswordSuccess: string
    newPassword: string
    duplicateOrganisation: string
    deleteOrganisation: string

    // SHARELINKS
    manageShareLinkTitle: string
    manageShareLinkDescription: string

    createNewLink: string
    link: string
    dateAdded: string
    questionDeleteShareLink: string
    createNewShareLinkTitle: string
    createNewShareLinkDescription: string
    allOrganisationUnitsTitle: string
    createdBy: string
    updatedBy: string;

    headerSearchbarNoResults: string
    headerSearchbarPlaceholder: string

    createNewProfile: string;
    pleaseSelectAnAnswer: string;
    pleaseSelectALevel: string;
    showStyrProfile: string;
    showTags: string;

    createUpdate: string;
    allocatorQuestions: string;
    allocatorQuestion: string;

    errorTitle: string;
    errorFailedFetchText: string;
    errorEndText: string;

    tagsHeader: string;
    tagsUntagged: string;
    tagsFutureState: string;
    tagsCurrentState: string;

    multiselectAllItemsAreSelected: string;
    multiselectClearSearch: string;
    multiselectClearSelected: string;
    multiselectNoOptions: string;
    multiselectSearch: string;
    multiselectSelectAll: string;
    multiselectSelectAllFiltered: string;
    multiselectSelectSomeItems: string;
    multiselectCreate: string;
    mulitselectSelect: string;

    //Allocator Error Messages
    loadQuestions: string;
    loadValidatedProfiles: string;
    loadLevels: string;
    loadTalentpaths: string;
    createQuestion: string;
    updateQuestion: string;
    deleteQuestion: string;
    createAnswer: string;
    updateAnswer: string;
    deleteAnswer: string;
    createValidatedProfile: string;
    updateValidatedProfile: string;
    deleteValidatedProfile: string;

    //User Error Messages
    updateOrganisationRequest: string;
    userSharedLinkLogin: string;
    updateOrganisationUsers: string;
    updateOrganisationsRequest: string;
    changeRole: string;
    inviteUser: string;
    invitePartner: string;
    deleteUser: string;
    deleteOrganisationError: string;
    duplicateOrganisationError: string;
    createNewOrganisationError: string;
    updateOrganisationValuation: string;
    updateOrganisationTMALink: string;
    updateOrganisationContractContactPerson: string;
    updateOrganisationJobFamilies: string;
    updateOrganisationName: string;
    updateOrganisationMatrixes: string;
    updateResultInFamily: string;
    addOrganisationUnit: string;
    updateOrganisationUnit: string;
    deleteOrganisationUnit: string;
    addUnit: string;
    deleteUnit: string;
    updateUnit: string;
    updateUnitsAndOrganisationUnitsOrder: string;
    updateLevelNotes: string;
    uploadOrganisationImage: string;
    createShareLink: string;
    updateShareLink: string;
    deleteShareLink: string;
    updatePermissions: string;
    getRole: string;
    loadAllOrganisationsRequest: string;
    loadSelectedUserRequest: string;
    updateUserRequest: string;
    loadPagedUserRequest: string;

    //Update Error Messages
    updatesCreateUpdate: string;
    updatesGetUpdates: string;
    updatesChangeUpdate: string;
    updatesDeleteUpdate: string;

    //Results Error Messages
    getResult: string;
    getResults: string;
    postResult: string;
    postResultManual: string;
    changeStatusBulkError: string;
    changeResult: string;
    changeResultDetails: string;
    deleteResult: string;
    changeResultValidatedProfile: string;
    updateResultByField: string;
    updateResultExtraCompetence: string;
    changeResultExtraCompetenceOrder: string;
    saveExtraCompetence: string;

    //Language Error Messages
    getRemoteLanguages: string;

    //JobFamily Error Messages
    createJobfamily: string;
    updateJobfamily: string;
    deleteJobfamily: string;
    updateDifferentiatingFactor: string;
    updateJobFamilyByField: string;

    paginationTake: string;
    filterOrganisation: string;
    organisations: string;
    allOrganisations: string;
    userExportFileName: string;

    dockInformation: string;
    manual: string;
    ictIPS: string;
    faq: string;
    flyerCompensation: string;
    flyerJobMatrix: string;
    implementation: string;
    benchmark: string;
    objectionProcedure: string;
    templateAgreementCompensation: string;
    templateAgreementJobGrading: string;
    templateMaintenanceMatrix: string;
    templateMaintenanceGeneral: string;
    templatePlanningImplementation: string;
    compensationPolicy: string;

    manageMatrixes: string;
    manageMatrixesDescription: string;

    noPermissionHeader: string;
    noPermissionText: string;

    elementDisabledHeader: (item: string) => string;
    elementDisabledText: (item: string) => string;

    // JobFamily Images
    writeDescription: string;
    createNewVersion: string;

    noExistingFiles: string;
    versionManagement: string;

    ssoEditHeader: string;
    ssoDomain: string;
    ssoTenantId: string;
    saveSSOConfiguration: string;
    updateCurrentSsoConfiguration: string;
    getCurrentSsoConfigurations: string;

    documentOverview: string;
    jobFamilyVersion: string;

    contractContactPerson: string;
    manageContractContactPersons: string;

    organisationNameDescription: string;
    tmaLinkDescription: string;
    manageContractContactPersonsDescription: string;
    ssoEditDescription: string;
    createOrganisationDescription: string;
    deleteOrganisationDescription: string;
    duplicateOrganisationDescription: string;
    manageJobFamilyDescription: string;
    manageDifferentiatingFactorsDescription: string;

    noDocumentAvailable: string;
    documentTypeImplementation: string;
    documentTypeRewards: string;
    documentTypeMaintenance: string;

    updateExistingUser: string;
    updateUserName: string;
}

export default getLanguageObject;
