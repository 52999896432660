import React from 'react';
import MainLayoutProps from './MainLayout.types';
import { useLocation } from 'react-router-dom';
import {
    getLayoutForPath,
    getLayoutWrapper,
} from './MainLayout.helper';
import { layoutRecord } from './MainLayout.record';

const MainLayout: React.FC<MainLayoutProps> = ({
    children,
}) => {
    const path = useLocation().pathname;
    const layout = getLayoutForPath(path, layoutRecord);
    const LayoutWrapper = getLayoutWrapper[layout];

    return <LayoutWrapper>{children}</LayoutWrapper>;
};

export default MainLayout;
