import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { resultsGetResults } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { Family } from 'types/Result';
import { FamilyMatrix } from './JobFamilyMatrix.components';

import getLanguageObject from 'helpers/language';
import { updateOrganisationRequest } from 'store/user/user.actions';
import { getLevelIndex } from 'utils/levels';
import { JobFamilyNotEnabledContainer } from './JobFamilyMatrix.styling';
import { JobFamilyMatrixProps } from './jobFamilyMatrix.types';
import { getRemoteLanguages } from 'store/language/language.actions';
import 'react-toastify/dist/ReactToastify.css';
import { useOrganisation } from 'providers/Organisation.provider';

const JobFamilyMatrix: React.FC<JobFamilyMatrixProps> = ({
    permissions,
    isShareLink,
    id,
    currentLanguage,
    currentOrganisation,
    results,
    resultsGetResults,
    updateOrganisationRequest,
    getRemoteLanguages,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const { updateOrganisation } = useOrganisation();
    useEffect(() => {
        getRemoteLanguages();
    }, []);

    useEffect(() => {
        resultsGetResults();
    }, [resultsGetResults, currentOrganisation?.id]);

    useEffect(() => {
        updateOrganisation();
    }, []);

    const jobfamily = currentOrganisation?.jobFamilies.find(
        (item: Family) => item.id === id
    );

    if (!currentOrganisation) {
        return (
            <JobFamilyNotEnabledContainer>
                {lang.manageJobFamilyNotEnabled}
            </JobFamilyNotEnabledContainer>
        );
    }

    if (!results || !jobfamily) {
        return null;
    }

    const jobfamilyResults = jobfamily.jobs || [];
    const resultsInCurrentFamily = jobfamilyResults
        .map(y => results.find(r => r.id === y.id))
        .filter(x => Boolean(x))
        .map(x => x!);
    const sortedResultsInCurrentFamily = resultsInCurrentFamily.sort(
        (a, b) =>
            getLevelIndex(b.level?.toLowerCase()) -
            getLevelIndex(a.level?.toLowerCase())
    );

    return (
        <FamilyMatrix
            permissions={permissions}
            isShareLink={isShareLink}
            currentOrganisation={currentOrganisation}
            jobfamily={jobfamily}
            currentLanguage={currentLanguage}
            sortedResultsInCurrentFamily={sortedResultsInCurrentFamily}
            users={currentOrganisation.users}
        />
    );
};

const mapMatrixViewTabsStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    results: state.results.results,
    isShareLink: state.user.isShareLink,
    permissions: state.user.permissions,
});

const mapMatrixViewTabsDispatchToProps = {
    resultsGetResults,
    updateOrganisationRequest,
    getRemoteLanguages,
};
export const ConnectedJobFamilyMatrix = connect(
    mapMatrixViewTabsStateToProps,
    mapMatrixViewTabsDispatchToProps
)(JobFamilyMatrix);
