import { ShareLinkSettings } from 'store/user/user.types';
import { Language } from "types/MultiLingualString";
import { Organisation } from "types/Organisation";
import { Result } from "types/Result";

export default interface ResultsProps {
    currentLanguage: Language
    results: Result[];
    resultsGetResults: () => void;
    currentOrganisation?: Organisation;
    role?: string;
    isSharedLink?: boolean;
    share_link_settings?: ShareLinkSettings;
}

export interface FilterValues {
    name: string;
    status: string;
    level: string[];
    organisationUnit: string[];
    unit: string[];
    talentPath: string[];
}

export const BaseFilterValues: FilterValues = {
    name: '',
    unit: [],
    organisationUnit: [],
    level: [],
    status: 'Show All',
    talentPath: []
};

export type SetFilterValues = (filterValues: FilterValues) => void;
