import {
    InteractionType,
    InteractionRequiredAuthError,
    InteractionStatus,
} from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import Loading from 'containers/Loading/Loading';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { userLoginMicrosoft } from 'store/user/user.actions';

const RequireAuthAD: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { accounts, inProgress, instance } = useMsal();
    const dispatch = useDispatch<ThunkDispatch<any, unknown, AnyAction>>();

    const isInitialized = useRef(false);

    const location = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const tokenRequest = useMemo(
        () => ({
            account: accounts[0],
            scopes: [],
            forceRefresh: true,
            state: location.pathname || '/',
        }),
        [accounts, location.pathname]
    );

    const { login, acquireToken, result, error } = useMsalAuthentication(
        InteractionType.Redirect,
        tokenRequest
    );

    const loginCallback = useCallback(async () => {
        if (inProgress === InteractionStatus.None) {
            try {
                await login(InteractionType.Redirect);
            } catch (err) {
                console.error('Error during login:', err);
            }
        }
    }, [login, inProgress]);

    const handleTokenAcquisition = useCallback(async () => {
        try {
            const response = await acquireToken();
            console.log('Token acquired:', response);
            console.log(
                'Token expires at: ' +
                    new Date(
                        (response?.idTokenClaims as any).exp * 1000
                    ).toLocaleString()
            );

            if (response) {
                try {
                    await dispatch(userLoginMicrosoft(response.idToken));
                } catch (error) {
                    console.error('Error completing initial setup:', error);
                }
            }
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError && inProgress === InteractionStatus.None) {
                loginCallback();
            } else {
                console.error('Error refreshing token:', e);
            }
        } finally {
            setIsLoading(false);
        }
    }, [acquireToken, userLoginMicrosoft, loginCallback, inProgress]);

    // Refresh token every 5 minutes
    const refreshInterval = useCallback(() => {
        const interval = setInterval(() => {
            console.log('Checking token validity...');
            handleTokenAcquisition();
        }, 5 * 60 * 1000);

        return interval;
    }, [handleTokenAcquisition]);

    // On first render, check if we are already logged in
    // With the isInitialized flag, we can prevent the useEffect from running on every render
    // If isInitialized is true and we are logged in, start the refresh interval
    useEffect(() => {
        if (!isInitialized.current) {
            if (!instance.getActiveAccount() && accounts.length === 0) {
                loginCallback();
                return;
            }

            if (accounts.length > 0 && inProgress === InteractionStatus.None) {
                handleTokenAcquisition().then(async() => {
                    await acquireToken();
                    isInitialized.current = true;
                });
            }
        } else {
            console.log('Token acquired, starting refresh interval...');
            const interval = refreshInterval();

            return () => clearInterval(interval);
        }
    }, [
        accounts,
        instance,
        loginCallback,
        handleTokenAcquisition,
        inProgress,
        refreshInterval,
    ]);

    useEffect(() => {
        if (result?.state) {
            history.replace(result.state); 
        }
    }, [result, history]);

    // Only show loading spinner if we are not initialized and loading
    if (isLoading && !isInitialized.current) return <Loading />;

    const isAuthenticated = error == null && result != null;
    if (!isAuthenticated) return <MainLayoutNoSidebar />;

    return <>{children}</>;
};

export default RequireAuthAD;
