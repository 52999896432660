import { getOrganisationUnitAndUnitWithOrderByIndex, moveArray } from 'helpers/arrays';
import { loadState } from 'store/localStorage';
import UserActions from './user.constants';
import UserState, { UserDispatches } from './user.types';
import { defaultLevelOptions } from 'utils/levelOptions';

const initialFromLocalStorage = loadState();

export const initialAlternative: UserState = {
    errors: [],
    manageOrganisationError: '',
    organisations: [],
    permissions: {
        canEditJobValidatedProfile: true,
        canProvideStyrCompetencesAccess: true,
        canDeleteResult: true,
        canUpdateStatusResult: true,
        canEditJobMatrix: true,
        canManageOrganisation: true,
        canInviteUser: true,
        canInviteExpert: true,
        canInvitePartnerUser: true,
        canInvitePartnerExpert: true,
        canInviteAdministrator: false,
        canRemoveUserFromOrganisation: true,
        canViewAllUsers: false,
        canEditOrganisationUnits: true,
        canEditDifferentiatingFactors: true,
        canUpdateAlternativeValuationMethod: true,
        canCreateOrganisation: true,
        canDeleteOrganisation: true,
        canDuplicateOrganisation: true,
        canNotAccessLevels: [],
        canOnlyAccessOrganisationUnit: "",
        canToggleStyrTmaNormedCompetencies: true,
        canViewDocuments: false,
        canConfigureSso: false,
        canManageContractContactPersons: false,
        canUpdateUserName: false,
        canViewExtraFields: {
            extraField1: false,
            extraField2: false,
            extraField3: false,
        },
        canViewTags: false,
    },
    // Why are these hardcoded default level options stored in the user state and therefor cached?
    options: defaultLevelOptions,
    passwordEmailSent: false,
    passwordHasReset: false,
    role: "",
    allUsers: [],
    allUsersTotalCount: 0,
    allOrganisations: [],
    currentSsoConfigurations: [],
};

export const initialState: UserState =
    initialFromLocalStorage || initialAlternative;

const userReducer = (
    state: UserState = initialState,
    action: UserDispatches
) => {
    switch (action.type) {
        case UserActions.AddError:
            return {
                ...state,
                errors: [action.payload.error],
            };
        case UserActions.UpdatePermissions:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    ...action.payload.permissions,
                }
            };
        case UserActions.AddManageOrgError:
            return {
                ...state,
                manageOrganisationError: action.payload.error,
            };
        case UserActions.ClearManageOrgError:
            return {
                ...state,
                manageOrganisationError: '',
            };
        case UserActions.Login:
            return {
                ...state,
                jwt: action.payload.jwt,
                isShareLink: Boolean(action.payload.isShareLink),
                passwordEmailSent: false,
                passwordHasReset: false,
                share_link_settings: action.payload.share_link_settings,
            };
        case UserActions.DeleteOrganisation:
            return {
                ...state,
                organisations: state.organisations.filter(
                    x => x.id !== action.payload.organisationId
                ),
            };
        case UserActions.SetCurrentOrganisation:
            const foundOrganisation = state.organisations.find(
                x => x.id === action.payload.id
            );
            if (!foundOrganisation) {
                return {
                    ...state,
                    errors: [
                        ...state.errors,
                        'Can not switch to non-existing organisation',
                    ],
                };
            }
            return {
                ...state,
                currentOrganisation: {
                    ...foundOrganisation,
                    users: [],
                    differentiatingFactors: foundOrganisation.differentiatingFactors || {
                        problemSolvingCapability: true,
                        levelOfExpertise: true,
                        planningHorizon: true,
                        continuousImprovement: true,
                        autonomy: true,
                        communicationSkills: true,
                        coordinationSkills: true,
                        projectManagementSkills: true,
                        educationLevel: true,
                        styrCompetencies: false,
                    }
                },
            };
        case UserActions.Logout:
            return {
                ...state,
                jwt: undefined,
                user: undefined,
                role: undefined,
                permissions: {
                    ...initialAlternative.permissions,
                    canManageOrganisation: false,
                    canInviteExpert: false,
                    canInvitePartnerExpert: false,
                    canInvitePartnerUser: false,
                },
                errors: [],
            };
        case UserActions.ClearErrors:
            return {
                ...state,
                errors: [],
            };
        case UserActions.AddOrganisation:
            return {
                ...state,
                organisations: [
                    ...state.organisations,
                    action.payload.organisation,
                ],
                currentOrganisation: {
                    ...action.payload.organisation,
                    differentiatingFactors: action.payload.organisation.differentiatingFactors || {
                        problemSolvingCapability: true,
                        levelOfExpertise: true,
                        planningHorizon: true,
                        continuousImprovement: true,
                        autonomy: true,
                        communicationSkills: true,
                        coordinationSkills: true,
                        projectManagementSkills: true,
                        educationLevel: true,
                        styrCompetencies: false,
                    }
                }
            };
        case UserActions.UpdateOrganisation:
            return {
                ...state,
                organisations: state.organisations.map(org => {
                    if (org.id === action.payload.organisation.id) {
                        return action.payload.organisation;
                    }
                    return org;
                }),
                currentOrganisation: {
                    ...action.payload.organisation,
                    users: state.currentOrganisation?.users || [],
                    differentiatingFactors: action.payload.organisation.differentiatingFactors || {
                        problemSolvingCapability: true,
                        levelOfExpertise: true,
                        planningHorizon: true,
                        continuousImprovement: true,
                        autonomy: true,
                        communicationSkills: true,
                        coordinationSkills: true,
                        projectManagementSkills: true,
                        educationLevel: true,
                        styrCompetencies: false,
                    }
                }
            };
        case UserActions.UpdateOrganisations:
            const currentOrgSelected = state.currentOrganisation;

            const currentOrgNew = action.payload.organisations.find(x => x.id === currentOrgSelected?.id) || action.payload.organisations[0]

            const isSharedLink = action.payload.isSharedLink;
            if (isSharedLink && action.payload.organisations.length > 0) {
                const newOrg = action.payload.organisations[0];
                return {
                    ...state,
                    organisations: action.payload.organisations,
                    currentOrganisation: {
                        ...newOrg,
                        differentiatingFactors: newOrg.differentiatingFactors || {
                            problemSolvingCapability: true,
                            levelOfExpertise: true,
                            planningHorizon: true,
                            continuousImprovement: true,
                            autonomy: true,
                            communicationSkills: true,
                            coordinationSkills: true,
                            projectManagementSkills: true,
                            educationLevel: true,
                            styrCompetencies: false,
                        }
                    },
                }
            }

            return {
                ...state,
                organisations: action.payload.organisations,
                currentOrganisation: {
                    ...currentOrgNew,
                    ...currentOrgSelected,
                    differentiatingFactors: currentOrgSelected?.differentiatingFactors || currentOrgNew.differentiatingFactors || {
                        problemSolvingCapability: true,
                        levelOfExpertise: true,
                        planningHorizon: true,
                        continuousImprovement: true,
                        autonomy: true,
                        communicationSkills: true,
                        coordinationSkills: true,
                        projectManagementSkills: true,
                        educationLevel: true,
                        styrCompetencies: false,
                    }
                },
            };
        case UserActions.UpdateUnitOrder:
            const currentOrg = state.currentOrganisation;
            if (!currentOrg) {
                return state;
            }
            const newOrganisationUnits = currentOrg.organisationUnits.map((organisationUnit) => {
                if (action.payload.organisationUnit === organisationUnit.id) {
                    const newUnits = moveArray(organisationUnit.units, action.payload.oldIndex, action.payload.newIndex);
                    return ({
                        ...organisationUnit,
                        units: newUnits
                    })
                }
                return organisationUnit;
            })
            const newOrganisationUnitsWithOrder = getOrganisationUnitAndUnitWithOrderByIndex(newOrganisationUnits);
            const newOrg = {
                ...currentOrg,
                organisationUnits: newOrganisationUnitsWithOrder,
            }
            return {
                ...state,
                organisations: state.organisations.map(org => {
                    if (org.id === newOrg.id) {
                        return newOrg;
                    }
                    return org;
                }),
                currentOrganisation: newOrg
            };
        case UserActions.UpdateOrganisationUnitOrder:
            const currentOrganisation = state.currentOrganisation;
            if (!currentOrganisation) {
                return state;
            }
            const reorderedOrganisationUnit = moveArray(currentOrganisation.organisationUnits, action.payload.oldIndex, action.payload.newIndex);
            const newEnt = getOrganisationUnitAndUnitWithOrderByIndex(reorderedOrganisationUnit);
            const newOrganisation = {
                ...currentOrganisation,
                organisationUnits: newEnt,
            }
            return {
                ...state,
                organisations: state.organisations.map(org => {
                    if (org.id === newOrganisation.id) {
                        return newOrganisation;
                    }
                    return org;
                }),
                currentOrganisation: newOrganisation
            }
        case UserActions.SetCurrentSsoConfigurations:

                return {
                    ...state,
                    currentSsoConfigurations: action.payload.ssoConfigurations,
                };
        case UserActions.SetUsersForCurrentOrganisation:
            const curOrg = state.currentOrganisation;
            if (!curOrg) {
                return state;
            }
            return {
                ...state,
                currentOrganisation: {
                    ...curOrg,
                    users: action.payload.users,
                },
            };
        case UserActions.UpdateRole:
            return {
                ...state,
                role: action.payload.role,
            };
        case UserActions.UpdateAllUsers:
            return {
                ...state,
                allUsers: action.payload.allUsers,
                allUsersTotalCount: action.payload.allUsersTotalCount,
            };
        case UserActions.UpdateSelectedUserOrganisation:
            return {
                ...state,
                selectedOrganisationUser: action.payload.selectedOrganisationUser,
            };
        case UserActions.UpdateAllOrganisations:
            return {
                ...state,
                allOrganisations: action.payload.organisations,
            };
        default:
            return state;
    }
};

export default userReducer;
