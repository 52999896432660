import React from 'react';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import LoginForm from 'containers/LoginForm/LoginForm.connector';
import LoginProps from './Login.types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReduxStore from 'store/store.type';
import Loading from 'containers/Loading/Loading';

const Login: React.FC<LoginProps> = () => {

    const { jwt } = useSelector((state: ReduxStore) => state.user);

    if (jwt) {
        return <Redirect to="/" />;
    }

    return (
        <MainLayoutNoSidebar>
            <div style={{ display: 'none' }}>
                <LoginForm />
            </div>
            <Loading />
        </MainLayoutNoSidebar>
    );
};

export default Login;
