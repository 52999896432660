import React from 'react';

import { Container } from './Documents.components';
import DocumentsProps from './Documents.types';
import ConnectedDocumentsBlocksSection from 'sections/DocumentsBlocksSection/DocumentsBlocksSection.connector';

const Documents: React.FC<DocumentsProps> = ({}) => {
    return (
        <Container>
            <ConnectedDocumentsBlocksSection />
        </Container>
    );
};

export default Documents;
