enum Routes {
    LoginRoute = '/login',
    ShardRoute = '/shared/',
    DashboardRoute = '/dashboard',
    DocumentsRoute = '/documents',

    // ALLOCATOR
    AllocatorRoute = '/allocator',
    PdfRoute = '/pdf/',

    // JOBS
    ResultRoute = '/jobs/results/',
    ResultPreviewRoute = '/preview/',
    ResultsRoute = '/jobs/results',

    //JOBMATRIX
    JobMatrixRoute = '/job-matrix',
    ManageJobMatrix = '/jobmatrix/edit',
    JobMatrixPrint = '/print-jobmatrix/',
    TalentMatrixPrint = '/print-talentmatrix/',
    TalentMatrix = '/talent-matrix',

    // ORGANISATION
    LatestUpdates = '/latest-updates/',
    StyrManual = '/styr-manual/',
    ManageOrganisationDetails = '/organisation',
    ManageOrganisationUsers = '/users',
    UpdateRoute = '/update/',
    CreateUpdateRoute = '/update/',
    PartnerRoute = '/partner',
    ValuationRoute = '/valuation',

    // SHARE LINKS
    ManageShareLinks = '/share/links',

    // JOBFAMILY
    ManageJobFamily = '/jobfamily/results',
    JobFamilyEdit = '/jobfamily/edit/',
    JobFamilyView = '/jobfamily/view/',

    // ADMIN
    AdminRoute = '/admin',
    AdminAllocatorQuestionsRoute = '/admin/questions',
    AdminAllocatorQuestionRoute = '/admin/question/',
    AdminAllocatorValidatedProfilesRoute = '/admin/validatedProfiles',
    AdminAllocatorValidatedProfileRoute = '/admin/validatedProfile/',
    AdminUserManagementRoute = '/admin/users',
    AdminUserManagementDetailRoute = '/admin/user/',
}

export default Routes;
