import { LangObject } from 'helpers/language';

export const fr: LangObject = {
    id: '27c0a230-36f3-46d8-969c-be58e179baf2',
    styrAllocator: 'Styr Allocator',
    dashboard: 'Accueil',
    organisation: 'Organisation/Unité',
    startAllocator: 'Démarrer l’allocateur',
    searchJobsnRoles: 'Chercher des fonctions/rôles',
    talentManagementTool: 'Système de gestion des talents',
    elearningStyrModel: 'Modèle Styr d’e-learning',
    styrUpdates: 'Release-informatie',
    forgotPassword: 'Passe oublié?',
    logout: 'Déconnexion',
    edit: 'Modifier',
    add: 'Ajouter',
    unitEmpty: 'Le nom d’unité ne peut pas être vide. Renseignez un nom pour continuer',
    unitAlreadyExists: 'L’unité existe déjà. Choisissez un autre nom pour continuer',
    noUnits: 'Aucune unité disponible',
    editJob: 'Modifier profil',
    editFilterOptions: 'Modifier les options de filtre',
    styrProfile: 'Profil validé',
    viewValidatedProfile: 'voir le Profil validé',
    viewJobFamily: 'Voir la famille d’emplois',
    viewJobMatrix: 'Voir Matrice d’emploi',
    talentMatrix: 'Matrice de talent',
    theTalentMatrix: 'La Matrice de talent',
    chooseUserRole: 'Choisissez un rôle d’utilisateur',
    userRole: 'Rôle d’utilisateur',
    jobMatrix: 'Matrice d’emploi',
    theJobMatrix: 'La Matrice d’emploi',
    jobTalentMatrix: 'Matrice d’emploi / Matrice de talent',
    jobMatrixArchitecture: 'Architecture de matrice d’emploi',
    jobMatrixArchitectureName: 'Nom de l’organisation/unité',
    pleaseEnterUnit: 'Veuillez saisir une unité',
    nameAlreadyExists: 'Le nom existe déjà, veuillez saisir un autre nom pour continuer',
    uploadImage: 'Chargez une image',
    chooseImage: 'Choisissez une image',
    styrLevel: 'Styr level',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Colonne supplémentaire',
    freeInputfield: 'Colonne de matrice d’emploi supplémentaire',
    freeInputfield2: 'Colonne de matrice d’emploi supplémentaire 2',
    freeInputfield3: 'Colonne de matrice d’emploi supplémentaire 3',
    salaryScale: 'Échelle salariale',
    naturalCareerPath: 'Voie des talents',
    styrGroups: 'Styr group',
    styrGroup: 'Styr group',
    problemSolvingCapability: 'Capacité de résolution des problèmes',
    expertiseLevel: 'Niveau d’expertise',
    planningHorizon: 'Horizon de planification',
    continuousImprovement: 'Amélioration continue',
    autonomy: 'Autonomie',
    communicationSkills: 'Compétences en communication',
    managementSkill: 'Compétences en coordination (facultative)',
    management: 'Direction',
    levelOfExpertise: 'Niveau d’expertise',
    planning_horizon: 'Horizon de planification',
    continuous_improvement: 'Amélioration continue',
    communication_skills: 'Compétences en communication',
    coordinationSkills: 'Compétences en coordination',
    projectManagementSkills: 'Compétences en gestion de projet',
    styr_competencies: 'Compétences STYR-TMA',
    extra_competencies: 'Compétences supplémentaires',
    education_level: 'Niveau de travail et de réflexion / années d’expérience (indication)',
    educationLevelValidatedProfile: 'Niveau de travail et de réflexion / années d’expérience (indication)',
    educationLevel: 'Niveau d’expertise. Niveau de travail et de réflexion / années d’expérience (indication)',
    question: 'Quel est le nom de la fonction ou du rôle ?’,',
    unitShort: 'Architecture 2',
    organisationUnit: 'Organisation/Unité’,',
    unit: 'Unité',
    organisationUnitHasNoUnits: (organisationUnitName: string) => `L’organisation/unité ${organisationUnitName} n’a pas d’unités`,
    jobAlreadyExist: 'L’emploi existe déjà. Choisissez un autre nom pour continuer',
    noNameEntered: 'Veuillez saisir un nom pour continuer',
    addNewOrganisationUnit: 'Ajouter à l’organisation/unité',
    addNewUnit: 'Ajouter à l’unité',
    unitMultiple: 'Unités',
    deleteWarningOrganisationUnit: 'Attention ! Lorsque cette organisation/unité est supprimée, tous les résultats liés dans l’organisation/unité sont également supprimés',
    deleteWarningUnit: 'Attention ! Lorsque cette unité est supprimée, tous les résultats liés dans l’unité sont également supprimés’,',
    deleteWarningUser: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    inviteNewUser: 'Inviter un nouvel utilisateur',
    inviteExistingUser: 'Inviter un utilisateur existant',
    inviteNewPartner: 'Inviter un nouveau consultant',
    userInvited: 'Nouvel utilisateur invité',
    partnerInvited: 'Nouveau consultant invité',
    next: 'Suivant',
    back: 'Retour',
    close: 'Fermer',
    title: 'Titre',
    description: 'Description',
    comment: 'Message',
    noResults: 'Aucun résultat trouvé',
    placeholder: (item: string) => `Saisissez ici votre ${item}...`,
    placeholderError: (item: string) => `Veuillez renseigner votre${item}!`,
    post: 'Envoyer',
    attachment: 'Pièce jointe',
    user: 'utilisateur',
    users: 'Utilisateurs',
    manageUsers: 'Gérer les utilisateurs',
    submit: 'Envoyer',
    updates: 'Dernières mises à jour',
    recentAllocated: 'Allocation la plus récente',
    questionsServiceDeskHome: 'Prenez contact avec le support technique Styr lorsque vous avez des questions.',
    date: 'Date',
    retry: 'Rétablir',
    role: 'Profil',
    functionOrRole: 'Fonction / rôle',
    jobRole: 'Titre de profil',
    jobNRole: 'Profils',
    level: 'Niveau',
    organisationName: 'Nom de l’organisation',
    createDetails: 'Créer des détails',
    createdOn: 'Créé sur',
    createOrganisation: 'Créer une organisation',
    createUser: 'Créer un nouvel utilisateur',
    createNewOrganisation: 'Ajouter une organisation',
    note: 'Informations complémentaires',
    save: 'Enregistrer',
    saving: 'Enregistrer…',
    saveResults: 'Enregistrer…',
    delete: 'Supprimez',
    deletePlural: 'Supprimer',
    newName: 'Nouveau nom',
    name: 'Nom ',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    editPlural: 'Modifier',
    confirmation: (action: string, item: string) => `Êtes-vous sûr(e) de vouloir ${action} ${item}?`,
    result: 'Résultat',
    cancel: 'Annulez',
    freeInputLevel: 'Colonne de matrice d’emploi supplémentaire',
    columns: 'Colonnes',
    compare: 'Comparez',
    choose: 'Choisissez',
    change: 'Changez',
    manageOrganisation: 'Gérer une organisation',
    manageValuation: "Gérer l'évaluation",
    manageValuationDescription: "Relier les niveaux classiques d'évaluation des emplois aux niveaux Styr.\n\nRemarque ! Seuls les niveaux d'évaluation classiques seront visibles dans le module Job Profiler.",
    pleaseSelectAtleastTwo: 'Choisissez au minimum 2 niveaux pour comparer',
    styrDifferencingFactors: 'Facteurs différenciateurs Styr',
    showTalentpath: 'Afficher la voie des talents',
    changeStyrLevel: 'Comparer Styr level',
    selectLevelAfterTalentpathChange: 'Veuillez sélectionner un niveau après avoir modifié le parcours des talents',
    backToValidatedProfile: 'Retour vers le profil',
    backToPreview: 'Retour vers l’aperçu',
    changeLevel: 'Changez Styr level',
    chooseStyrLevels: (current: number) => `Choisissez Styr level (max ${current}/3)`,
    selectStyrLevelsToCompare: (current: number) => `Sélectionnez Styr level que vous souhaitez comparer (max. ${current}/3)`,
    currentValidatedProfileLevelIs: 'Styr level actuel est',
    youreAboutToChange: 'Vous êtes sur le point de changer ceci pour le niveau',
    changing: 'Modifier',
    theStyrLevel: 'Styr level',
    talentpath: 'Voie des talents',
    view: 'Afficher',
    viewInformation: 'Informations complémentaires',
    allocatorWarningOrganisation: 'Il est impossible d’utiliser l’Allocateur Styr sans ajouter à l’organisation une architecture de matrice d’emploi.',
    maxLogoWidthDescription: 'Largeur max. logo 1000px',
    maxLogoWidthWarning: 'L’image est trop large',
    supportedFileTypes: 'Types de fichiers pris en charge',
    goToManagement: 'Allez vers Gérer',
    talentpathAdministrative: 'Administratif',
    talentpathAdvisory: 'Conseil',
    talentpathCommercial: 'Commercial',
    talentpathExpert: 'Expert',
    talentpathManagement: 'Direction',
    talentpathProjectManagement: 'Gestion de projet',
    talentpathSupportCoordination: 'Soutien et coordination',
    talentpathProcessManagement: 'Gestion de processus',
    talentpathOperationalTechnical: 'Opérationnel / Technique',
    manageJobFamily: 'Job Profiler',
    manageJobFamilyNotEnabled: 'Les (familles d’)emplois ne sont pas disponibles pour l’organisation actuelle',
    search: 'Chercher',
    jobFamilyAlreadyExist: 'L’emploi (ou la famille d’emplois) existe déjà',
    pleaseEnterFamilyName: 'Nom de l’emploi (ou de la famille d’emplois) oublié',
    addJobFamily: 'Ajouter l’emploi (ou la famille d’emplois)',
    goToSettings: 'Paramètres',
    addNewJob: 'Créer un nouvel emploi',
    newJobFamily: 'Nouvelle famille d’emplois',
    nameJobFamily: 'Nom de la famille d’emplois’',
    status: 'Statut',
    changeStatusBulk: 'Change status:', //TODO: Translation Change status
    jobFamily: 'Famille d’emplois',
    lastModified: 'Dernière modification',
    differentiatingFactorsTitle: 'Facteurs différenciateurs',
    changeDifferentiatingFactorsTitle: 'Changez Facteurs différenciateurs',
    differentiatingFactorsDescription: 'Gérez la visibilité des facteurs différenciateurs, accédez à Paramètres pour les désactiver et les activer.',
    competencies: 'Compétences',
    defaultStyrCompetencies: 'Compétences STYR-TMA',
    extraCompetencies: 'Compétences supplémentaires',

    mainResponsibilities: 'Principales responsabilités',
    manage: 'Gérer',
    row: 'Ligne',
    enterTitle: 'Entrez un titre',
    enterDescription: 'Entrez une description',
    addExtraCompetence: 'Ajouter une compétence',
    manageRows: 'Gérer les lignes',
    createNewJob: 'Créer un nouvel emploi',
    selectOrganisationUnit: 'Sélectionnez une organisation / unité',
    selectUnit: 'Sélectionnez une unité',
    selectTalentPath: 'Sélectionnez une voie des talents',
    selectLevel: 'Sélectionnez un niveau Styr',
    pleaseFillInAllField: 'Il semble que vous ayez oublié quelque chose.',

    accountability: 'Responsabilité',
    attention_to_detail: 'Sens du détail',
    business_orientation: 'Esprit d’entreprise',
    commercial_power: 'Commercial',
    conduct: 'Attitude',
    controlling_progress: 'Contrôle de l’avancement',
    cooperation: 'Esprit de collaboration',
    creativity: 'Créativité',
    customer_orientation: 'Orientation vers le client',
    decisiveness: 'Esprit de décision',

    delegating: 'Déléguer',
    developing_employees: 'Développement des collaborateurs',
    discipline_competence: 'Discipline',
    flexible_behavior: 'Souplesse du comportement',
    focus_on_quality: 'Recherche de la qualité',
    forming_judgment: 'Capacité de jugement',
    identification_with_management: 'Identification avec la direction',
    independence: 'Indépendance',
    innovative_power: 'Capacité d’innovation',
    insight: 'Perspicacité',
    leadership_of_groups: 'Leadership de groupe',
    managing: 'Leadership',
    need_to_achieve: 'Désir de performance',
    networking: 'Réseau de relations',
    perseverance: 'Persévérance',
    persuasiveness: 'Pouvoir de persuasion',
    planning_and_organizing: 'Planification et organisation',
    result_orientedness: 'Orientation résultats',
    sociability: 'Sociabilité',
    social_awareness: 'Conscience de l’environnement professionnel et sociétal',
    verbal_expression: 'Aptitudes orales',
    vision: 'Vision',
    workmanship: 'Expertise',
    courage: 'Mut',
    integrity: 'Intégrité',
    written_expression: 'Aptitudes rédactionnelles',
    backToJobFamilies: 'Retour vers les familles d’emplois',
    showAll: 'Afficher tout',
    jobfamilySearchPlaceholder: 'Recherche sur : Titre, organisation, unité, niveau ',
    filterResults: 'Profils des filtres',
    password: 'Mot de passe',
    accessCode: 'Access code',
    accessCodeDescription: 'he access code is not a password. The share link in combination with the access code can be shared through an intranet or similar secure channel. Can not contain special characters.',
    accessCodeError: 'Can not contain special characters.',
    usernameOrEmail: 'Nom (Username) ou adresse e-mail',
    usernameEmpty: "Veuillez saisir votre nom d'utilisateur pour continuer",
    passwordEmpty: 'Veuillez saisir votre mot de passe pour continuer',
    microsoftNotWorking: "Le compte n'a pas pu être vérifié.",
    switchOrganisation: "Changer d'organisation",
    viewOtherOrganisations: "Voir d'autres organisations",

    login: 'Log in',
    forgotPasswordTitle: 'Vous avez oublié votre mot de passe ?',
    backToLogin: 'Retour à la login',
    requestNewPassword: 'Demander un mot de passe',
    tryAgain: 'Réessayer',
    newPasswordSend: "L'e-mail contenant un lien pour réinitialiser votre mot de passe a été envoyé. N'avez-vous pas reçu l'e-mail ?",
    emailFieldEmpty: 'Le champ email est vide',
    resetPasswordTitle: 'Réinitialiser votre mot de passe',
    resetPasswordSuccess: 'Votre mot de passe a été réinitialisé avec succès.',
    newPassword: 'Nouveau mot de passe',
    duplicateOrganisation: "Dupliquer l'organisation actuelle",
    deleteOrganisation: 'Delete Organisation',

    // SHARELINKS
    manageShareLinkTitle: "Lien de partage de l'organisation",
    manageShareLinkDescription: `Suivez les trois étapes suivantes pour créer un lien partagé permettant aux employés d'accéder à la matrice des emplois/matrice des talents et à ses profils. Seuls les profils actifs seront affichés dans la matrice des emplois/matrice des talents.

    1) Cliquez sur "créer un nouveau lien" et sélectionnez la vue que vous souhaitez afficher.
    2) Saisissez un mot de passe pour sécuriser le lien partagé.
    3) Enfin, partagez le lien et le mot de passe avec le destinataire prévu et veillez à ce qu'ils restent confidentiels afin de garantir l'accès et la sécurité voulus.
    `,

    createNewLink: 'Créer un nouveau link',
    link: 'Link',
    dateAdded: "Date d'ajout",

    questionDeleteShareLink: 'Êtes vous sur de vouloir supprimer?',

    createNewShareLinkTitle: 'Créer un lien de partage',
    createNewShareLinkDescription: "Définissez les droits de visualisation afin que les employés aient accès à l'organisation/aux unités et aux niveaux Styr concernés. Vous devez protéger votre lien avec un mot de passe.",

    allOrganisationUnitsTitle: 'Tous',
    createdBy: 'Classé par',
    updatedBy: 'Dernière modification par',

    headerSearchbarNoResults: 'Aucun résultat',
    headerSearchbarPlaceholder: '',

    createNewProfile: 'créer un nouveau profil',
    pleaseSelectAnAnswer: 'Veuillez sélectionner une réponse pour continuer',

    pleaseSelectALevel: 'Veuillez sélectionner un niveau pour le nouveau talentpath.',
    showStyrProfile: 'Afficher le profil validé',

    createUpdate: 'Create update',
    allocatorQuestions: 'Allocator Questions',
    allocatorQuestion: 'Allocator Question',

    errorTitle: "Quelque chose s'est mal passé",
    errorFailedFetchText: "Nous ne pouvons pas nous connecter au serveur. Veuillez contacter <a href='mailto:helpdesk@styr.nl'>le service d'assistance</a> si ce problème persiste. ",
    errorEndText: 'Réessayez plus tard',

    tagsHeader: 'Sélectionner des balises',
    tagsUntagged: 'Non tagué',
    tagsFutureState: 'État futur',
    tagsCurrentState: 'État actuel',

    multiselectAllItemsAreSelected: 'Tous les éléments sont sélectionnés',
    multiselectClearSearch: 'Effacer la recherche',
    multiselectClearSelected: 'Effacer la sélection',
    multiselectNoOptions: 'Aucune option',
    multiselectSearch: 'Recherche',
    multiselectSelectAll: 'Tout sélectionner',
    multiselectSelectAllFiltered: 'Tout sélectionner (filtré)',
    multiselectSelectSomeItems: 'Sélectionner...',
    multiselectCreate: 'Créer',
    mulitselectSelect: 'Sélectionner',

    //Allocator Error Messages
    loadQuestions: 'Impossible de charger les questions demandées, veuillez réessayer plus tard',
    loadValidatedProfiles: 'Impossible de charger les profils validés, veuillez réessayer plus tard',
    loadLevels: 'Impossible de charger les niveaux, veuillez réessayer plus tard',
    loadTalentpaths: 'Impossible de charger les chemins de talent, veuillez réessayer plus tard',
    createQuestion: 'Impossible de créer la question, veuillez réessayer plus tard',
    updateQuestion: 'Impossible de mettre à jour la question, veuillez réessayer plus tard',
    deleteQuestion: 'Impossible de supprimer la question, veuillez réessayer plus tard',
    createAnswer: 'Impossible de créer la réponse, veuillez réessayer plus tard',
    updateAnswer: 'Impossible de mettre à jour la réponse, veuillez réessayer plus tard',
    deleteAnswer: 'Impossible de supprimer la réponse, veuillez réessayer plus tard',
    createValidatedProfile: 'Impossible de créer le profil validé, veuillez réessayer plus tard',
    updateValidatedProfile: 'Impossible de mettre à jour le profil validé, veuillez réessayer plus tard',
    deleteValidatedProfile: 'Impossible de supprimer le profil validé, veuillez réessayer plus tard',

    //User Error Messages
    updateOrganisationRequest: "Impossible de récupérer l'organisation, veuillez réessayer plus tard",
    userSharedLinkLogin: 'Impossible de se connecter avec le lien partagé, veuillez réessayer plus tard',
    updateOrganisationUsers: "Impossible de récupérer les utilisateurs de l'organisation, veuillez réessayer plus tard",
    updateOrganisationsRequest: 'Impossible de récupérer les organisations, veuillez réessayer plus tard',
    changeRole: "Impossible de changer le rôle de l'utilisateur, veuillez réessayer plus tard",
    inviteUser: "Impossible d'inviter l'utilisateur, veuillez réessayer plus tard",
    invitePartner: "Impossible d'inviter le partenaire, veuillez réessayer plus tard",
    deleteUser: "Impossible de supprimer l'utilisateur, veuillez réessayer plus tard",
    deleteOrganisationError: "Impossible de supprimer l'organisation, veuillez réessayer plus tard",
    duplicateOrganisationError: "Impossible de dupliquer l'organisation, veuillez réessayer plus tard",
    createNewOrganisationError: 'Impossible de créer une nouvelle organisation, veuillez réessayer plus tard',
    updateOrganisationValuation: "Impossible de mettre à jour la valorisation de l'organisation, veuillez réessayer plus tard",
    updateOrganisationTMALink: "Impossible de mettre à jour le lien TMA de l'organisation, veuillez réessayer plus tard",
    updateOrganisationJobFamilies: "Impossible de mettre à jour les familles de métiers de l'organisation, veuillez réessayer plus tard",
    updateOrganisationName: "Impossible de mettre à jour le nom de l'organisation, veuillez réessayer plus tard",
    updateResultInFamily: 'Impossible de mettre à jour le job dans la famille, veuillez réessayer plus tard',
    addOrganisationUnit: "Impossible d'ajouter l'unité d'organisation, veuillez réessayer plus tard",
    updateOrganisationUnit: "Impossible de mettre à jour l'unité d'organisation, veuillez réessayer plus tard",
    updateOrganisationMatrixes: "Impossible d'activer ou de désactiver les matrices organisationnelles. Veuillez réessayer plus tard.",
    deleteOrganisationUnit: "Impossible de supprimer l'unité d'organisation, veuillez réessayer plus tard",
    addUnit: "Impossible d'ajouter l'unité, veuillez réessayer plus tard",
    deleteUnit: "Impossible de supprimer l'unité, veuillez réessayer plus tard",
    updateUnit: "Impossible de mettre à jour l'unité, veuillez réessayer plus tard",
    updateUnitsAndOrganisationUnitsOrder: "Impossible de mettre à jour l'ordre des unités et des unités d'organisation, veuillez réessayer plus tard",
    updateLevelNotes: 'Impossible de mettre à jour les notes de niveau, veuillez réessayer plus tard',
    uploadOrganisationImage: "Impossible de télécharger l'image de l'organisation, veuillez réessayer plus tard",
    createShareLink: 'Impossible de créer le lien de partage, veuillez réessayer plus tard',
    updateShareLink: 'Impossible de mettre à jour le lien de partage, veuillez réessayer plus tard',
    deleteShareLink: 'Impossible de supprimer le lien de partage, veuillez réessayer plus tard',
    updatePermissions: 'Impossible de mettre à jour les autorisations, veuillez réessayer plus tard',
    getRole: 'Impossible de récupérer le rôle, veuillez réessayer plus tard',
    loadAllOrganisationsRequest: 'Impossible de charger toutes les organisations, veuillez réessayer plus tard',
    loadSelectedUserRequest: "Impossible de charger l'utilisateur sélectionné, veuillez réessayer plus tard",
    updateUserRequest: "Impossible de mettre à jour l'utilisateur, veuillez réessayer plus tard",
    loadPagedUserRequest: "Impossible de charger l'utilisateur paginé, veuillez réessayer plus tard",

    //Update Error Messages
    updatesCreateUpdate: 'Impossible de créer une mise à jour pour le moment, veuillez réessayer plus tard',
    updatesGetUpdates: 'Impossible de récupérer les mises à jour pour le moment, veuillez réessayer plus tard',
    updatesChangeUpdate: 'Impossible de modifier une mise à jour pour le moment, veuillez réessayer plus tard',
    updatesDeleteUpdate: 'Impossible de supprimer une mise à jour pour le moment, veuillez réessayer plus tard',

    //Results Error Messages
    getResult: 'Impossible de récupérer les job demandés, veuillez réessayer plus tard.',
    getResults: 'Impossible de récupérer les jobs demandés, veuillez réessayer plus tard.',
    postResult: 'Impossible de créer le job, veuillez réessayer plus tard.',
    postResultManual: 'Impossible de créer le job, veuillez réessayer plus tard.',
    changeStatusBulkError: 'Impossible de changer le statut des jobs en masse, veuillez réessayer plus tard.',
    changeResult: 'Impossible de modifier le job, veuillez réessayer plus tard.',
    changeResultDetails: 'Impossible de modifier les détails du job, veuillez réessayer plus tard.',
    deleteResult: 'Impossible de supprimer le job, veuillez réessayer plus tard.',
    changeResultValidatedProfile: 'Impossible de changer le niveau styr du profil validé, veuillez réessayer plus tard.',
    updateResultByField: 'Impossible de mettre à jour la jobfamily, veuillez réessayer plus tard.',
    updateResultExtraCompetence: 'Impossible de mettre à jour la compétence supplémentaire du job, veuillez réessayer plus tard.',
    changeResultExtraCompetenceOrder: "Impossible de changer l'ordre des compétences supplémentaires du job, veuillez réessayer plus tard.",
    saveExtraCompetence: 'Impossible de sauvegarder la compétence supplémentaire du job, veuillez réessayer plus tard.',

    //Language Error Messages
    getRemoteLanguages: 'Impossible de récupérer les langues distantes, veuillez réessayer plus tard.',

    //JobFamily Error Messages
    createJobfamily: 'Impossible de créer une famille de métiers pour le moment, veuillez réessayer plus tard',
    updateJobfamily: 'Impossible de mettre à jour la famille de métiers pour le moment, veuillez réessayer plus tard',
    deleteJobfamily: 'Impossible de supprimer la famille de métiers pour le moment, veuillez réessayer plus tard',
    updateDifferentiatingFactor: 'Impossible de mettre à jour le facteur différenciant pour le moment, veuillez réessayer plus tard',
    updateJobFamilyByField: 'Impossible de mettre à jour la famille de métiers pour le moment, veuillez réessayer plus tard',

    paginationTake: "Nombre",
    filterOrganisation: 'Filtrer par organisation',
    organisations: "Organisations",
    allOrganisations: 'Toutes les organisations',
    userExportFileName: 'ExportationUtilisateur',

    dockInformation: 'Informations sur le Dock',
    manual: 'Manuel Styr Dock',
    ictIPS: 'TIC IPS',
    faq: 'FAQ',
    flyerCompensation: 'Flyer Compensation',
    flyerJobMatrix: 'Flyer Job Matrix',
    implementation: 'Implementation',
    benchmark: 'Reward Benchmark (NL)',
    compensationPolicy: 'Reward Policy (NL)',
    objectionProcedure: 'Objection Procedure',
    templateAgreementCompensation: 'Template Agreement Compensation',
    templateAgreementJobGrading: 'Template Agreement Job Grading',
    templateMaintenanceMatrix: 'Template Maintenance Matrix',
    templateMaintenanceGeneral: 'Template Maintenance General',
    templatePlanningImplementation: 'Template Planning Implementation',

    manageMatrixes: 'Gérer les matrices',
    manageMatrixesDescription: "1 matrice doit toujours être active. Si les deux matrices sont éteintes, 1 matrice sera automatiquement activée.",

    noPermissionHeader: "Vous n'avez pas la permission",
    noPermissionText: "Vous n'avez pas la permission de voir cette page. Veuillez contacter votre administrateur pour obtenir de l'aide.",

    elementDisabledHeader: (element: string) => `${element} est désactivé`,
    elementDisabledText: (element: string) => `L'élément ${element} est désactivé. Veuillez contacter votre administrateur pour obtenir de l'aide.`,

    //JobFamily Images
    writeDescription: 'Description',
    createNewVersion: 'Créer une nouvelle version',
    noExistingFiles: 'Aucun fichier existant',
    versionManagement: 'Gestion des versions',

    ssoEditHeader: 'Configuration SSO',
    ssoDomain: 'Domaine SSO (tout ce qui suit le @)',
    ssoTenantId: 'ID de locataire SSO (ID de locataire Microsoft Entra)',
    saveSSOConfiguration: 'Enregistrer la configuration SSO',
    updateCurrentSsoConfiguration: 'La configuration SSO n\'a pas pu être mise à jour, veuillez réessayer plus tard',
    getCurrentSsoConfigurations: 'Les configurations SSO n\'ont pas pu être récupérées, veuillez réessayer ultérieurement',

    documentOverview: 'Base de connaissances',
    jobFamilyVersion: 'la version',

    updateOrganisationContractContactPerson: 'Impossible de mettre à jour la personne de contact du contrat de l\'organisation, veuillez réessayer plus tard',
    manageContractContactPersons: 'Gérer les personnes de contact du contrat',
    contractContactPerson: 'Personne de contact du contrat',

    organisationNameDescription: 'Le nom de l\'organisation',
    tmaLinkDescription: 'Le lien vers TMA',
    manageContractContactPersonsDescription: 'L\'adresse e-mail de la personne de contact du contrat',
    ssoEditDescription: 'Configurer les paramètres SSO (Microsoft) pour l\'organisation',
    createOrganisationDescription: 'Créer une nouvelle organisation',
    deleteOrganisationDescription: 'Supprimer l\'organisation actuelle',
    duplicateOrganisationDescription: 'Dupliquer l\'organisation actuelle',
    manageJobFamilyDescription: '(Dés)activer le profileur d\'emploi',
    manageDifferentiatingFactorsDescription: 'Gérer les facteurs différenciants de visibilité',

    showTags: 'Afficher les balises',

    noDocumentAvailable: 'Aucun document disponible',
    documentTypeImplementation: 'Implementation',
    documentTypeRewards: 'Récompenses',
    documentTypeMaintenance: 'Maintenance',

    updateUserName: 'Impossible de mettre à jour le nom d\'utilisateur, veuillez réessayer plus tard',
    updateExistingUser: 'Mettre à jour l\'utilisateur existant',

    validationValidValue: 'Veuillez saisir une valeur valide',
    validationToLong: 'Veuillez saisir une valeur plus petite',
};
