import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'utils/theme';
import {
    RenderUsersProps, UserItemProps
} from './ManageOrganisationUsers.types';
import { TableContainer } from 'components/ResultsTable/ResultsTable.components';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { HeaderItem } from 'components/TableElements/TableElements';
import useLocalStorageState from 'use-local-storage-state/dist';
import ReduxStore from 'store/store.type';
import { connect } from 'react-redux';
import { compareUsers } from 'helpers/CompareFunctions';
import TrashIcon from 'components/Icons/trash';
import { OrganisationUser } from 'types/Organisation';
import { SelectField } from 'containers/InputField/InputField';
import { ChangeRole, changeRole } from 'store/user/user.actions';
import { Permissions } from 'store/user/user.types';
import ConnectedUpdateExsistingUser from 'sections/ManageOrganisation/UpdateExistingUser/UpdateExistingUser.connector';
import EditIcon from 'components/Icons/edit';

export const ManageOrganisationUsersContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    margin: 1em 0;
`;

export const UserContainer = styled.div`
    padding: 0.5em 1em;
    display: grid;
    grid-template-columns: 2.5fr 3fr 2fr 60px;
    align-items: center;
    &:hover {
        background-color: rgba(117, 179, 174, 0.08);
        cursor: pointer;
    }
`;

export const TableItemContainer = styled.div<{ canCheck?: boolean; sticky?: boolean }>`
    padding: 5px 15px;
    display: grid;
    grid-template-columns: ${props => props.canCheck && '20px'} 1fr;
    grid-gap: 15px;
    align-items: center;
    height: 54px;
    width: 98%;
    box-sizing: border-box;

    ${props => props.sticky ? css`
        padding: 0 15px;
        position: sticky;
        top: 0;
        z-index: 1;
    ` : `    &:hover {
        background-color: #f5f5f5;
    }`};
`;


export const Row = styled.div`
    display: grid;
    grid-template-columns: minmax(150px, 1fr) 1fr 200px 44px;
    grid-gap: 15px;
    align-items: center;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const NameContainer = styled.div`
    min-height: 40px;
    min-width: 100px;
`;

const UsersHeader: React.FC<{
    changeSorting: (item: keyof OrganisationUser) => void, state: {
        sorting: keyof OrganisationUser;
        sortingOrder: boolean;
    }
}> = ({ changeSorting, state }) => (
    <TableItemContainer sticky>
        <Row>
            <HeaderItem
                onClick={() => {
                    changeSorting('emailAdress');
                }}
                text="Email"
                activeItem={state.sorting === 'emailAdress'}
                sortingOrder={state.sortingOrder}
            />
            <HeaderItem
                onClick={() => {
                    changeSorting('firstName');
                }}
                text="Name"
                activeItem={state.sorting === 'firstName'}
                sortingOrder={state.sortingOrder}
            />
            <HeaderItem
                onClick={() => {
                    changeSorting('roleName');
                }}
                text="Role"
                activeItem={state.sorting === 'roleName'}
                sortingOrder={state.sortingOrder}
            />
        </Row>
    </TableItemContainer>
);

const RoleSelect: React.FC<{ user: OrganisationUser, changeRole: ChangeRole, permissions: Permissions }> = ({ user, changeRole, permissions }) => {
    if (!permissions.canDuplicateOrganisation || !permissions.canInviteUser) {
        return <div>{user.roleName}</div>
    }

    if (user.roleName === 'Administrator') {
        if (
            !permissions.canInvitePartnerExpert
        ) {
            return null;
        }
        return <div>{user.roleName}</div>
    }

    return (
        <SelectField
            onChange={e => {
                const newRole = e.target.value
                changeRole(user.id, newRole)
            }}
            options={
                <>
                    {permissions.canInviteUser && <option value="ClientUser">Client User</option>}
                    {permissions.canInviteExpert || permissions.canInvitePartnerUser && <option value="ClientExpert">Client Expert</option>}
                    {permissions.canInviteExpert || permissions.canInvitePartnerUser && <option value="ClientAdministrator">Client Administrator</option>}
                    {permissions.canInvitePartnerUser && <option value="PartnerUser">Partner User</option>}
                    {permissions.canInvitePartnerExpert && <option value="PartnerExpert">Partner Expert</option>}
                    {permissions.canInvitePartnerExpert && <option value="PartnerAdministrator">Partner Administrator</option>}
                    {/* {permissions.canInvitePartnerExpert && <option value="Administrator">Administrator</option>} */}
                </>
            }
            value={user.roleName.replace(' ', '')}
        />
    )
}
export const ManageUsersList: React.FC<RenderUsersProps> = ({
    users,
    deleteUser,
    lang,
    currentOrganisation,
    changeRole,
    permissions
}) => {
    const [state, setState] = useLocalStorageState<{
        sorting: keyof OrganisationUser;
        sortingOrder: boolean;
    }>("manageUsersSortOrder" + currentOrganisation?.id, { sorting: 'firstName', sortingOrder: false });

    const changeSorting = (item: keyof OrganisationUser) => {
        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const Delete: React.FC<UserItemProps> = ({ user, setPopup }) => {
        if (!setPopup) return null;
        const canDeleteClients = user.roleName.includes('Client') && permissions.canManageOrganisation;
        const canDeletePartners = user.roleName.includes('Partner') && permissions.canDeleteOrganisation;
        const isNotAdmin = user.roleName !== 'Administrator';
        const canDelete = (canDeleteClients || canDeletePartners) && isNotAdmin && permissions.canRemoveUserFromOrganisation;
        const canUpdateUserName = permissions.canUpdateUserName;

        const onClick = () => {
            deleteUser(user.id);
        };
        return (
            <ButtonContainer>
                {canUpdateUserName && <IconButton IconSVG={EditIcon} onClick={() => setPopup(true)} color={'primary'} />}
                {canDelete &&
                    <AreYouSurePopup
                        onClick={onClick}
                        action={lang.deletePlural}
                        item={lang.user}
                        InnerContent={() => <IconButton IconSVG={TrashIcon} onClick={() => { }} color={'danger'} />}
                    />
                }
            </ButtonContainer>
        );
    };

    const UserItem: React.FC<UserItemProps> = ({ user }) => {
        const canDeleteClients = user.roleName.includes('Client') && permissions.canManageOrganisation;
        const canDeletePartners = user.roleName.includes('Partner') && permissions.canDeleteOrganisation;
        const isNotAdmin = user.roleName !== 'Administrator';
        const canDelete = (canDeleteClients || canDeletePartners) && isNotAdmin && permissions.canRemoveUserFromOrganisation;
        const canUpdateUserName = permissions.canUpdateUserName;
        const [popup, setPopup] = useState<boolean>(false);
        return (
            <TableItemContainer>
                {popup && <ConnectedUpdateExsistingUser popup={popup} setPopup={setPopup} user={user} permissions={permissions} />}
                <Row>
                    <div>{user.emailAdress}</div>
                    <NameContainer onClick={() => { if (canUpdateUserName) { setPopup(true) } }}>
                        {user.firstName + " " + user.lastName}
                    </NameContainer>
                    <RoleSelect permissions={permissions} changeRole={changeRole} user={user} />

                    {(canDelete || canUpdateUserName) && <Delete user={user} setPopup={setPopup} />}
                </Row>
            </TableItemContainer>
        )
    };

    const filteredUsers = users?.filter(user => user.roleName !== 'Administrator');

    const sortedUsers = filteredUsers?.sort((a, b) => {
        return compareUsers(a, b, state.sorting);
    });

    if (state.sortingOrder) {
        sortedUsers?.reverse();
    }


    const userItems = sortedUsers?.map((user: OrganisationUser) => {
        let firstName = user.firstName.toLowerCase() != "unknown" ? user.firstName : "";
        let lastName = user.lastName.toLowerCase() != "unknown" ? user.lastName : "";
        return {
            ...user,
            firstName,
            lastName
        };
    }).map((user: OrganisationUser) => (
        <UserItem user={user} key={user.id} />
    ))

    return (
        <TableContainer>
            <UsersHeader changeSorting={changeSorting} state={state} />
            {userItems}
        </TableContainer>
    );
};


const mapStateToProps = (state: ReduxStore) => ({
    currentOrganisation: state.user.currentOrganisation
});

const mapDispatchToProps = { changeRole };
export const RenderUsers = connect(mapStateToProps, mapDispatchToProps)(ManageUsersList);
