import Routes from "routes/Routes.types";
import { LayoutType } from "./MainLayout.types";

export const layoutRecord: Partial<Record<Routes, LayoutType>> = {
    [Routes.ShardRoute]: 'no-sidebar',
    [Routes.AdminRoute]: 'admin',
    [Routes.AdminAllocatorQuestionsRoute]: 'admin',
    [Routes.AdminAllocatorQuestionRoute]: 'admin',
    [Routes.AdminAllocatorValidatedProfilesRoute]: 'admin',
    [Routes.AdminAllocatorValidatedProfileRoute]: 'admin',
    [Routes.AdminUserManagementRoute]: 'admin',
    [Routes.AdminUserManagementDetailRoute]: 'admin',
};