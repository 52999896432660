import React from 'react';
import styled from 'styled-components';
import MainLayoutNoSidebarProps from './MainLayoutNoSidebar.types';

const Layout = styled.div`
    margin: 0;
    overflow: hidden;
    background-color: #eff4f9;
`;

const FlexWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
`;

const StyledMain = styled.main`
    background: unset;
    grid-template-areas: unset;
    grid-auto-columns: unset;
    grid-template-rows: unset;
    height: 100vh;
    margin: 0;
    padding: 0;
    flex: 1 1;
    display: grid;
    grid-gap: 1em;
`;

const MainLayoutNoSidebar: React.FC<MainLayoutNoSidebarProps> = ({
    children,
}) => {
    return (
        <Layout>
            <FlexWrapper>
                <StyledMain>{children}</StyledMain>
            </FlexWrapper>
        </Layout>
    );
};
export default MainLayoutNoSidebar;
