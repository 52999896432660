import styled from 'styled-components';

export const Container = styled.div`
    background-image: url(${require('assets/images/background-login.jpg')});
    padding: 1em;
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;
