import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Button from 'components/Button/Button';
import Popup from 'components/Popup/Popup';
import {
    HeaderCheckBox,
    HeaderItem,
} from 'components/TableElements/TableElements';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import InputField from 'containers/InputField/InputField';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { onKeyDown } from 'helpers/form';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Routes from 'routes/Routes.types';
import FilterInputDropdown, {
    BulkActions,
} from 'sections/ResultsFilters/ResultsFilters.components';
import {
    createJobfamily,
    deleteJobfamily,
    updateJobFamiliesByField,
    updateJobfamily,
} from 'store/families/jobfamily.actions';
import { resultsGetResults } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { Family, Status } from 'types/Result';
import useLocalStorageState from 'use-local-storage-state/dist';
import {
    Container,
    Controls,
    Header,
    Table,
    TableItemContainer,
} from './ListOfJobFamilies.components';
import {
    statusOptionList,
    statusOptions,
} from 'sections/ValidatedProfiles/ResultSummary/ResultSummary.types';
import { ConnectedJobFamilyTableBody } from './components/JobFamilyTableBody';
import { ListJobFamiliesProps } from './types';
import { useOrganisation } from 'providers/Organisation.provider';
import CustomSortIcon from 'components/Icons/customSortIcon';

const ListJobFamilies: React.FC<ListJobFamiliesProps> = ({
    currentLanguage,
    currentOrganisation,
    createJobfamily,
    resultsGetResults,
    updateJobFamiliesByField,
    permissions,
    isShareLink,
}) => {
    const lang = getLanguageObject(currentLanguage);
    const { updateOrganisation } = useOrganisation();

    const [popupState, setPopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const [newJobFamily, setNewJobFamily] = useState('');
    const [state, setState] = useLocalStorageState<{
        sorting: keyof Family;
        sortingOrder: boolean;
    }>('jobProfilerSortOnLastModified' + currentOrganisation?.id, {
        sorting: 'lastModified',
        sortingOrder: false,
    });

    const changeSorting = (item: keyof Family) => {
        if (state.sorting === 'sortOrder') {
            setState({
                sorting: 'sortOrder',
                sortingOrder: true,
            });
        }

        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const [statusFilter, setStatusFilter] = useState<string | null>(null);

    const [error, setError] = useState({
        exist: false,
        value: false,
    });

    useEffect(() => {
        resultsGetResults();
    }, [resultsGetResults, currentOrganisation]);


    useEffect(() => {
        updateOrganisation();
    }, []);

    if (!currentOrganisation?.id) return null;

    const submit = () => {
        const checkIfFamilyExist = currentOrganisation?.jobFamilies.find(
            (x: Family) => x.name === newJobFamily
        );
        if (!newJobFamily) return setError({ ...error, value: true });
        if (checkIfFamilyExist) return setError({ ...error, exist: true });
        createJobfamily(currentOrganisation.id, newJobFamily);
        setNewJobFamily('');
        setPopup(false);
    };

    const handleSelectedItems = (id: string) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(x => x !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const MaybeRenderError = () => {
        if (error.exist)
            return (
                <InputFieldErrorMessages text={lang.jobFamilyAlreadyExist} />
            );
        if (error.value)
            return (
                <InputFieldErrorMessages text={lang.pleaseEnterFamilyName} />
            );

        return null;
    };

    const jobFamilies = currentOrganisation?.jobFamilies || [];

    const onSelectAll = () => {
        if (
            selectedItems.length ===
            jobFamilies
                .filter(x => {
                    if (!statusFilter) {
                        return true;
                    }
                    return x.status?.toLowerCase() === statusFilter;
                })
                .filter(x => {
                    if (!isShareLink) {
                        return true;
                    }
                    return x.status?.toLowerCase() === 'active';
                }).length
        ) {
            setSelectedItems([]);
            return;
        }
        const ids = jobFamilies
            .filter(x => {
                if (!statusFilter) {
                    return true;
                }
                return x.status?.toLowerCase() === statusFilter;
            })
            .filter(x => {
                if (!isShareLink) {
                    return true;
                }
                return x.status?.toLowerCase() === 'active';
            })
            .map(x => x.id);
        setSelectedItems(ids);
    };

    const onBulkChangeStatus = (newStatus: string) => {
        const statusOptionsListAsStrings: string[] = statusOptionList;
        if (!statusOptionsListAsStrings.includes(newStatus.toLowerCase())) {
            return;
        }

        if (selectedItems.length === 0) return;

        const familiesMessages = selectedItems
            .filter(id => {
                const family = jobFamilies.find(f => f.id === id);
                return (
                    family &&
                    family.status?.toLowerCase() !== newStatus.toLowerCase()
                );
            })
            .map(id => {
                return {
                    id: id,
                    field: 'status',
                    data: newStatus as Status,
                    noTranslation: true,
                    updateState: true,
                };
            });
        updateJobFamiliesByField(familiesMessages);

        setSelectedItems([]);
    };
    if (!currentOrganisation?.jobFamilies) return null;

    return (
        <>
            <Container>
                <Header>
                    <BreadCrumbs
                        title={lang.manageJobFamily}
                        paths={[
                            {
                                title: lang.manage,
                                path: Routes.ManageJobFamily,
                            },
                        ]}
                    />
                </Header>
                <Table>
                    <TableItemContainer>
                        {permissions.canManageOrganisation ? (
                            <HeaderCheckBox
                                activeItem={
                                    selectedItems.length === jobFamilies.length
                                }
                                onClick={onSelectAll}
                            />
                        ) : (
                            <div />
                        )}
                        <HeaderItem icon={<CustomSortIcon></CustomSortIcon>} activeItem={state.sorting === 'sortOrder'} onClick={() => {if(state.sorting !== 'sortOrder') changeSorting('sortOrder')}}
                        />
                        <HeaderItem activeItem={state.sorting === 'name'} text={lang.jobFamily} onClick={() => changeSorting('name')} sortingOrder={state.sortingOrder}/>
                        <HeaderItem text={'#'} inactive />
                        <HeaderItem text={lang.lastModified} inactive />
                        <HeaderItem text={lang.status} inactive />
                        <div></div>
                    </TableItemContainer>
                    <ConnectedJobFamilyTableBody
                        jobFamilies={jobFamilies}
                        filters={{
                            changeSorting,
                            state,
                            statusFilter,
                            selectedItems,
                            handleSelectedItems,
                        }}
                    />
                </Table>
                <ConnectedBottomNavigation>
                    <BulkActions>
                        {permissions.canManageOrganisation && (
                            <FilterInputDropdown
                                label="Status"
                                onChange={e => {
                                    const newStatus = e.target.value;
                                    if (newStatus === 'Show All') {
                                        setStatusFilter(null);
                                        return;
                                    }
                                    setStatusFilter(newStatus);
                                    onBulkChangeStatus(newStatus);
                                }}
                                options={statusOptions()}
                                selected={statusFilter || 'Status'}
                            />
                        )}
                    </BulkActions>
                    <Controls>
                        {permissions.canManageOrganisation && (
                            <>
                                <Button
                                    text={lang.newJobFamily}
                                    onClick={() => setPopup(true)}
                                    priority="primary"
                                />
                                <Popup
                                    currentLanguage={currentLanguage}
                                    buttonText={lang.newJobFamily}
                                    title={lang.newJobFamily}
                                    popupHandler={setPopup}
                                    popupState={popupState}
                                >
                                    <InputField
                                        width={280}
                                        placeholder={lang.nameJobFamily}
                                        type="text"
                                        value={newJobFamily}
                                        onChange={event =>
                                            setNewJobFamily(event.target.value)
                                        }
                                        onKeyDown={event =>
                                            onKeyDown(event, submit)
                                        }
                                        errorMessage={<MaybeRenderError />}
                                    />
                                    <Button
                                        text={lang.addJobFamily}
                                        onClick={submit}
                                        priority="tertiary"
                                    />
                                </Popup>
                            </>
                        )}
                    </Controls>
                </ConnectedBottomNavigation>
            </Container>
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    results: state.results.results,
    permissions: state.user.permissions,
    isShareLink: state.user.isShareLink,
});
const mapDispatchToProps = {
    createJobfamily,
    deleteJobfamily,
    updateJobfamily,
    resultsGetResults,
    updateJobFamiliesByField,
};
const ListJobFamiliesConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListJobFamilies);

export default ListJobFamiliesConnected;
