import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import AdminAllocatorValidatedProfilesSingle from 'sections/Admin/AdminAllocatorValidatedProfilesSingle/AdminAllocatorValidatedProfilesSingle.connected';
import { useParams } from 'react-router-dom';

const AdminAllocatorValidatedProfile: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return <AdminAllocatorValidatedProfilesSingle id={id} />;
};

export default AdminAllocatorValidatedProfile;
