import React from 'react';
import { Container, SectionContainer } from './Loading.components';
import LoadingProps from './Loading.types';
import { ClipLoader } from 'react-spinners';

const Loading: React.FC<LoadingProps> = ({
    message,
    sectionOnly = false,
}) => {
    if (sectionOnly)
        return (
            <SectionContainer>
                <ClipLoader
                    loading={true}
                    size={45}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </SectionContainer>
        );

    return (
        <Container>
            <ClipLoader
                loading={true}
                size={45}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            {message}
        </Container>
    );
};

export default Loading;
