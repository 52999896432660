
import React from 'react';
import { useParams } from 'react-router-dom';
import ConnectedJobFamilyEdit from 'sections/JobFamilyEdit/JobFamilyEdit';

const JobFamilyEditScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return <ConnectedJobFamilyEdit id={id} />;
};

export default JobFamilyEditScreen;
