import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { createNewOrganisation, updateOrganisationJobFamilies, duplicateOrganisation, updateOrganisationName, updateOrganisationTMALink, deleteOrganisation, updateOrganisationRequest, updateOrganisationMatrixes, getCurrentSsoConfigurations, updateCurrentSsoConfiguration, updateOrganisationContractContactPerson } from 'store/user/user.actions';
import ManageOrganisation from './ManageOrganisation';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    organisations: state.user.organisations,
    canDuplicateOrganisation: state.user.permissions.canDuplicateOrganisation,
    permissions: state.user.permissions,
    currentSsoConfigurations: state.user.currentSsoConfigurations
});
const mapDispatchToProps = {
    duplicateOrganisation,
    updateOrganisationJobFamilies,
    createNewOrganisation,
    updateOrganisationTMALink,
    updateOrganisationName,
    updateOrganisationRequest,
    deleteOrganisation,
    updateOrganisationMatrixes,
    getCurrentSsoConfigurations,
    updateCurrentSsoConfiguration,
    updateOrganisationContractContactPerson
};
const ConnectedManageOrganisation = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOrganisation);

export default ConnectedManageOrganisation;
