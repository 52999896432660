import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadQuestions } from 'store/allocator/allocator.actions';

import AllocatorSetup from 'sections/Allocator/AllocatorSetup/AllocatorSetup';
import ReduxStore from 'store/store.type';
import AllocatorProps from './Allocator.types';
import ConnectedAllocatorQuestions from 'sections/Allocator/AllocatorQuestions/AllocatorQuestions.connected';

import Profile from 'types/Profile';
import QuestionNode from 'types/QuestionNode';
import { useOrganisation } from 'providers/Organisation.provider';
import Loading from 'containers/Loading/Loading';

const MaybeRenderInitAllocator: React.FC<{
    profile?: Profile;
    questions: QuestionNode[];
    currentQuestion?: QuestionNode;
}> = ({ profile, questions, currentQuestion }) => {
    if (!questions || !currentQuestion) return <Loading sectionOnly />;
    if (profile) return <ConnectedAllocatorQuestions />;

    return <AllocatorSetup />;
};

const Allocator: React.FC<AllocatorProps> = ({
    profile,
    loadQuestions,
    questions,
    currentQuestion,
}) => {
    const { updateOrganisation } = useOrganisation();

    useEffect(() => {
        if (!questions || questions.length === 0) {
            loadQuestions();
        }
    }, [loadQuestions]);

    useEffect(() => {
        updateOrganisation();
    }, [updateOrganisation]);

    return (
        <MaybeRenderInitAllocator
            profile={profile}
            questions={questions}
            currentQuestion={currentQuestion}
        />
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    profile: state.allocator.profile,
    currentOrganisation: state.user.currentOrganisation,
    questions: state.allocator.questions.questions,
    currentQuestion: state.allocator.questions.currentQuestion,
});

const mapDispatchToProps = {
    loadQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allocator);
