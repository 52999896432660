import { LangObject } from 'helpers/language';

export const nl: LangObject = {
    id: '919c4575-6ca2-483a-bf2a-2b9a99beea86',
    styrAllocator: 'Styr Allocator',
    dashboard: 'Dashboard',
    organisation: 'Organisatie/Unit',
    startAllocator: 'Start allocator',
    searchJobsnRoles: 'Zoek functies/rollen',
    talentManagementTool: 'Talent Management System',
    elearningStyrModel: 'E-learning Styr model',
    styrUpdates: 'Release-informatie',
    forgotPassword: 'Wachtwoord vergeten?',
    logout: 'Uitloggen',
    edit: 'Edit', //TODO: Translate
    add: 'Toevoegen',
    unitEmpty: 'Unit naam mag niet leeg zijn. Vul een naam in om door te gaan',
    unitAlreadyExists: 'Unit bestaat al. Kies een andere naam om door te gaan',
    noUnits: 'Geen units beschikbaar',
    editJob: 'Job aanpassen',
    editFilterOptions: 'Filteropties aanpassen',
    styrProfile: 'Gevalideerd profiel',
    viewValidatedProfile: 'Bekijk gevalideerd profiel',
    viewJobFamily: 'Bekijk Job (Family)',
    viewJobMatrix: 'Bekijk Job Matrix',
    talentMatrix: 'Talent matrix',
    theTalentMatrix: 'De Talent matrix',
    chooseUserRole: 'Kies een gebruikersrol',
    userRole: 'Gebruikersrol',
    jobMatrix: 'Job matrix',
    theJobMatrix: 'De Job matrix',
    jobTalentMatrix: 'Job matrix / Talent matrix',
    jobMatrixArchitectureName: 'Organisatie/Unit naam',
    pleaseEnterUnit: 'Voer alstublieft een unit in',
    nameAlreadyExists: 'Naam bestaat al, voer alstublieft een andere naam in om verder te gaan',
    uploadImage: 'Upload afbeelding',
    chooseImage: 'Kies afbeelding',
    styrLevel: 'Styr level',
    filterTags: 'Tags',
    freeInputfieldColumn: 'Extra kolom',
    freeInputfield: 'Extra',
    freeInputfield2: 'Extra 2',
    freeInputfield3: 'Extra 3',
    salaryScale: 'Salaris',
    naturalCareerPath: 'Talentpad',
    styrGroups: 'Styr groep',
    styrGroup: 'Styr groep',
    problemSolvingCapability: 'Probleemoplossend vermogen',
    expertiseLevel: 'Expertise niveau',
    planningHorizon: 'Planningshorizon',
    continuousImprovement: 'Continue verbetering',
    autonomy: 'Autonomie',
    communicationSkills: 'Communicatie skills',
    managementSkill: 'Coördinatie skills (optioneel)',
    management: 'Beheer',
    levelOfExpertise: 'Expertise niveau',
    planning_horizon: 'Planningshorizon',
    continuous_improvement: 'Continue verbetering',
    communication_skills: 'Communicatie skills',
    coordinationSkills: 'Coördinatie skills',
    projectManagementSkills: '(Project) management skills',
    styr_competencies: 'STYR-TMA competenties',
    extra_competencies: 'Extra Competenties',
    education_level: 'Werk- en denkniveau / ervaringsjaren (indicatie)',
    educationLevelValidatedProfile: 'Werk- en denkniveau / ervaringsjaren (indicatie)',
    educationLevel: 'Expertise level<br/>Werk- en denkniveau /<br/>ervaringsjaren (indicatie)',
    question: 'Wat is de naam van de functie of rol?',
    unitShort: 'Architectuur 2',
    jobMatrixArchitecture: 'Job matrix architectuur',
    organisationUnit: 'Organisatie/Unit',
    unit: 'Unit',
    organisationUnitHasNoUnits: (organisationUnitName: string) => `De organisatie/unit ${organisationUnitName} heeft geen units, voeg een unit toe om door te gaan`,
    jobAlreadyExist: 'Job bestaat al, voer een andere naam in om door te gaan',
    noNameEntered: 'Voer een naam van de functie of rol in om door te gaan',
    addNewOrganisationUnit: 'Toevoegen aan organisatie/unit',
    addNewUnit: 'Toevoegen aan unit',
    unitMultiple: 'Units',
    deleteWarningOrganisationUnit: 'Let op! Wanneer deze organisatie/unit wordt verwijderd, worden ook alle gerelateerde resultaten in de organisatie/unit verwijderd',
    deleteWarningUnit: 'Let op! Wanneer deze unit wordt verwijderd, worden ook alle gerelateerde resultaten in de organisatie/unit verwijderd',
    deleteWarningUser: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
    inviteNewUser: 'Nieuwe gebruiker uitnodigen',
    inviteExistingUser: 'Bestaande gebruiker uitnodigen',
    inviteNewPartner: 'Nieuwe consultant uitnodigen',
    userInvited: 'Nieuwe gebruiker uitgenodigd',
    partnerInvited: 'Nieuwe consultant uitgenodigd',
    next: 'Volgende',
    back: 'Ga terug',
    close: 'sluit',
    title: 'Titel',
    description: 'Beschrijving',
    comment: 'bericht',
    noResults: 'Geen resultaten gevonden',
    placeholder: (item: string) => `Voer hier uw ${item} in...`,
    placeholderError: (item: string) => `! Vul a.u.b uw ${item} in !`,
    post: 'Stuur',
    attachment: 'Bijlage',
    user: 'gebruiker',
    users: 'Gebruikers',
    manageUsers: 'Beheer gebruikers',
    submit: 'Verzenden',
    updates: 'Laatste updates',
    recentAllocated: 'Meest recent gealloceerd',
    questionsServiceDeskHome: 'Neem contact op met de Styr helpdesk wanneer je vragen hebt.',
    date: 'Datum',
    retry: 'Opnieuw',
    role: 'Profiel',
    functionOrRole: 'Functie / rol',
    jobRole: 'Profiel titel',
    jobNRole: 'Profielen',
    level: 'Niveau',
    organisationName: 'Organisatie naam',
    createDetails: 'Aanmaak details',
    createdOn: 'Gemaakt op',
    createOrganisation: 'Creëer Organisatie',
    createUser: 'Creëer nieuwe Gebruiker',
    createNewOrganisation: 'Organisatie toevoegen',
    note: 'Extra informatie',
    save: 'Opslaan',
    saving: 'Opslaan...',
    saveResults: 'Opslaan',
    delete: 'Verwijder',
    deletePlural: 'Verwijderen',
    newName: 'Nieuwe naam',
    name: 'Naam',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    editPlural: 'Bewerken',
    confirmation: (action: string, item: string) => `Weet je zeker dat je ${item} wil ${action}?`,
    result: 'Resultaat',
    cancel: 'Annuleer',
    freeInputLevel: 'Extra job matrix kolom',
    columns: 'Kolommen',
    compare: 'Vergelijk',
    choose: 'Kies',
    change: 'Verander',
    manageOrganisation: 'Organisatiegegevens',
    manageValuation: 'Communicatieschil',
    manageValuationDescription: `Koppel de klassieke functiewaardering levels aan de Styr levels. \n\nLet op! Hierdoor zijn in de Job Profiler module alleen nog de klassieke functiewaardering levels zichtbaar.`,
    pleaseSelectAtleastTwo: 'Kies minimaal 2 levels om te vergelijken',
    styrDifferencingFactors: 'Styr Differentiërende factoren',
    showTalentpath: 'Bekijk talentpad',
    changeStyrLevel: 'Styr levels vergelijken',
    selectLevelAfterTalentpathChange: 'Kies een Styr level na het veranderen van het talentpad',
    backToValidatedProfile: 'Terug naar profiel',
    backToPreview: 'Terug naar preview',
    changeLevel: 'Verander Styr level',
    chooseStyrLevels: (current: number) => `Kies Styr levels (max ${current}/3)`,
    selectStyrLevelsToCompare: (current: number) => `Selecteer de levels die je wilt vergelijken (max. ${current}/3)`,
    currentValidatedProfileLevelIs: 'Het huidige Styr level is ',
    youreAboutToChange: ', je staat op het punt dit te veranderen naar level ',
    changing: 'Aanpassen',
    theStyrLevel: 'het Styr level',
    talentpath: 'Talentpad',
    view: 'Bekijken',
    viewInformation: 'Extra informatie',
    allocatorWarningOrganisation: 'Het is niet mogelijk om de Styr Allocator te gebruiken zonder een job matrix architectuur aan de organisatie toe te voegen.',
    maxLogoWidthDescription: 'Max. breedte logo 1000px',
    maxLogoWidthWarning: 'Afbeelding is te breed',
    supportedFileTypes: 'Ondersteunde bestandstypen',
    goToManagement: 'Ga naar Beheer',
    talentpathAdministrative: 'Administratief',
    talentpathAdvisory: 'Advies',
    talentpathCommercial: 'Commercieel',
    talentpathExpert: 'Expert',
    talentpathManagement: 'Management',
    talentpathProjectManagement: 'Projectmanagement',
    talentpathSupportCoordination: 'Support & Coördinatie',
    talentpathProcessManagement: 'Procesmanagement',
    talentpathOperationalTechnical: 'Operationeel / Technisch',
    manageJobFamily: 'Job Profiler',
    manageJobFamilyNotEnabled: 'Job (families) zijn niet beschikbaar voor de huidige organisatie',
    search: 'Zoeken',
    jobFamilyAlreadyExist: 'Job (family) bestaat al',
    pleaseEnterFamilyName: 'Job (family) naam vergeten',
    addJobFamily: 'Job (family) toevoegen',
    goToSettings: 'Instellingen',
    addNewJob: 'Nieuwe job aanmaken',
    newJobFamily: 'Nieuwe job (family)',
    nameJobFamily: 'Naam job (family)',
    status: 'Status',
    changeStatusBulk: 'Status aanpassen:',
    jobFamily: 'Job (family)',
    lastModified: 'Laatst aangepast',
    differentiatingFactorsTitle: 'Differentiërende factoren',
    changeDifferentiatingFactorsTitle: 'Job profiler',
    differentiatingFactorsDescription: 'Beheer de zichtbaarheid van de differentiërende factoren en competenties. Ga naar instellingen om ze uit en aan te zetten.',
    competencies: 'Competenties',
    defaultStyrCompetencies: 'STYR-TMA competenties',
    extraCompetencies: 'Extra Competenties',
    mainResponsibilities: 'Hoofdverantwoordelijkheden',
    manage: 'Beheer',
    row: 'Rij',
    enterTitle: 'Titel',
    enterDescription: 'Beschrijving',
    addExtraCompetence: 'Competentie toevoegen',
    manageRows: 'Filter rijen',
    createNewJob: 'Nieuwe job aanmaken',
    selectOrganisationUnit: 'Selecteer Organisatie / Unit',
    selectUnit: 'Selecteer Unit',
    selectTalentPath: 'Selecteer talentpad',
    selectLevel: 'Selecteer Styr level',
    pleaseFillInAllField: 'Het lijkt er op dat je iets vergeten bent.',
    accountability: 'Verantwoordelijkheid',
    attention_to_detail: 'Aandacht voor details',
    business_orientation: 'Ondernemerschap',
    commercial_power: 'Commercieel vermogen',
    conduct: 'Optreden',
    controlling_progress: 'Voortgangscontrole',
    cooperation: 'Samenwerken',
    creativity: 'Creativiteit',
    customer_orientation: 'Klantgerichtheid',
    decisiveness: 'Besluitvaardigheid',
    delegating: 'Delegeren',
    developing_employees: 'Ontwikkelen van medewerkers',
    discipline_competence: 'Discipline',
    flexible_behavior: 'Flexible gedrag',
    focus_on_quality: 'Kwaliteitsgerichtheid',
    forming_judgment: 'Oordeelsvorming',
    identification_with_management: 'Managementidentificatie',
    independence: 'Onafhankelijkheid',
    innovative_power: 'Innoverend vermogen',
    insight: 'Inzicht',
    leadership_of_groups: 'Groepsgericht leidinggeven',
    managing: 'Leidinggeven',
    need_to_achieve: 'Prestatievermogen',
    networking: 'Netwerken',
    perseverance: 'Vasthoudendheid',
    persuasiveness: 'Overtuigingskracht',
    planning_and_organizing: 'Plannen en organiseren',
    result_orientedness: 'Resultaatgerichtheid',
    sociability: 'Sociabiliteit',
    social_awareness: 'Omgevingsbewustzijn',
    verbal_expression: 'Mondelinge vaardigheid',
    vision: 'Visie',
    workmanship: 'Vakgerichtheid',
    courage: 'Durf',
    integrity: 'Integriteit',
    written_expression: 'Schriftelijke vaardigheid',
    backToJobFamilies: 'Terug naar job (families)',
    showAll: 'Toon alles',
    jobfamilySearchPlaceholder: 'Zoeken op: Titel, organisatie, unit, niveau enz...',
    filterResults: 'Filter profielen',
    password: 'Wachtwoord',
    accessCode: 'Toegangscode',
    accessCodeDescription: 'De toegangscode is geen wachtwoord. De share link in combinatie met de toegangscode kan worden gedeeld via een intranet of een vergelijkbaar beveiligd kanaal. Kan geen bijzondere tekens bevatten.',
    accessCodeError: 'Toegangscode mag geen bijzondere tekens bevatten',
    usernameOrEmail: 'Gebruikersnaam of e-mailadres',
    usernameEmpty: 'Vul je gebruikersnaam in om verder te gaan',
    passwordEmpty: 'Vul je wachtwoord in om verder te gaan',
    microsoftNotWorking: 'Account kon niet geverifieerd worden. Probeer een ander account of probeer het later opnieuw.',
    switchOrganisation: 'Verander van organisatie',
    viewOtherOrganisations: 'Bekijk andere organisaties',

    login: 'Log in',
    forgotPasswordTitle: 'Wachtwoord vergeten?',
    backToLogin: 'Terug naar login',
    requestNewPassword: 'Wachtwoord aanvragen',
    tryAgain: 'Opnieuw proberen',
    newPasswordSend: 'De e-mail met een link om je wachtwoord opnieuw in te stellen is verzonden. Heb je de e-mail niet ontvangen?',
    emailFieldEmpty: 'Email veld is leeg',
    resetPasswordTitle: 'Wachtwoord resetten',
    resetPasswordSuccess: 'Je wachtwoord is gereset',
    newPassword: 'Nieuw wachtwoord',
    duplicateOrganisation: 'Huidige organisatie kopieren',
    deleteOrganisation: 'Organisatie verwijderen',

    // SHARELINKS
    manageShareLinkTitle: 'Gedeelde links beheren',
    manageShareLinkDescription: `
    Volg de volgende 3 stappen om een gedeelde link te maken waarmee medewerkers toegang krijgen tot de Job Matrix/Talent Matrix en de bijbehorende profielen. Alleen profielen die op actief staan worden getoond in de Job Matrix / Talent Matrix.

    1)	Klik op "nieuwe link aanmaken" en selecteer de relevante organisatie/unit en Styr levels die u wilt laten zien.
    2)	Vul een toegangscode in om de gedeelde link te beveiligen.
    3)	Deel de link en de toegangscode met de beoogde ontvanger en draag zorg dat hier vertrouwelijk mee wordt omgaan, zodat de beoogde toegang/beveiliging gewaarborgd blijft.
    `,

    createNewLink: 'Nieuwe link aanmaken',
    link: 'Link',
    dateAdded: 'Datum toegevoegd',

    questionDeleteShareLink: 'Weet u zeker dat u wilt verwijderen?',

    createNewShareLinkTitle: 'Maak een share link',
    createNewShareLinkDescription: 'Stel de kijkrechten zo in dat medewerkers toegang hebben tot de relevante organisatie/eenheden en Styr levels. U dient uw link met een toegangscode te beveiligen.',

    allOrganisationUnitsTitle: 'Alle',
    createdBy: 'Ingedeeld door',
    updatedBy: 'Laatst bewerkt door',

    headerSearchbarNoResults: 'Geen resultaten gevonden',
    headerSearchbarPlaceholder: 'Zoek...',

    createNewProfile: 'Nieuw profiel aanmaken',
    pleaseSelectAnAnswer: 'Selecteer een antwoord om verder te gaan',

    pleaseSelectALevel: 'Selecteer een nieuw Styr level om verder te gaan',
    showStyrProfile: 'Toon gevalideerd profiel',

    createUpdate: 'Creëer update',
    allocatorQuestions: 'Allocator Vragen',
    allocatorQuestion: 'Allocator Vraag',

    errorTitle: 'Er is iets misgegaan',
    errorFailedFetchText: "We kunnen geen verbinding maken met de server. Neem contact op met de <a href='mailto:helpdesk@styr.nl'>helpdesk</a> als dit probleem zich blijft voordoen. ",
    errorEndText: 'Probeer het later opnieuw',

    tagsHeader: 'Tags selecteren',
    tagsUntagged: 'Niet getagd',
    tagsFutureState: 'Toekomstige situatie',
    tagsCurrentState: 'Huidige situatie',

    multiselectAllItemsAreSelected: 'Alle items zijn geselecteerd',
    multiselectClearSearch: 'Zoekopdracht wissen',
    multiselectClearSelected: 'Geselecteerde wissen',
    multiselectNoOptions: 'Geen opties',
    multiselectSearch: 'Zoeken',
    multiselectSelectAll: 'Alles selecteren',
    multiselectSelectAllFiltered: 'Alles selecteren (gefilterd)',
    multiselectSelectSomeItems: 'Selecteer...',
    multiselectCreate: 'Aanmaken',
    mulitselectSelect: 'Selecteer',

    //Allocator Error Messages
    loadQuestions: 'De vragen kunnen niet worden geladen, probeer het later opnieuw',
    loadValidatedProfiles: 'De gevalideerde profielen kunnen niet worden geladen, probeer het later opnieuw',
    loadLevels: 'De niveaus kunnen niet worden geladen, probeer het later opnieuw',
    loadTalentpaths: 'De talentpaden kunnen niet worden geladen, probeer het later opnieuw',
    createQuestion: 'De vraag kan niet worden aangemaakt, probeer het later opnieuw',
    updateQuestion: 'De vraag kan niet worden bijgewerkt, probeer het later opnieuw',
    deleteQuestion: 'De vraag kan niet worden verwijderd, probeer het later opnieuw',
    createAnswer: 'Het antwoord kan niet worden aangemaakt, probeer het later opnieuw',
    updateAnswer: 'Het antwoord kan niet worden bijgewerkt, probeer het later opnieuw',
    deleteAnswer: 'Het antwoord kan niet worden verwijderd, probeer het later opnieuw',
    createValidatedProfile: 'Het gevalideerde profiel kan niet worden aangemaakt, probeer het later opnieuw',
    updateValidatedProfile: 'Het gevalideerde profiel kan niet worden bijgewerkt, probeer het later opnieuw',
    deleteValidatedProfile: 'Het gevalideerde profiel kan niet worden verwijderd, probeer het later opnieuw',

    //User Error Messages
    updateOrganisationRequest: 'Kon de organisatie niet ophalen, probeer het later opnieuw',
    userSharedLinkLogin: 'Kan niet inloggen met de gedeelde link, probeer het later opnieuw',
    updateOrganisationUsers: 'Kan de gebruikers van de organisatie niet ophalen, probeer het later opnieuw',
    updateOrganisationsRequest: 'Kan de organisaties niet ophalen, probeer het later opnieuw',
    changeRole: 'Kan de rol van de gebruiker niet wijzigen, probeer het later opnieuw',
    inviteUser: 'Kan de gebruiker niet uitnodigen, probeer het later opnieuw',
    invitePartner: 'Kan de partner niet uitnodigen, probeer het later opnieuw',
    deleteUser: 'Kan de gebruiker niet verwijderen, probeer het later opnieuw',
    deleteOrganisationError: 'Kan de organisatie niet verwijderen, probeer het later opnieuw',
    duplicateOrganisationError: 'Kan de organisatie niet dupliceren, probeer het later opnieuw',
    createNewOrganisationError: 'Kan geen nieuwe organisatie aanmaken, probeer het later opnieuw',
    updateOrganisationValuation: 'Kan de waardering van de organisatie niet bijwerken, probeer het later opnieuw',
    updateOrganisationTMALink: 'Kan de TMA-link van de organisatie niet bijwerken, probeer het later opnieuw',
    updateOrganisationJobFamilies: 'Kan de job families van de organisatie niet bijwerken, probeer het later opnieuw',
    updateOrganisationName: 'Kan de naam van de organisatie niet bijwerken, probeer het later opnieuw',
    updateOrganisationMatrixes: 'Kan de matrixen van de organisatie niet aan of uitzetten, probeer het later opnieuw',
    updateResultInFamily: 'Kan de job in de familie niet bijwerken, probeer het later opnieuw',
    addOrganisationUnit: 'Kan de organisatie-eenheid niet toevoegen, probeer het later opnieuw',
    updateOrganisationUnit: 'Kan de organisatie-eenheid niet bijwerken, probeer het later opnieuw',
    deleteOrganisationUnit: 'Kan de organisatie-eenheid niet verwijderen, probeer het later opnieuw',
    addUnit: 'Kan de unit niet toevoegen, probeer het later opnieuw',
    deleteUnit: 'Kan de unit niet verwijderen, probeer het later opnieuw',
    updateUnit: 'Kan de unit niet bijwerken, probeer het later opnieuw',
    updateUnitsAndOrganisationUnitsOrder: 'Kan de volgorde van de units en organisatie-eenheden niet bijwerken, probeer het later opnieuw',
    updateLevelNotes: 'Kan de niveau-opmerkingen niet bijwerken, probeer het later opnieuw',
    uploadOrganisationImage: 'Kan de organisatieafbeelding niet uploaden, probeer het later opnieuw',
    createShareLink: 'Kan de deel link niet maken, probeer het later opnieuw',
    updateShareLink: 'Kan de deel link niet bijwerken, probeer het later opnieuw',
    deleteShareLink: 'Kan de deel link niet verwijderen, probeer het later opnieuw',
    updatePermissions: 'Kan de rechten niet bijwerken, probeer het later opnieuw',
    getRole: 'Kan de rol niet ophalen, probeer het later opnieuw',
    loadAllOrganisationsRequest: 'Kan de organisaties niet ophalen, probeer het later opnieuw',
    loadSelectedUserRequest: 'Kan de gebruiker niet ophalen, probeer het later opnieuw',
    updateUserRequest: 'Kan de gebruiker niet bijwerken, probeer het later opnieuw',
    loadPagedUserRequest: 'Kan de gebruikers niet ophalen, probeer het later opnieuw',

    //Update Error Messages
    updatesCreateUpdate: 'Kan op dit moment geen update maken, probeer het later opnieuw',
    updatesGetUpdates: 'Kan op dit moment geen updates ophalen, probeer het later opnieuw',
    updatesChangeUpdate: 'Kan op dit moment de update niet wijzigen, probeer het later opnieuw',
    updatesDeleteUpdate: 'Kan op dit moment de update niet verwijderen, probeer het later opnieuw',

    //Results Error Messages
    getResult: 'De job kan niet opgehaald worden, probeer het later opnieuw.',
    getResults: 'De gevraagde jobs kunnen niet opgehaald worden, probeer het later opnieuw.',
    postResult: 'De job kon niet worden aangemaakt, probeer het later opnieuw.',
    postResultManual: 'De job kon niet worden aangemaakt, probeer het later opnieuw.',
    changeStatusBulkError: 'De status van de jobs kan niet in bulk wijzigen, probeer het later opnieuw.',
    changeResult: 'De job kan niet bewerkt worden, probeer het later opnieuw.',
    changeResultDetails: 'De job details kunnen niet worden gewijzigd, probeer het later opnieuw.',
    deleteResult: 'De job kan niet verwijderd worden, probeer het later opnieuw.',
    changeResultValidatedProfile: 'Het styr niveau van het gevalideerde profiel kan niet worden gewijzigd, probeer het later opnieuw.',
    updateResultByField: 'De jobfamily kan niet worden bijgewerkt, probeer het later opnieuw.',
    updateResultExtraCompetence: 'De extra competentie van de job kan niet worden bijgewerkt, probeer het later opnieuw.',
    changeResultExtraCompetenceOrder: 'De volgorde van de extra competenties van de job kan niet worden gewijzigd, probeer het later opnieuw.',
    saveExtraCompetence: 'De extra competentie van de job kan niet worden opgeslagen, probeer het later opnieuw.',

    //Language Error Messages
    getRemoteLanguages: 'Momenteel kunnen de talen niet opgehaald worden, probeer het later opnieuw.',

    //JobFamily Error Messages
    createJobfamily: 'Kan op dit moment geen job family aanmaken, probeer het later opnieuw',
    updateJobfamily: 'Kan op dit moment de job family niet bijwerken, probeer het later opnieuw',
    deleteJobfamily: 'Kan op dit moment de job family niet verwijderen, probeer het later opnieuw',
    updateDifferentiatingFactor: 'Kan op dit moment het differentiërende kenmerk niet bijwerken, probeer het later opnieuw',
    updateJobFamilyByField: 'Kan op dit moment de job family niet bijwerken, probeer het later opnieuw',

    paginationTake: 'Toon',
    filterOrganisation: 'Filter op organisatie',
    organisations: 'Organisaties',
    allOrganisations: 'Alle organisaties',
    userExportFileName: 'GebruikersExport',

    dockInformation: 'Dock informatie',
    manual: 'Handleiding Styr Dock',
    ictIPS: 'ICT-IPS',
    faq: 'FAQ',
    flyerCompensation: 'Flyer Beloningsbeleid',
    flyerJobMatrix: 'Flyer Job Matrix',
    implementation: 'Implementatie',
    benchmark: 'Beloningsbenchmark 2025 (NL)',
    compensationPolicy: 'Beloningsbeleid tool (NL)',
    objectionProcedure: 'Bezwaarprocedure',
    templateAgreementCompensation: 'Instemmingsaanvraag Beloningsbeleid',
    templateAgreementJobGrading: 'Instemmingsaanvraag Functiewaardering',
    templateMaintenanceMatrix: 'Onderhoudsprocedure Job Matrix',
    templateMaintenanceGeneral: 'Onderhoudsprocedure',
    templatePlanningImplementation: 'Planning Implementatie',

    manageMatrixes: 'Beheer matrixen',
    manageMatrixesDescription: '1 matrix moet altijd actief zijn. Als beide matrixen uitstaan zal 1 matrix automatisch worden geactiveerd.',

    noPermissionHeader: 'Geen toegang',
    noPermissionText: 'Je hebt geen toegang tot deze pagina. Neem contact op met de beheerder van de organisatie.',

    elementDisabledHeader: (element: string) => `${element} is uitgeschakeld`,
    elementDisabledText: (element: string) => `Neem contact op met de beheerder van de organisatie om ${element} in te schakelen.`,

    //JobFamily Images
    writeDescription: 'Beschrijving',
    createNewVersion: 'Nieuwe versie aanmaken',

    noExistingFiles: 'Geen bestaande bestanden',
    versionManagement: 'Versiebeheer',

    ssoEditHeader: 'SSO Configuratie',
    ssoDomain: 'SSO Domein (alles na de @)',
    ssoTenantId: 'SSO Tenant ID (Microsoft Entra Tenant ID)',
    saveSSOConfiguration: 'SSO Configuratie opslaan',
    updateCurrentSsoConfiguration: 'De SSO configuratie kon niet worden bijgewerkt, probeer het later opnieuw',
    getCurrentSsoConfigurations: 'De SSO configuraties konden niet worden opgehaald, probeer het later opnieuw',

    documentOverview: 'Kennisbank',
    jobFamilyVersion: "versie",

    updateOrganisationContractContactPerson: 'Kan het contract contact persoon van de organisatie niet bijwerken, probeer het later opnieuw',
    contractContactPerson: 'Contract contact persoon',
    manageContractContactPersons: 'Beheer contract contact personen',

    organisationNameDescription: 'De naam van de organisatie',
    tmaLinkDescription: 'De link naar de TMA',
    manageContractContactPersonsDescription: 'Het email adres van het contract contact persoon',
    ssoEditDescription: 'Configureer de Microsoft SSO instellingen voor de organisatie',
    createOrganisationDescription: 'Maak een nieuwe organisatie aan',
    deleteOrganisationDescription: 'Verwijder de huidige organisatie',
    duplicateOrganisationDescription: 'Dupliceer de huidige organisatie',
    manageJobFamilyDescription: '(de)activeer de Job profiler',
    manageDifferentiatingFactorsDescription: 'Beheer de zichtbaarheid van de differentiërende factoren en competenties',

    showTags: 'Toon tags',

    noDocumentAvailable: 'Geen documenten beschikbaar',
    documentTypeImplementation: 'Implementatie',
    documentTypeRewards: 'Beloning',
    documentTypeMaintenance: 'Onderhoud',

    updateUserName: 'Kan de gebruikersnaam niet bijwerken, probeer het later opnieuw',
    updateExistingUser: 'Bestaande gebruiker bijwerken',

    validationValidValue: 'Vul een geldige waarde in',
    validationToLong: 'Vul een kortere waarde in',
};
