import React, { useState } from 'react';
import UpdateExistingUserProps, { UpdateExistingError } from 'sections/ManageOrganisation/UpdateExistingUser/UpdateExistingUser.types';
import { BackdropBlocker, UpdateExistingUserContainer } from './UpdateExistingUser.components';
import { H2 } from 'components/Typography/Typography';
import getLanguageObject from 'helpers/language';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { ControllerButtons } from 'sections/ValidatedProfiles/ResultSection/ResultSection';
import Button from 'components/Button/Button';
import InputField from 'containers/InputField/InputField';

export const UpdateExistingUser: React.FC<UpdateExistingUserProps> = ({
    user,
    updateUserName,
    popup,
    setPopup,
    currentLanguage
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [error, setError] = useState<UpdateExistingError>({
        firstName: {
            empty: false,
            toLong: false,
        },
        lastName: {
            empty: false,
            toLong: false,
        }
    });

    const validateValue = (key: string, value: string, minimumLength: number, maximumLength: number) => {
        if (!value || value.length < minimumLength) {
            setError(prevError => ({ ...prevError, [key]: { empty: true, toLong: false } }));
        } else if (value.length > maximumLength) {
            setError(prevError => ({ ...prevError, [key]: { empty: false, toLong: true } }));
        } else {
            setError(prevError => ({ ...prevError, [key]: { empty: false, toLong: false } }));
        }
    };

    const isValid = () => {
        return !error.firstName.empty && !error.firstName.toLong && !error.lastName.empty && !error.lastName.toLong;
    }

    const setProperty = (key: string, value: string) => {
        if (key === 'firstName') {
            validateValue(key, value, 2, 29);
            user.firstName = value;
        } else if (key === 'lastName') {
            validateValue(key, value, 2, 63);
            user.lastName = value;
        }
    }

    const handleUpdate = () => {
        if (popup && user && isValid()) {
            updateUserName(user.id, user.firstName, user.lastName);
            setPopup(false);
        }
    }

    const MaybeRenderError = (key: keyof UpdateExistingError) => {
        if (error[key].empty) return <InputFieldErrorMessages text={lang.validationValidValue} />
        if (error[key].toLong) return <InputFieldErrorMessages text={lang.validationToLong} />

        return null;
    }

    return (
        <>
            <BackdropBlocker onClick={()=> setPopup(false)}></BackdropBlocker>
            <UpdateExistingUserContainer>
                <H2>{lang.updateExistingUser}</H2>
                <InputField label={lang.firstName} value={user?.firstName} onChange={(e) => setProperty('firstName', e.target.value)} type='text' errorMessage={MaybeRenderError('firstName')} />
                <InputField label={lang.lastName} value={user?.lastName} onChange={(e) => setProperty('lastName', e.target.value)} type='text' errorMessage={MaybeRenderError('lastName')} />
                <ControllerButtons>
                    <Button text={lang.cancel} onClick={() => setPopup(false)} priority="secondary" />
                    <Button text={lang.save} onClick={handleUpdate} priority="tertiary" disabled={!isValid}  />
                </ControllerButtons>
            </UpdateExistingUserContainer>
        </>
    )
}
