import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button/Button';
import { UnderlinedTitle } from 'components/Typography/Typography';
import InputField from 'containers/InputField/InputField';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { ProcessingOverlay } from 'containers/ProcessingOverlay/ProcessingOverlay';
import getLanguageObject from 'helpers/language';
import {
    Form,
    LoginFormContainer,
    Row
} from './LoginForm.components';
import SharedLoginFormProps from './LoginFormSL.types';

const LoginForm: React.FC<SharedLoginFormProps> = ({
    errors,
    userSharedLinkLogin,
    userAddError,
    userClearErrors,
    currentLanguage,
    token
}) => {
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setProcessing(false);
        }, 3000);
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        }
    }, [setProcessing]);

    const handleSubmit = () => {
        userClearErrors();
        setProcessing(true);
        if (password.length < 1) {
            setProcessing(false);
            userAddError(lang.passwordEmpty);
        }
        if (password.length > 0) {
            if (token != null) {
                userSharedLinkLogin(password, token, history, () => {
                    setProcessing(false);
                });
            }
        }
    };

    return (
        <>
            {processing && <ProcessingOverlay />}
            <LoginFormContainer>
                <Form>
                <UnderlinedTitle>Styr dock</UnderlinedTitle>
                    <InputField
                        fullWidth
                        label={lang.accessCode}
                        name="password-shared-link"
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPassword(e.target.value)
                        }
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                        errorMessage={errors &&
                            errors.map((error, key) => (
                                <InputFieldErrorMessages key={key} text={error} />
                            ))} />
                    <Row>
                        <Button text={lang.login} onClick={handleSubmit} priority="tertiary" />
                    </Row>
                </Form>
            </LoginFormContainer>
        </>
    );
};

export default LoginForm;
