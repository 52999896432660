import { UpdateJobFamilyByField } from "store/families/jobfamily.actions"
import { UpdateResultInFamily } from "store/user/user.actions"
import { Organisation } from "types/Organisation";
import { Language } from "types/MultiLingualString"
import { Result } from "types/Result"

interface JobFamilyEditProps{ 
    currentLanguage: Language
    currentOrganisation?: Organisation
    results?: Result[]
    resultsGetResults: () => void
    updateResultInFamily: UpdateResultInFamily
    updateJobFamilyByField: UpdateJobFamilyByField
    id: string
}

export interface FilterValues {
    name: string;
    status: string;
    level: string;
    organisationUnit: string;
    unit: string;
    talentPath: string;
}

export const BaseFilterValues: FilterValues = {
    name: '',
    unit: 'Show All',
    organisationUnit: 'Show All',
    level: 'Show All',
    status: 'Show All',
    talentPath: 'Show All'
};

export type SetFilterValues = (filterValues: FilterValues) => void;

export default JobFamilyEditProps