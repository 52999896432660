import React from 'react';

import { Permissions } from 'store/user/user.types';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';

const RequireAdminComponent: React.FC<{
    children: React.ReactElement;
    permissions: Permissions;
}> = ({ children, permissions }) => {
    if (!permissions.canDuplicateOrganisation) return null;

    return children;
};

export const RequireAdmin = connect(
    (state: ReduxStore) => ({ permissions: state.user.permissions }),
    {}
)(RequireAdminComponent);
