import React, { useEffect, useState } from 'react';

import ResultsFilters from 'sections/ResultsFilters/ResultsFilters';
import ResultsTable, {
    SelectedItemsProps,
} from 'components/ResultsTable/ResultsTable';
import ResultsProps, { BaseFilterValues, FilterValues } from './Results.types';
import styled from 'styled-components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import getLanguageObject from 'helpers/language';
import useLocalStorageState from 'use-local-storage-state';
import { Result } from 'types/Result';
import { compareResults } from '../../helpers/CompareFunctions';
import { resultsGetResults } from 'store/results/results.actions';
import { getNoExcessElements } from 'helpers/RequireAuth';

const ResultsPage: React.FC<ResultsProps> = ({
    currentLanguage,
    results,
    resultsGetResults,
    currentOrganisation,
    role,
    isSharedLink,
    share_link_settings,
}) => {
    const lang = getLanguageObject(currentLanguage);

    if(isSharedLink && !share_link_settings?.show_styr_profile){
        return getNoExcessElements(lang);
    }

    const [filterValues, setFilterValues] = useState<FilterValues>(
        BaseFilterValues
    );
    const [selectedItems, setSelectedItems] = useState<SelectedItemsProps[]>(
        []
    );

    const filteredResults = results.filter(result => {
        return (
            (result.status?.toLowerCase() === filterValues.status.toLowerCase() || filterValues.status === 'Show All') && // Filter on status filter
            (filterValues.level.includes(result.level.toLowerCase()) || filterValues.level.length === 0) && // Filter on level filter
            (filterValues.organisationUnit.includes(result.organisationUnitId) || filterValues.organisationUnit.length === 0) && // Filter on organisationUnit filter
            (filterValues.talentPath.includes(result.validatedProfile.talentPath.key) || filterValues.talentPath.length === 0) && // Filter on talent path filter
            (filterValues.unit.includes(result.unitId) || filterValues.unit.length === 0) && // Filter on unit filter
            (result.name?.toLowerCase().includes(filterValues.name?.toLowerCase() || "") || filterValues.name?.replace(/\s/g, '') === '') // Filter on name filter

        );
    });

    useEffect(() => {
        resultsGetResults();
    }, [resultsGetResults, currentOrganisation?.id]);

    const onSelectItem = (id: string, status: string) => {
        const exists = selectedItems?.find(x => x.id === id)?.id;
        if (exists)
            return setSelectedItems(selectedItems.filter(x => x.id !== id));
        return setSelectedItems([...selectedItems, { id, status }]);
    };

    const onSelectAll = () => {
        const getAllIds = filteredResults.map(x => {
            return {
                id: x.id,
                status: x.status,
            };
        });
        if (selectedItems.length === 0) return setSelectedItems(getAllIds);

        return setSelectedItems([]);
    };

    const [state, setState] = useLocalStorageState<{
        sorting: keyof Result;
        sortingOrder: boolean;
    }>('resultsSortOrder' + currentOrganisation?.id, {
        sorting: 'createdOn',
        sortingOrder: false,
    });

    const changeSorting = (item: keyof Result) => {
        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const sortedResults = filteredResults.sort((a, b) => {
        return compareResults(a, b, state.sorting, currentLanguage);
    });

    if (state.sortingOrder) {
        sortedResults.reverse();
    }

    return (
        <Container>
            <Header>
                <BreadCrumbs
                    title={lang.searchJobsnRoles}
                    paths={[
                        { title: lang.dashboard, path: Routes.DashboardRoute },
                        {
                            title: lang.searchJobsnRoles,
                            path: Routes.ResultsRoute,
                        },
                    ]}
                />
            </Header>
            <ResultsTable
                results={sortedResults}
                state={state}
                onSelectAll={onSelectAll}
                onSelectItem={onSelectItem}
                changeSorting={changeSorting}
                selectedItems={selectedItems}
            />
            <ResultsFilters
                results={sortedResults}
                setFilterValues={setFilterValues}
                filterValues={filterValues}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
            />
        </Container>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    results: state.results.results,
    currentOrganisation: state.user.currentOrganisation,
    role: state.user.role,
    isSharedLink: state.user.isShareLink,
    share_link_settings: state.user.share_link_settings,
});
const mapDispatchToProps = { resultsGetResults };
const Results = connect(mapStateToProps, mapDispatchToProps)(ResultsPage);

export default Results;

export const Container = styled.div`
    display: grid;
    grid-template-rows: 120px 1fr 80px;
    height: 100%;

    &:-webkit-scrollbar {
        display: none;
    }
`;

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`;
