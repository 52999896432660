import React from 'react';

import ConnectedManageOrganisationUsersList from 'sections/ManageOrganisation/ManageOrganisationUsers/ManageOrganisationUsers.connector';
import ManageOrganisationProps from './ManageOrganisationUsers.types';
import getLanguageObject from 'helpers/language';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import { Container, Header } from 'layouts/MainLayout/MainLayout.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import ConnectedInviteNewUser from 'sections/ManageOrganisation/InviteNewUser/InviteNewUser.connector';
import { deleteShareLink } from 'store/user/user.actions';

const ManageUsers: React.FC<ManageOrganisationProps> = ({
    currentLanguage,
    permissions,
}) => {
    const lang = getLanguageObject(currentLanguage);

    const canInviteUser = permissions.canInviteUser;

    return (
        <Container>
            <Header>
                <BreadCrumbs
                    title={lang.manageUsers}
                    paths={[
                        { title: lang.dashboard, path: Routes.DashboardRoute },
                        {
                            title: lang.manageUsers,
                            path: Routes.ManageOrganisationUsers,
                        },
                    ]}
                />
            </Header>
            <ConnectedManageOrganisationUsersList />
            {canInviteUser && (
                <ConnectedBottomNavigation>
                    <div />
                    <ConnectedInviteNewUser />
                </ConnectedBottomNavigation>
            )}
        </Container>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    shareLinks: state.user.currentOrganisation?.shareLinks || [],
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
});

const mapDispatchToProps = { deleteShareLink };

const ManageOrganisationUsers = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageUsers);

export default ManageOrganisationUsers;
