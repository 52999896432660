import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    overflow-x: hidden;

    &:-webkit-scrollbar {
        display: none;
    }
`;

export const Main = styled.main`
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Pages = styled.section<{ scrollPage?: boolean }>`
    box-sizing: border-box;
    &:-webkit-scrollbar {
        display: none;
    }
    ${props =>
        props.scrollPage &&
        `
        overflow: scroll;
    `}

    overflow-y: auto;
`;

export const Container = styled.div<{ isFullscreen?: boolean }>`
    display: grid;
    grid-template-rows: 120px 1fr 80px;
    height: 100%;
`;

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`;

export const BlocksGridContainer = styled.div`
    height: calc(100vh - 190px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
`
