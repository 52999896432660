import React, { useEffect } from 'react';
import ManageOrganisationDetailsProps from './ManageJobFamily.types';
import { Header } from './ManageJobFamily.components';
import getLanguageObject from 'helpers/language';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import ListJobFamiliesConnected from 'sections/ListOfJobFamilies/ListOfJobFamilies';
import { loadValidatedProfiles } from 'store/allocator/allocator.actions';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import { Container } from 'layouts/MainLayout/MainLayout.components';

export const ManageJobFamily: React.FC<ManageOrganisationDetailsProps> = ({
    currentLanguage,
    currentOrganisation,
    loadValidatedProfiles,
}) => {
    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        loadValidatedProfiles();
    }, [loadValidatedProfiles]);

    if (!currentOrganisation) {
        return (
            <Container>
                <Header>
                    <BreadCrumbs
                        title={lang.manageJobFamilyNotEnabled}
                        paths={[
                            {
                                title: lang.dashboard,
                                path: Routes.DashboardRoute,
                            },
                        ]}
                    />
                </Header>
            </Container>
        );
    }

    return <ListJobFamiliesConnected />;
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapDispatchToProps = { loadValidatedProfiles };

const ManageJobFamilyConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageJobFamily);

export default ManageJobFamilyConnected;
