
import React from 'react';
import JobMatrixProps from './Matrix.types';
import MatrixContextProvider from 'containers/MatrixContextProvider/MatrixContextProvider';
import ConnectedJobMatrixResults from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.connector';
import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { Container } from 'layouts/MainLayout/MainLayout.components';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import getLanguageObject from 'helpers/language';
import { CenteredContainer } from 'screens/Matrix/Matrix.components';

const TalentMatrixScreen: React.FC<JobMatrixProps> = ({
    currentLanguage,
    currentOrganisation,
}) => {
    const lang = getLanguageObject(currentLanguage);
    if (!currentOrganisation || !currentOrganisation?.talentMatrixEnabled) {
        return (
            <CenteredContainer>
                <h1>{lang.elementDisabledHeader(lang.talentMatrix)}</h1>
                <p>{lang.elementDisabledText(lang.theTalentMatrix)}</p>
            </CenteredContainer>
        );
    }

    return (
        <Container>
            <MatrixContextProvider>
                <ConnectedJobMatrixResults
                    matrixView={MatrixViews.talentmatrix}
                />
            </MatrixContextProvider>
        </Container>
    );
};

const mapMatrixViewTabsStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});
const mapMatrixViewTabsDispatchToProps = {};
const JobMatrix = connect(
    mapMatrixViewTabsStateToProps,
    mapMatrixViewTabsDispatchToProps
)(TalentMatrixScreen);

export default JobMatrix;
